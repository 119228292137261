import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import { advertiserDashboardData } from "../../../../redux/slices/AdminService";
import { useEffect, useState } from "react";
import MvpLoader from "../../../../components/common/mvpLoader/MvpLoader";
import AdvertApexChart from "./AdvertApexChart";

// for custom progressbar design

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8"
  }
}));

// for tabal data

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function AdvertiserHome() {
  const [loading, setLoading] = useState(true);
  const [allDashboardData, setAllDashboardData] = useState();
  const dispatch = PageIndex?.useDispatch();
  useEffect(() => {
    dispatch(advertiserDashboardData()).then((res) => {
      if (res?.payload?.status === 200) {
        setAllDashboardData(res?.payload?.data);
        setLoading(false);
      }
    });
  }, []);
  return (
    <>
      {loading ? (
        <MvpLoader color="#1B698E" loading={loading} />
      ) : (
        <Index.Box className="dashboard-content home-page-content">

          <Index.Grid container spacing={2}>
            <Index.Grid item xs={12} md={8} lg={8}>
              <Index.Typography
                className="admin-page-title main-heading"
                component="h2"
                variant="h2"
              >
                Dashboard
              </Index.Typography>
            </Index.Grid>
            <Index.Grid item xs={12} md={4} lg={4} textAlign="end">
              <Index.Typography
                className="admin-page-title sub-text-cont"
                component="h6"
                variant="h6"
              >
                {/* Number of Advertisers <span className="sub-digit">2861</span> */}
              </Index.Typography>
            </Index.Grid>
          </Index.Grid>

          <Index.Box className="dashboard-box-cont">
            <Index.Grid container spacing={2}>
              <Index.Grid item xs={12} md={2.4} lg={2.4}>
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box admin-dash-new-box">
                    <Index.Box className="admin-dash-right">
                      <img
                         src={PageIndex.Svg.Campaigns}
                        // src={PageIndex.Png.flexo}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {allDashboardData?.totalAdvertisementsCount}
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Advertisement Created
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={12} md={2.4} lg={2.4}>
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box admin-dash-new-box">
                    <Index.Box className="admin-dash-right">
                      <img
                        src={PageIndex.Svg.TotalImpression}
                        // src={PageIndex.Png.flexo}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {allDashboardData?.activeAdvertisementsCount}
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Active advertisement
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={12} md={2.4} lg={2.4}>
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box admin-dash-new-box">
                    <Index.Box className="admin-dash-right">
                      <img
                        src={PageIndex.Svg.TotalImpression}
                        // src={PageIndex.Png.flexo}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {allDashboardData?.totalAmountSpent}
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Amount Spent
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>

          <Index.Box className="graph-box-cont">
            <Index.Grid container spacing={2}>
              <Index.Grid item xs={12} md={12} lg={2}>
                <Index.Typography
                  className="graph-title"
                  component="h6"
                  variant="h6"
                >
                  Graph Title
                </Index.Typography>
              </Index.Grid>
              <Index.Grid item xs={12} md={12} lg={12}>
                <Index.Box className="graph-box">
                  <AdvertApexChart />
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>

          {/* <Index.Box className="admin-dashboad-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3"
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-right">
                        <img
                          src={PageIndex.Png.userimg}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total Advertisement Created
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {allDashboardData?.totalAdvertisementsCount}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3"
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-right">
                        <img
                          src={PageIndex.Svg.dashicon2}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Active advertisement
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {allDashboardData?.activeAdvertisementsCount}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>              
              </Index.Box>
            </Index.Box>
          </Index.Box> */}
          {/* <Index.Box className="admin-dashboad-row">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
          >
         
          </Index.Box>
        </Index.Box>
      </Index.Box> */}
          {/* <Index.Box className="admin-dashboard-list-row">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="admin-dash-box">
                <Index.Box className="flex-all admin-dashboard-table-row">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h3"
                      variant="h3"
                    >
                      Latest Orders
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="view-btn-main border-btn-main">
                    <Index.Button
                      className="view-order-btn border-btn"
                      btnLabel="View Orders"
                    >
                      View Orders
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="page-table-main">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    <Index.Table
                      sx={{ minWidth: 650 }}
                      aria-label="simple table"
                      className="table"
                    >
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            ORDER
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            STATUS
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            DATE
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            CUSTOMER
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            TOTAL
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {rows.map((row) => (
                          <Index.TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="table-td"
                            >
                              {row.name}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {row.calories}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {row.fat}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {row.carbs}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {row.protein}
                            </Index.TableCell>
                          </Index.TableRow>
                        ))}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="admin-dash-box">
                <Index.Box className="flex-all admin-dashboard-table-row">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h3"
                      variant="h3"
                    >
                      Latest Orders
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="view-btn-main border-btn-main">
                    <Index.Button className="view-order-btn border-btn">
                      View Orders
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="page-table-main">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    <Index.Table
                      sx={{ minWidth: 650 }}
                      aria-label="simple table"
                      className="table"
                    >
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            ORDER
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            STATUS
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            DATE
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            CUSTOMER
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            TOTAL
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {rows.map((row) => (
                          <Index.TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="table-td"
                            >
                              {row.name}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {row.calories}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {row.fat}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {row.carbs}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {row.protein}
                            </Index.TableCell>
                          </Index.TableRow>
                        ))}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box> */}

          {/* <Index.Box className="progress-bar-content">
        <Index.Typography
          className="admin-page-title"
          component="h2"
          variant="h2"
        >
          Progress Bar
        </Index.Typography>
        <Index.Box className="progress-bar-main">
          <BorderLinearProgress
            variant="determinate"
            value={50}
            className="progress-bar"
          />
          <span className="progress-bar-text">50%</span>
        </Index.Box>
      </Index.Box> */}
        </Index.Box>
      )}
    </>
  );
}
