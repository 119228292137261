import Svg from "../assets/Svg";
import Png from "../assets/Png";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import MvpLoader from "./common/mvpLoader/MvpLoader";
import { useDispatch, useSelector } from "react-redux";
import { loginSchema, forgotPasswordSchema, verifyOtpSchema } from "../validations/Validation";

// import Sidebar from "./admin/defaulLayout/Sidebar";
// import Header from "./admin/defaulLayout/Header";
// import PaidLable from "./common/lables/PaidLable";
// import FailedLable from "./common/lables/FailedLable";
// import PendingLable from "./common/lables/PendingLable";
// import PrimaryButton from "../component/common/Button/primarybutton/PrimaryButton";
// import AuthFooter from "./admin/defaulLayout/AuthFooter";
// import AuthBackground from "./admin/defaulLayout/AuthBackground";
export default {
  useSelector,
  MvpLoader,
  useNavigate,
  useLocation,
  Svg,
  Png,
  useDispatch,
  loginSchema,
  forgotPasswordSchema,
  verifyOtpSchema,
  //   Sidebar,
  //   Header,
  //   PaidLable,
  //   FailedLable,
  //   PendingLable,
  //   PrimaryButton,
  //   AuthFooter,
  //   AuthBackground,
};
