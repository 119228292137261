import * as Yup from "yup";
const startSpace = /^(?!\s).*$/;

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email")

    .matches(
      // /^[a-zA-Z0-9]+[\w\.-]*[a-zA-Z0-9]+@[a-zA-Z\d]+[\w\.-]*[a-zA-Z\d]+\.[a-zA-Z]{2,}$/,
      /^(?:(?!\.)(?![0-9])(?:\w+\.)*(?:\w+)\@[a-zA-Z]+\.\w{2,3}(?:\.[a-z]{2,3})?)$/,
      "Please enter valid email"
    )

    // .matches(
    //   /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$/,
    //   "Please enter valid email"
    // )
    .oneOf([Yup.ref("email"), ""], "Email must match")
    .required("Please enter email"),
  password: Yup.string()
    .required("Please enter your password")
    // .min(8, "Must Contain 8 Characters")

   
    .min(8,"Password length should be minimum 8 character").max(25)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*.).{8,25}$/g,
      "Password including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),
   
});

// // Validation EditProfile
const digitsOnly = (value) =>
  /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0;
const mobilePattern = (value) =>
  /^[1-9][0-9]*$/.test(value) || value.length === 0;
const textAndSpaceOnly = (value) =>
  /^[a-zA-Z]+(\s[a-zA-Z]*){0,2}$/.test(value) || value.length === 0;

export const EditProfileSchema = Yup.object().shape({
  name: Yup.string()
  .required("Please enter name"),

  email: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .email("Please enter valid email")
    .matches(
      /^(?:(?!\.)(?![0-9])(?:\w+\.)*(?:\w+)\@[a-zA-Z]+\.\w{2,3}(?:\.[a-z]{2,3})?)$/,
      "Please enter valid email"
    )
    .required("Please enter email"),
  mobileNumber: Yup.number()
    .required("Please enter mobile number")
    .min(10, "Number should be 10 digit"),
    adminCommission : Yup.string()
    .max(100, "Amount exceeds limit. Please enter an admin commission up to 100")
    .matches(
      /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,4})?|0(\.\d*[1-9]+\d{0,3})?)$/,
      "Please enter a valid admin commission"
    )  .test('is-not-zero', 'Value should be greater than 0', value => parseFloat(value) !== 0)
    
    .required("Please enter a admin commission")


  // profileImage: Yup.mixed()
  //   //.required("Company logo is required")
  //   .test(
  //     "FILE_FORMAT",
  //     "Allow only jpg,jpeg,png file",
  //     (value) =>
  //       !value ||
  //       (["jpg", "jpeg", "png"].includes(
  //         value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
  //       ) &&
  //         ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
  //   )
  //   .test(
  //     "fileSize",
  //     "File size too large, max file size is 2.048 Mb",
  //     (file) => {
  //       if (file) {
  //         return file.size <= 2048000;
  //       } else {
  //         return true;
  //       }
  //     }
  //   ),
});
export const EditAdvertiserProfileSchema = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  email: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .email("Please enter valid email")
    .matches(
      // /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
      // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      /^(?:(?!\.)(?![0-9])(?:\w+\.)*(?:\w+)\@[a-zA-Z]+\.\w{2,3}(?:\.[a-z]{2,3})?)$/,
      "Please enter valid email"
    )
    .required("Please enter email"),
  company: Yup.string()
    .required("Please enter company name")

    .test(
      "len",
      "Company name allows maximum 35 characters",
      (val) => val.length <= 35
    )
  // profileImage: Yup.mixed()
  //   //.required("Company logo is required")
  //   .test(
  //     "FILE_FORMAT",
  //     "Allow only jpg,jpeg,png file",
  //     (value) =>
  //       !value ||
  //       (["jpg", "jpeg", "png"].includes(
  //         value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
  //       ) &&
  //         ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
  //   )
  //   .test(
  //     "fileSize",
  //     "File size too large, max file size is 2.048 Mb",
  //     (file) => {
  //       if (file) {
  //         return file.size <= 2048000;
  //       } else {
  //         return true;
  //       }
  //     }
  //   ),
});
export const updatePublisherProfileSchema = Yup.object().shape({
  name: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter name"),
  email: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .email("Please enter valid email")
    .matches(
      // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      /^(?:(?!\.)(?![0-9])(?:\w+\.)*(?:\w+)\@[a-zA-Z]+\.\w{2,3}(?:\.[a-z]{2,3})?)$/,
      "Please enter valid email"
    )
    .required("Please enter email"),
  company: Yup.string()
    .required("Please enter company name")
    .min(1, "Company Name is Too Short.")
    .matches(startSpace, "Starting space not allow"),
  publishUrl: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter publisher url")
    .matches(
      /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
      // /^(www\.)?[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      "Please enter valid publisher url"
    ),
  walletAddress: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter wallet address")
  // profileImage: Yup.mixed()
  //   //.required("Company logo is required")
  //   .test(
  //     "FILE_FORMAT",
  //     "Allow only jpg,jpeg,png file",
  //     (value) =>
  //       !value ||
  //       (["jpg", "jpeg", "png"].includes(
  //         value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
  //       ) &&
  //         ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
  //   )
  //   .test(
  //     "fileSize",
  //     "File size too large, max file size is 2.048 Mb",
  //     (file) => {
  //       if (file) {
  //         return file.size <= 2048000;
  //       } else {
  //         return true;
  //       }
  //     }
  //   ),
});
export const ChangepasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter old password"),
  newPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter new password").min(8,"Password length should be minimum 8 character").max(25)
    .min(8,"Password length should be minimum 8 character").max(25)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*.).{8,25}$/g,
      "Password including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),
  confirmPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter confirm password")
    .oneOf([Yup.ref("newPassword"), null], "Password must match")
});
export const ChangeAdvertiserpasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter old password"),
  newPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter new password")
    .min(8,"Password length should be minimum 8 character").max(25)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*.).{8,25}$/g,
      "Password including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),
  confirmPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter confirm password")
    .oneOf([Yup.ref("newPassword"), null], "Password must match")
});
export const ChangePublisherpasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter old password"),
  newPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter new password")
    .min(8,"Password length should be minimum 8 character").max(25)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*.).{8,25}$/g,
      "Password including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),
  confirmPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter confirm password")
    .oneOf([Yup.ref("newPassword"), null], "Password must match")
});

export const forgotPasswordSchema = Yup.object().shape({
  // emailForgot: Yup.string()
  // email: Yup.string()
  //   // .email("Please Enter Your Valid Email")
  //   .matches(startSpace, "Starting space not allow")
  //   .matches(
  //     /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$/,
  //     "Please enter your valid email"
  //   )
  //   .oneOf([Yup.ref("emailForgot"), ""], "Email must match")
  //   .required("Please enter email"),

  email: Yup.string()
    .email("Please enter valid email")

    .matches(
      // /^[a-zA-Z0-9]+[\w\.-]*[a-zA-Z0-9]+@[a-zA-Z\d]+[\w\.-]*[a-zA-Z\d]+\.[a-zA-Z]{2,}$/,
      // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      /^(?:(?!\.)(?![0-9])(?:\w+\.)*(?:\w+)\@[a-zA-Z]+\.\w{2,3}(?:\.[a-z]{2,3})?)$/,
      "Please enter valid email"
    )

    // .matches(
    //   /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$/,
    //   "Please enter valid email"
    // )
    .oneOf([Yup.ref("email"), ""], "Email must match")
    .required("Please enter email")
});

export const verifyOtpSchema = Yup.object().shape({
  otp: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(/^\d{4}$/, "OTP must be a 4-digit number")
    .required("Please enter your four digit otp")
});

export const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required(" Please enter new password")
    .matches(startSpace, "Starting space not allow")
    .min(8,"Password length should be minimum 8 character").max(25)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*.).{8,25}$/g,
      "Password including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),

  confirmPassword: Yup.string()
    .required(" Please enter confirm password")
    .oneOf(
      [Yup.ref("password")],
      " New assword and confirm password should be the same"
    )
});
export const registerSchema = Yup.object().shape({
  role: Yup.string().required("Please select role"),

  category: Yup.array().when("role", {
    is: "publisher",
    then: () =>
      Yup.array()
        .min(1, "Select at least one category")
        .required("Please select category")
  }),

  password: Yup.string()
    .required(" Please enter password")
    .matches(startSpace, "Starting space not allow")
    .min(8,"Password length should be minimum 8 character").max(25)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*.).{8,25}$/g,
      "Password including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),

  confirmpassword: Yup.string()
    .required("Please enter confirm password")
    .oneOf(
      [Yup.ref("password")],
      " Password and confirm password should be the same"
    ),
  name: Yup.string()
    .required("Please enter name")
    .min(1, " Name is Too Short.")
    .matches(startSpace, "Starting space not allow")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Name must have only capital and small letters"
    ),
  company: Yup.string()
    .required("Please enter company name")
    .min(1, "Company Name is Too Short.")
    .matches(startSpace, "Starting space not allow"),
  // .matches(
  //   /^(?!\s)[a-zA-Z0-9.,;:|\\\/~!@#$%^&*_-{}\[\]()`"'<>?\s]+$/,
  //   "Company Name must have only capital and small letters"
  // ),
  email: Yup.string()
    // .email("Please Enter Your Valid Email")
    .matches(startSpace, "Starting space not allow")
    .matches(
      /^(?:(?!\.)(?![0-9])(?:\w+\.)*(?:\w+)\@[a-zA-Z]+\.\w{2,3}(?:\.[a-z]{2,3})?)$/,
      "Please enter valid email"
    )
    .oneOf([Yup.ref("email"), ""], "Email must match")
    .required("Please enter email"),

  publishUrl: Yup.string().when("role", {
    is: "publisher",
    then: () =>
      Yup.string()
        .required("Please enter your publisher url")
        .matches(
          /^(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i,
          // /^(www\.)?[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
          "Please enter valid publisher url"
        )
  })
});
export const registerPublisherSchema = Yup.object().shape({
  password: Yup.string()
    .required(" Please enter password")
    .matches(startSpace, "Starting space not allow")
    .min(8,"Password length should be minimum 8 character").max(25)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*.).{8,25}$/g,
      "Password including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),

  confirmpassword: Yup.string()
    .required("Please enter confirm password")
    .oneOf(
      [Yup.ref("password")],
      " Password and confirm password should be the same"
    ),
  name: Yup.string()
    .required("Please enter name")
    .min(1, " Name is Too Short.")
    .matches(startSpace, "Starting space not allow")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Name must have only capital and small letters"
    ),
  company: Yup.string()
    .required("Please enter company name")
    .min(1, "Company Name is Too Short.")
    .matches(startSpace, "Starting space not allow")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Company name must have only capital and small letters"
    ),
  email: Yup.string()
    // .email("Please Enter Your Valid Email")
    .matches(startSpace, "Starting space not allow")
    .matches(
      /^(?:(?!\.)(?![0-9])(?:\w+\.)*(?:\w+)\@[a-zA-Z]+\.\w{2,3}(?:\.[a-z]{2,3})?)$/,
      "Please enter valid email"
    )
    .oneOf([Yup.ref("email"), ""], "Email must match")
    .required("Please enter email"),
  publisherUrl: Yup.string()
    .required("Please enter your publisher url")
    .min(1, "publisher url is Too Short.")
    .matches(
      /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
      // /^(www\.)?[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      "Please enter valid publisher url"
    )
  // .required('Domain name is required'),
  // .matches(startSpace, "Starting space not allow")
  // .matches(
  //   /^(?!\s)[A-Za-z\s]+$/,
  //   "Publisher url must have only capital and small letters"
  // ),
});
export const advertisementSchema = Yup.object().shape({
  title: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Title must have only capital and small letters"
    )
    .required("Please enter title"),
  category: Yup.array().required("Please select category"),
  maxSpendLimitPerTransaction: Yup.string()
    .matches(startSpace, "Starting space not allow")

    .matches(
      /^-?(\d+(\.\d{1,2})?|\.\d{1,2})$/,
      "Please enter valid max spend limit per transaction"
    )
    .required("Please enter max spend limit per transaction"),
  duration: Yup.string().when("advertisement_type", {
    is: "video",
    then: () => Yup.string().notRequired(), // If advertisement_type is "video", duration is not required
    otherwise: () =>
      Yup.string()
        .matches(startSpace, "Starting space not allowed")
        .matches(
          /^-?(\d+(\.\d{1,2})?|\.\d{1,2})$/,
          "Please enter a valid duration"
        )
        .test(
          "lengthValidation",
          "Duration must be between 15 or 20 seconds",
          (value) => {
            const durationInSeconds = parseFloat(value);
            return durationInSeconds >= 15 && durationInSeconds <= 20;
          }
        )
        .required("Please enter duration")
  }),
  // maxSpendLimit: Yup.string()
  //   .matches(startSpace, "Starting space not allow")

  //   .matches(
  //     /^-?(\d+(\.\d{1,2})?|\.\d{1,2})$/,
  //     "Please enter valid max spend limit"
  //   )
  //   .required("Please enter max spend limit"),
  // maxLimit: Yup.string()
  //   .matches(startSpace, "Starting space not allow")

  //   .matches(/^-?(\d+(\.\d{1,2})?|\.\d{1,2})$/, "Please enter valid max limit")
  //   .required("Please enter max limit"),
  // maxGasFeesLimit: Yup.string()
  //   .matches(startSpace, "Starting space not allow")

  //   .matches(
  //     /^-?(\d+(\.\d{1,2})?|\.\d{1,2})$/,
  //     "Please enter valid max gas fees limit"
  //   )
  //   .required("Please enter max gas fees limit"),

  startDate: Yup.date().required("Please enter start date"),

  // endDate: Yup.date().required("Please enter end date"),

  endDate: Yup.date()
    .required("Please enter end date")
    .test(
      "is-greater-than-start",
      "End date must be grater than start date",
      function (endDate) {
        const { startDate } = this.parent; // Access the 'startDate' value
        if (startDate && endDate) {
          return endDate >= startDate;
        }
        return true;
      }
    ),
  // advertisement_type : Yup
  // video: Yup.mixed().required("Please select video"),
  description: Yup.mixed().when("advertisement_type", {
    is: "text",
    then: () => Yup.mixed().notRequired(),
    otherwise: () => Yup.mixed().required("Please select description")
  }),

  hyperLink: Yup.string()
    .required("Please enter hyperlink")
    .matches(
      /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
      // /^(www\.)?[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      "Please enter valid hyperlink"
    ),
  // file: Yup.mixed()
  //   .required("image is required")

  video: Yup.mixed().when("advertisement_type", {
    is: "video",
    then: () => Yup.mixed().required("Please select video"),
    otherwise: () =>
      Yup.mixed().when("advertisement_type", {
        is: "image",
        then: () => Yup.mixed().required("Please select image"),
        otherwise: () => Yup.mixed().notRequired() // No error message for "text"
      })
  }),
  textdetails: Yup.mixed().when("advertisement_type", {
    is: "text",
    then: () => Yup.mixed().required("Please select text details"),
    otherwise: () => Yup.mixed().notRequired()
  })
});

export const updatePublisherSchema = Yup.object().shape({
  publisherCommission: Yup.string()
    .max(100, "Amount exceeds limit. Please enter an publisher commission up to 100")
    .matches(
      /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,4})?|0(\.\d*[1-9]+\d{0,3})?)$/,
      "Please enter a valid publisher commission"
    )  .test('is-not-zero', 'Value should be greater than 0', value => parseFloat(value) !== 0)
    
    .required("Please enter a publisher commission"),
    walletAmount:Yup.string().required("Please enter a wallet"),

});
export const updateAdvertiserSchema = Yup.object().shape({

    walletAmount:Yup.string().required("Please enter a wallet"),

});



export const categorySchema = Yup.object().shape({
  Category_Name: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Category name must have only capital and small letters"
    )
    .required("Please enter category name"),
  // Category_Image: Yup.mixed().required("Please select image"),
  Category_Image: Yup.mixed()
    .required("Please select a image")
    .test("size", "Image size is too large", (value) => {
      if (value?.size) {
        return value?.size <= 5 * 1024 * 1024;
      }
      return true;
    })
    .test(
      "fileFormat",
      "Image must be in JPG, JPEG, and PNG format",
      (value) => {
        if (value?.type) {
          const allowedExtensions = ["image/jpg", "image/jpeg", "image/png"];
          // const fileExtension = value?.name?.split(".").pop().toLowerCase();
          return allowedExtensions.includes(value?.type);
        } else {
          const allowedExtensions = ["jpg", "jpeg", "png"];
          const fileExtension = value?.split(".")[1];
          return allowedExtensions.includes(fileExtension);
        }
        return true;
      }
    )
});
export const EditPublisherProfileSchema = Yup.object().shape({
  name: Yup.string()

    .matches(startSpace, "Starting space not allow")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Name must have only capital and small letters"
    )
    .required("Please enter name")
    .min(1, " Name is Too Short."),
  company: Yup.string()

    .matches(startSpace, "Starting space not allow")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Company name must have only capital and small letters"
    )
    .required("Please enter company name")
    .min(1, "Company Name is Too Short."),
  email: Yup.string()
    // .email("Please Enter Your Valid Email")
    .matches(startSpace, "Starting space not allow")
    .matches(
      /^(?:(?!\.)(?![0-9])(?:\w+\.)*(?:\w+)\@[a-zA-Z]+\.\w{2,3}(?:\.[a-z]{2,3})?)$/,
      "Please enter valid email"
    )
    .oneOf([Yup.ref("email"), ""], "Email must match")
    .required("Please enter email"),
  publisherUrl: Yup.string()

    .matches(startSpace, "Starting space not allow")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Publisher url must have only capital and small letters"
    )
    .required("Please enter your publisher url")
    .min(1, "publisher url is Too Short.")
});
//ritika changes presale validation 

export const presaleSchema = Yup.object().shape({
  startValue:Yup.date().required("Please enter start date"),
  endValue: Yup.string()
  // .matches(
  //   /^(?!\s)[A-Za-z\s]+$/,
  //   "Token price must have only capital and small letters"
  // )
  .required("Please enter end date"),
  round_no: Yup.string()
    // .matches(
    //   /^(?!\s)[A-Za-z\s]+$/,
    //   "Category name must have only capital and small letters"
    // )
    .required("Please enter round no"),
    minimum_purchase: Yup.string()
    // .matches(
    //   /^(?!\s)[A-Za-z\s]+$/,
    //   "Category name must have only capital and small letters"
    // )
    .required("Please enter minimum purchase"),
    monthly_release: Yup.string() .max(100, "Monthly release exceeds limit. Please enter a monthly release up to 100")
    .matches(
      /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,4})?|0(\.\d*[1-9]+\d{0,3})?)$/,
      "Please enter a valid monthly release"
    )  .test('is-not-zero', 'Value should be greater than 0', value => parseFloat(value) !== 0)
    // .matches(
    //   /^(?!\s)[A-Za-z\s]+$/,
    //   "Category name must have only capital and small letters"
    // )
    .required("Please enter monthly release"),
    round_percent: Yup.string().max(100, "Round percent exceeds limit. Please enter a round percent up to 100")
    .matches(
      /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,4})?|0(\.\d*[1-9]+\d{0,3})?)$/,
      "Please enter a valid round percent"
    )  .test('is-not-zero', 'Value should be greater than 0', value => parseFloat(value) !== 0)
    // .matches(
    //   /^(?!\s)[A-Za-z\s]+$/,
    //   "Category name must have only capital and small letters"
    // )
    .required("Please enter round percent"),
    token_price: Yup.string()
    // .matches(
    //   /^(?!\s)[A-Za-z\s]+$/,
    //   "Token price must have only capital and small letters"
    // )
    .required("Please enter token price"),
  
});
