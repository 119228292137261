import React from 'react'
import Index from '../Index'

function WalletFooter() {
  return (
    <>
      <Index.Box className='metamask-footer'>
            <Index.PrimaryButton btnLabel="Add Network" className='primary-btn' />
          </Index.Box>
    </>
  )
}

export default WalletFooter
