import React from "react";
import DataService from "../config/DataService";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PublisherPrivateRoutes = () => {
  const { publishertoken } = useSelector((state) => state.admin);
  const isAuthenticate = (token) => {
    if (!token) return false;
    DataService.defaults.headers.common.auth = token;
    return true;
  };

  return isAuthenticate(publishertoken) ? (
    <Outlet />
  ) : (
    <Navigate to="/publisher/login" replace={true} />
  );
};

export default PublisherPrivateRoutes;
