import React, { useEffect, useState } from "react";
import MvpLoader from "../../../../components/common/mvpLoader/MvpLoader";
import { Skeleton } from "@mui/material";
import {
  deleteCategoryData,
  listOfAllCategory,
} from "../../../../redux/slices/AdminService";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/DataService";
import "./categorylist.css";
import DeleteModal from "../../../../components/common/deleteModal/DeleteModal";
// for modal design

// for table data

export default function CategoryList() {
  const [loading, setLoading] = useState(true);
  const [allCategoryList, setAllCategoryList] = useState([]);
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const ITEM_HEIGHT = 48;
  const open1 = Boolean(anchorEl);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [singleChallangeData, setSingleChallangeData] = useState({})

  const handleDeleteOpen = (id) => {
    setDeleteOpen(true);
    setSelectedRow(id);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const dispatch = PageIndex?.useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(listOfAllCategory()).then((res) => {
      setAllCategoryList(res?.payload?.data);
      setLoading(false);
    });
  }, []);

  const navigate = PageIndex?.useNavigate();

  const handleDeleteCategoryData = () => {
    const idData = {
      _id: selectedRow,
    };
    dispatch(deleteCategoryData(idData)).then((res) => {
      if (res?.payload?.status === 200) {
        dispatch(listOfAllCategory()).then((res) => {
          setLoading(false);
          setAllCategoryList(res?.payload?.data);
          handleDeleteClose();
          // handleMenuClose();
        });
      }
    });
  };
  useEffect(() => {
    const filtered =
      allCategoryList &&
      allCategoryList?.filter((item) => {
        return !search
          ? item
          : item?.Category_Name?.toLowerCase().includes(search?.toLowerCase());
      });

    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [allCategoryList, search]);


  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const handleActionOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      {loading ? (
        <Index.Box className="dashboard-content user-list-content">
          <Index.Box className="user-list-flex res-direction-col">
            <Index.Typography
              className="admin-page-title user-list-page-title main-heading"
              component="h2"
              variant="h2"
            >
              Category
            </Index.Typography>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group search-box-bg">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <img
                      src={PageIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={() => navigate("/admin/add-category ")}
                >
                  <img
                    src={PageIndex.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add Category
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-bg-main table-main-box">
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box pb-0">
                      <Index.Box className="userlist-table-main page-table-main">
                        <Index.TableContainer component={Index.Paper} className="table-container">
                          <Index.Table aria-label="simple table" className="table">
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell className="table-th">Action</Index.TableCell>
                                <Index.TableCell className="table-th">Image</Index.TableCell>
                                <Index.TableCell className="table-th">Name</Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {[...Array(5)].map((_, index) => (
                                <Index.TableRow key={index}>
                                  <Index.TableCell width="5%">
                                    <Skeleton variant="circular" width={40} height={40} />
                                  </Index.TableCell>
                                  <Index.TableCell width="12%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="15%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      ) : (
        <Index.Box className="dashboard-content user-list-content category-list-main">
          <Index.Box className="user-list-flex res-direction-col">
            <Index.Typography
              className="admin-page-title user-list-page-title main-heading"
              component="h2"
              variant="h2"
            >
              Category
            </Index.Typography>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group search-box-bg">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <img
                      src={PageIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={() => navigate("/admin/add-category ")}
                >
                  <img
                    src={PageIndex.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add Category
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">

            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="table-bg-main table-main-box">
                    <Index.Box className="admin-dash-box pb-0">
                      <Index.Box className="advertiserlist-table-main page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            aria-label="simple table"
                            className="table"
                            sx={{ width: "100%" }}
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Action
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Image
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Name
                                </Index.TableCell>

                              </Index.TableRow>
                            </Index.TableHead>
                            {pageData && pageData?.length > 0 ? (
                              <Index.TableBody className="table-body">
                                {pageData &&
                                  pageData?.map((row, index) => (
                                    <Index.TableRow
                                      key={row?._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td action-tab"
                                      >
                                        <Index.IconButton
                                          aria-label="more"
                                          id="long-button"
                                          aria-haspopup="true"
                                          onClick={(event) => {
                                            handleActionOpen(event);
                                            setSingleChallangeData(
                                              pageData?.[index]
                                            );
                                          }}
                                        // sx={{ filter: "invert(1)" }}
                                        >
                                          <Index.MoreVertIcon />
                                        </Index.IconButton>
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        <img
                                          className="category-images"
                                          src={`${imageUrl}/${row?.Category_Image}`}
                                          alt="category"
                                        />
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row.Category_Name}
                                      </Index.TableCell>

                                      {/* <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        <Index.IconButton
                                          color="primary"
                                          aria-label="upload picture"
                                          component="label"
                                          onClick={() => {
                                            navigate("/admin/view-category", {
                                              state: row,
                                            });
                                          }}
                                        >
                                          <Index.RemoveRedEyeIcon className="view-ic" />
                                        </Index.IconButton>
                                        <Index.IconButton
                                          color="primary view-ic"
                                          aria-label="upload picture"
                                          component="label"
                                          onClick={() => {
                                            navigate("/admin/add-category", {
                                              state: row,
                                            });
                                          }}
                                        >
                                          <Index.EditIcon className="table-td-edit view-ic" />
                                        </Index.IconButton>
                                        <Index.IconButton
                                          color="primary view-ic"
                                          aria-label="upload picture"
                                          component="label"
                                          onClick={() =>
                                            handleDeleteOpen(row?._id)
                                          }
                                        >
                                          <Index.DeleteIcon className="table-td-Inactive" />
                                        </Index.IconButton>
                                      </Index.TableCell> */}
                                    </Index.TableRow>
                                  ))}
                              </Index.TableBody>
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td no-record-text"
                                  align="center"
                                  colSpan={8}
                                >
                                  <h3> No records available </h3>
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.Table>
                        </Index.TableContainer>

                        {/* Action Menu */}
                        <Index.Box className="admin-menu-box">
                          <Index.Menu
                            className="admin-menu-main"
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl}
                            open={open1}
                            onClose={handleActionClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "auto",
                                boxShadow: "none",
                                border: "1px solid rgba(224, 224, 224, 1)",
                                marginTop: "6px",
                              },
                            }}
                          >
                            <Index.MenuItem
                              key={`view_`}
                              onClick={handleActionClose}
                              className="menu-option-li"
                            >
                              <Index.IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                onClick={() => {
                                  navigate("/admin/view-category", {
                                    state: singleChallangeData,
                                  });
                                }}
                              >
                                <Index.RemoveRedEyeIcon className="view-ic" />
                              </Index.IconButton>
                            </Index.MenuItem>

                            <Index.MenuItem
                              key={`edit_`}
                              onClick={handleActionClose}
                              className="menu-option-li"
                            >
                              <Index.IconButton
                                color="primary view-ic"
                                aria-label="upload picture"
                                component="label"
                                onClick={() => {
                                  navigate("/admin/add-category", {
                                    state: singleChallangeData,
                                  });
                                }}
                              >
                                <Index.EditIcon className="table-td-edit view-ic" />
                              </Index.IconButton>
                            </Index.MenuItem>

                            <Index.MenuItem
                              key={`delete_`}
                              onClick={handleActionClose}
                              className="menu-option-li"
                            >
                              <Index.IconButton
                                color="primary view-ic"
                                aria-label="upload picture"
                                component="label"
                                onClick={() =>
                                  handleDeleteOpen(singleChallangeData?._id)
                                }
                              >
                                <Index.DeleteIcon className="table-td-Inactive" />
                              </Index.IconButton>
                            </Index.MenuItem>

                          </Index.Menu>
                        </Index.Box>
                        {/* End Action Menu */}

                      </Index.Box>
                      <Index.Box className="pagination-main">
                        <PageIndex.Pagination
                          fetchData={filteredData}
                          setPageData={setPageData}
                          pageData={pageData}
                          search={search}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

          </Index.Box>
          <DeleteModal
            deleteOpen={deleteOpen}
            handleDeleteClose={handleDeleteClose}
            hanldeDeleteReocrd={handleDeleteCategoryData}
            handleModalName="Category"
          />
        </Index.Box>
      )}
    </>
  );
}
