import React, { useEffect, useState } from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import moment from "moment";
import {
  approveRejectAdvertisement,
  listOfAllCategory
} from "../../../../redux/slices/AdminService";
import { imageUrl } from "../../../../config/DataService";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { advertisementSchema } from "../../../../validations/Validation";
import MvpLoader from "../../../../components/common/mvpLoader/MvpLoader";

export default function ViewAdminAdvertisement() {
  // for open handleChangedropdown
  const [videoData, setVideoData] = useState(null);
  const [allCategoryList, setAllCategoryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const params = PageIndex.useLocation();
  const advertisementSingleData = params?.state;

  const categoryListData = () => {
    dispatch(listOfAllCategory()).then((res) => {
      setLoading(false);
      setAllCategoryList(res?.payload?.data);
    });
  };
  useEffect(() => {
    categoryListData();
  }, []);

  const initialValues = {
    title: advertisementSingleData?._id ? advertisementSingleData?.title : "",
    // video: "",
    category: advertisementSingleData?._id
      ? advertisementSingleData?.category?.map((category) => category?._id)
      : [],
    // duration: advertisementSingleData?._id
    //   ? advertisementSingleData?.duration
    //   : "",
    maxSpendLimitPerTransaction: advertisementSingleData?._id
      ? advertisementSingleData?.maxSpendLimitPerTransaction
      : "",
    startDate: advertisementSingleData?._id
      ? advertisementSingleData?.startDate
      : "",
    endDate: advertisementSingleData?._id
      ? advertisementSingleData?.endDate
      : "",
    rejectReason: advertisementSingleData?._id
      ? advertisementSingleData?.rejectReason
      : ""
  };

  ////////////// This is for add Update image video ///////////////

  const videoHandle = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    let checkVideo = file.type.match(/video/i);

    if (!checkVideo) {
      alert("Please upload a video (MP4 format)");
      return;
    } else {
      // Handle the video upload
      const newVideo = {
        type: "video",
        pre: URL.createObjectURL(file)
      };
      setVideoData(newVideo);
    }
  };

  useEffect(() => {
    if (advertisementSingleData) {
      const newVideoData = {
        type: "video",
        pre: `${imageUrl}/${advertisementSingleData.video}`,
        backend: true
      };
      setVideoData(newVideoData);
    }
  }, [advertisementSingleData]);

  const handleApproveRejectAdvertisement = async (id, status) => {
    const data = {
      id,
      status
    };
    dispatch(approveRejectAdvertisement(data)).then((res) => {
      if (res?.payload?.status === 200) {
        navigate("/admin/advertisement-list");
      }
    });
  };
console.log(advertisementSingleData,"advertisementSingleData")
  return (
    <Index.Box className="dashboard-content add-user-containt">
      <Index.Typography
        className="admin-page-title main-heading"
        component="h2"
        variant="h2"
      >
        View Advertisement
      </Index.Typography>
      {loading ? (
        <MvpLoader color="#1B698E" loading={loading} />
      ) : (
        <Index.Box className="table-bg-main">
          <PageIndex.Formik
            initialValues={initialValues}
            validationSchema={advertisementSchema}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldValue
            }) => (
              <PageIndex.Form>
                <Index.Box className="add-user-data-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6"
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Title<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              disabled
                              name="title"
                              as={Index.TextField}
                              // label="Carpet Area"
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 25) {
                                  // Only update the value if it's not more than 30 characters
                                  handleChange(event);
                                }
                              }}
                              onBlur={handleBlur}
                              error={Boolean(errors.title)}
                            />
                            {errors?.title && touched?.title ? (
                              <Index.Typography className="error-msg">
                                {errors?.title}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6"
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Category<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="dropdown-box">
                            <Index.Box className="form-control drop-form-control">
                              <Index.Select
                                name="category"
                                variant="outlined"
                                fullWidth
                                disabled
                                multiple // Enable multiple selections
                                value={values?.category}
                                id="fullWidth"
                                className="dropdown-select drop-select dropdown-property"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(errors.title)}
                                displayEmpty // Allow display of empty value
                                renderValue={(selected) =>
                                  selected.length
                                    ? selected
                                        .map(
                                          (value) =>
                                            allCategoryList.find(
                                              (category) =>
                                                category._id === value
                                            )?.Category_Name
                                        )
                                        .join(", ")
                                    : "Select Category"
                                } // Display selected category names
                              >
                                {/* Placeholder */}
                                <Index.MenuItem
                                  value=""
                                  disabled
                                  className="drop-menuitem"
                                >
                                  Select Category
                                </Index.MenuItem>
                                {/* Category options */}
                                {allCategoryList?.map((category) => (
                                  <Index.MenuItem
                                    value={category._id}
                                    key={category._id}
                                  >
                                    {category.Category_Name}
                                  </Index.MenuItem>
                                ))}
                              </Index.Select>
                              {errors?.category && touched?.category ? (
                                <Index.Typography className="error-msg">
                                  {errors?.category}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      {/* <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Duration<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              disabled
                              name="duration"
                              as={Index.TextField}
                              // label="Carpet Area"
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 10) {
                                  // Only update the value if it's not more than 30 characters
                                  handleChange(event);
                                }
                              }}
                              onBlur={handleBlur}
                              error={Boolean(errors.duration)}
                            />
                            {errors?.duration && touched?.duration ? (
                              <Index.Typography className="error-msg">
                                {errors?.duration}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box> */}
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6"
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Max Spend Limit Per Transaction<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              disabled
                              name="maxSpendLimitPerTransaction"
                              as={Index.TextField}
                              // label="Carpet Area"
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 10) {
                                  // Only update the value if it's not more than 30 characters
                                  handleChange(event);
                                }
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors.maxSpendLimitPerTransaction
                              )}
                            />
                            {errors?.maxSpendLimitPerTransaction &&
                            touched?.maxSpendLimitPerTransaction ? (
                              <Index.Typography className="error-msg">
                                {errors?.maxSpendLimitPerTransaction}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      {/* <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Max Spend Limit<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              disabled
                              name="maxSpendLimit"
                              as={Index.TextField}
                              // label="Carpet Area"
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 10) {
                                  // Only update the value if it's not more than 30 characters
                                  handleChange(event);
                                }
                              }}
                              onBlur={handleBlur}
                              error={Boolean(errors.maxSpendLimit)}
                            />
                            {errors?.maxSpendLimit && touched?.maxSpendLimit ? (
                              <Index.Typography className="error-msg">
                                {errors?.maxSpendLimit}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box> */}

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6"
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Start Date<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group custom-datepicker ">
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              className="date-adapter-main"
                            >
                              <DemoContainer
                                components={["DatePicker", "DatePicker"]}
                                className="date-adapter-container"
                              >
                                <DatePicker
                                  className="date-adapter-picker"
                                  disabled
                                  name="startDate"
                                  value={dayjs(values.startDate)}
                                  onChange={(e) => {
                                    const formatedDate = moment(e).format(
                                      "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
                                    );
                                    setFieldValue("startDate", formatedDate);
                                  }}
                                  format="DD/MM/YYYY"
                                  disablePast
                                />
                              </DemoContainer>
                            </LocalizationProvider>

                            {errors?.startDate && touched?.startDate ? (
                              <Index.Typography className="error-msg">
                                {errors?.startDate}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6"
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            End Date<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group custom-datepicker ">
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              className="date-adapter-main"
                            >
                              <DemoContainer
                                components={["DatePicker", "DatePicker"]}
                                className="date-adapter-container"
                              >
                                <DatePicker
                                  disabled
                                  className="date-adapter-picker"
                                  name="endDate"
                                  value={dayjs(values.endDate)}
                                  onChange={(e) => {
                                    const formatedDate = moment(e).format(
                                      "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
                                    );
                                    setFieldValue("endDate", formatedDate);
                                  }}
                                  format="DD/MM/YYYY"
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            {errors?.endDate && touched?.endDate ? (
                              <Index.Typography className="error-msg">
                                {errors?.endDate}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>


                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6"
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input mt-video-btn">
                          <Index.Box className="form-group flex-search-details w-100btn">
                            <Index.Button className="discard-user-btn border-btn fil_Button w-100btn"
                             variant="contained"
                             type="primary"
                             onClick={()=>{
                              navigate("/admin/view-advertiser",{
                                state:advertisementSingleData?.advertiserId
                              })
                             }}
                            //  className="fil_Button"
                            >
                              Advertiser Details
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      {/* <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Max Limit<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              disabled
                              name="maxLimit"
                              as={Index.TextField}
                              // label="Carpet Area"
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 10) {
                                  // Only update the value if it's not more than 30 characters
                                  handleChange(event);
                                }
                              }}
                              onBlur={handleBlur}
                              error={Boolean(errors.maxLimit)}
                            />
                            {errors?.maxLimit && touched?.maxLimit ? (
                              <Index.Typography className="error-msg">
                                {errors?.maxLimit}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box> */}
                      {/* <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Max Gas Fees Limit<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              disabled
                              name="maxGasFeesLimit"
                              as={Index.TextField}
                              
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 10) {
                                  handleChange(event);
                                }
                              }}
                              onBlur={handleBlur}
                              error={Boolean(errors.maxGasFeesLimit)}
                            />
                            {errors?.maxGasFeesLimit &&
                            touched?.maxGasFeesLimit ? (
                              <Index.Typography className="error-msg">
                                {errors?.maxGasFeesLimit}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>

                      
                       
                      </Index.Box> */}
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 6",
                          lg: "span 6"
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input add-game-row">
                          <Index.FormHelperText className="form-lable add-game-lables">
                            Advertisement Videos
                          </Index.FormHelperText>

                          <Index.Box className="grid-row">
                            <Index.Box className="grid-main">
                              <Index.Box className="display-row-add-user">
                                {videoData && videoData.type === "video" ? (
                                  <Index.Box className="position-relative">
                                    <Index.Button
                                      disabled
                                      sx={{ cursor: "pointer" }}
                                      className="document-close"
                                      onClick={() => {
                                        // Handle removal of the video
                                        if (videoData.backend) {
                                          // Add code to remove the video from the backend if necessary
                                        }
                                        setVideoData(null);
                                      }}
                                    >
                                      <Index.CloseTwoToneIcon />
                                    </Index.Button>
                                    <Index.Box className="document-close-set"></Index.Box>
                                    <Index.Box
                                      gridColumn={{
                                        xs: "span 12",
                                        sm: "span 3",
                                        md: "span 3",
                                        lg: "span 3"
                                      }}
                                    >
                                      <Index.Box>
                                        <video
                                          className="upload-img-slider"
                                          controls
                                        >
                                          <source
                                            src={videoData.pre}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                ) : (
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 3",
                                      md: "span 3",
                                      lg: "span 6"
                                    }}
                                  >
                                    <Index.Box className="images-upload-btn">
                                      <Index.Button
                                        variant="contained"
                                        component="label"
                                        className="save-user-btn border-btn image-upload-btn"
                                      >
                                        <img
                                          alt="img"
                                          src={Index.Svg.plus}
                                          className="upload-img"
                                        />
                                        <input
                                          hidden
                                          accept="video/mp4"
                                          disabled
                                          name="video"
                                          type="file"
                                          onClick={(e) => {
                                            e.target.value = null;
                                          }}
                                          onChange={(e) => {
                                            videoHandle(e);
                                          }}
                                        />
                                      </Index.Button>
                                    </Index.Box>
                                  </Index.Box>
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                  

                      {/* <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Reject reason
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              disabled
                              name="rejectReason"
                              as={Index.TextField}
                              // label="Carpet Area"
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box> */}

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12"
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="user-btn-flex back-appr-btn-sec">
                          <Index.Box className="discard-btn-main border-btn-main">
                            <Index.Button
                              className="discard-user-btn border-btn"
                              onClick={() =>
                                navigate("/admin/advertisement-list")
                              }
                            >
                              Back
                            </Index.Button>
                          </Index.Box>
                          {/* {advertisementSingleData?.isApproved ===
                          "Rejected" ? null : (
                            <Index.Box className="discard-btn-main border-btn-main">
                              <Index.Button
                                className="discard-user-btn border-btn"
                                onClick={() =>
                                  handleApproveRejectAdvertisement(
                                    advertisementSingleData?._id,
                                    "Approved"
                                  )
                                }
                                disabled={
                                  advertisementSingleData?.isApproved ==
                                    "Approved" ||
                                  advertisementSingleData?.isApproved ==
                                    "Rejected"
                                }
                              >
                                Approve
                              </Index.Button>
                            </Index.Box>
                          )} */}
                        </Index.Box>
                      </Index.Box>
                      {/* <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="user-btn-flex">
                          <Index.Box className="discard-btn-main border-btn-main">
                            <Index.Button
                              className="discard-user-btn border-btn"
                              onClick={() =>
                                handleApproveRejectAdvertisement(
                                  advertisementSingleData?._id,
                                  "Approved"
                                )
                              }
                              disabled={
                                advertisementSingleData?.isApproved ===
                                  "Approved" ||
                                advertisementSingleData?.isApproved ===
                                  "Rejected"
                              }
                            >
                              Approve
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box> */}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </PageIndex.Form>
            )}
          </PageIndex.Formik>
        </Index.Box>
      )}
    </Index.Box>
  );
}
