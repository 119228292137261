import React from "react";
import HashLoader from "react-spinners/HashLoader";
import "./mvploader.css";
import Index from "../../Index";
import PageIndex from "../../PageIndex";

const MvpLoader = () =>
  //   {
  //   color,
  //   loading,
  //   ariaLabel,
  //   dataTestId,
  // }
  {
    return (
      // <HashLoader
      //   color={color}
      //   loading={loading}
      //   size={35}
      //   aria-label={ariaLabel}
      //   data-testid={dataTestId}
      //   style={{ transform: "none" }}
      // />
      <Index.Box className="spinner-box">
        <Index.Box className="configure-border-1">
          <Index.Box className="configure-core"></Index.Box>
        </Index.Box>
        <Index.Box className="configure-border-2">
          <Index.Box className="configure-core">
            <img
              src={PageIndex.Png.flexo}
              width="512"
              height="512"
              className="head-logo-icon"
              alt="Company Logo"
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    );
  };

export default MvpLoader;
