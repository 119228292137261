import React from 'react'
import Index from '../Index'
import PageIndex from '../PageIndex'
import { styled } from "@mui/material/styles";


function WalletAlerts() {
      // for custom switch design

  const IOSSwitch = styled((props) => (
    <Index.Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 34,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 3,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(14px)',
        color: '#112A35',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#00000000',
          opacity: 1,
          border: '1px solid #112A35',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #112A35',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 14,
      height: 14,
      boxShadow: "none",
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#A6A8AA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  // for custom switch design End
  return (
    <>
     <Index.Box className="swap-sec Alerts-wrapper">
                  <Index.Box className="swap-header-sec cus-between">
                    <Index.Box className="left-errow-sec">
                      <img src={PageIndex.Svg.arrowBack} alt="back-arrow" className="backarrow-img" />
                    </Index.Box>
                    <Index.Box className="swap-header-title">
                      <Index.Typography component="p" variant="p" className="swap-title">Alerts</Index.Typography>
                    </Index.Box>
                    <Index.Box className="swap-header-icon">
                      <img src={PageIndex.Svg.closeSetting} alt="close" className="colse-setting-img" />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="search-main">
                    <Index.Box className="input-wrapper search-wrapper">
                      <Index.Box className="input-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="cus-input"
                          placeholder="Search..."
                        />
                      </Index.Box>
                      <img src={PageIndex.Svg.search2} alt="search" className="inp-search-ic" />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="alert-content-sec">
                    <Index.Box className="alert-detail cus-between">
                      <Index.Box className="alert-left">
                        <Index.Typography component="p" variant="p" className="alert-text">Browsing a website with an
                          unconnected account selected</Index.Typography>
                        <img src={PageIndex.Svg.information} alt="information" className="information-img" />
                      </Index.Box>
                      <Index.Box className="alert-switch-sec">
                        <Index.Box className="switch-main">
                          <Index.FormControlLabel
                            control={<IOSSwitch defaultChecked />}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="alert-detail cus-between">
                      <Index.Box className="alert-left">
                        <Index.Typography component="p" variant="p" className="alert-text">When a website tries to use
                          the removed window.web3 API</Index.Typography>
                        <img src={PageIndex.Svg.information} alt="information" className="information-img" />
                      </Index.Box>
                      <Index.Box className="alert-switch-sec">
                        <Index.Box className="switch-main">
                          <Index.FormControlLabel
                            control={<IOSSwitch defaultChecked />}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box> 
    </>
  )
}

export default WalletAlerts
