import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { Skeleton } from "@mui/material";
import MvpLoader from "../../../../components/common/mvpLoader/MvpLoader";
import moment from "moment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  activeDeactivePublisher,
  approveRejectPublisher,
  getListAllPublisher
} from "../../../../redux/slices/AdminService";
import FileExcel from "../../../../components/common/fileExcel/FileExcel";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#102934",
    color: "#ffffff",
    fontSize: "11px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px"
  }
}));

export default function PubliserList() {
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [publisherList, setPublisherList] = useState([]);
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [singleChallangeData, setSingleChallangeData] = useState({})

  const [excel, setExcel] = useState([]);
  const fileName = "myfile";
  const navigate = PageIndex?.useNavigate();
  const dispatch = PageIndex?.useDispatch();
  // Get all advertisement list
  const open1 = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const getAllPublisherList = async () => {
    dispatch(getListAllPublisher()).then((res) => {
      setPublisherList(res?.payload?.data);

      setLoading(false);

    });
  };
  const onOpenModal = () => {
    setModalOpen(true);
  };
  const onCloseModal = () => {
    setModalOpen(false);
  };
  const handleApproveRejectPublisher = async (id, status) => {
    const data = {
      id,
      status
    };
    dispatch(approveRejectPublisher(data)).then((res) => {
      if (res?.payload?.status === 200) {
        getAllPublisherList();
      }
    });
  };
  const handleActiveDeactivePublisher = async (id, status) => {
    const data = {
      id,
      status: String(status)
    };
    dispatch(activeDeactivePublisher(data)).then((res) => {
      if (res?.payload?.status === 200) {
        getAllPublisherList();
      }
    });
  };
  useEffect(() => {
    const customHeadings = publisherList?.map((item) => ({
      "Name": item?.name,
      "Email": item?.email,
      "Company": item?.company,
      "IsApproved": item?.isApproved,
      "Publish URL": item?.publishUrl,
      "CreatedAt": moment(item.createdAt).format("DD/MM/YYYY"),
    }));

    setExcel(customHeadings);
  }, [publisherList]);

  useEffect(() => {
    const filtered =
      publisherList &&
      publisherList?.filter((item) => {
        return !search
          ? item
          : item?.company?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.email?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.name?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.isApproved?.toLowerCase().includes(search?.toLowerCase()) ||
          moment(item?.createdAt)
            .format("DD-MM-YYYY")
            ?.toString()
            .includes(search?.toLowerCase());
      });

    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [publisherList, search]);

  // region useEffects
  useEffect(() => {
    getAllPublisherList();
  }, []);

  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const handleActionOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      {loading ? (
        <Index.Box className="dashboard-content user-list-content">
          <Index.Box className="user-list-flex">
            <Index.Typography
              className="admin-page-title user-list-page-title main-heading"
              component="h2"
              variant="h2"
            >
              Publisher
            </Index.Typography>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box flex-search-details">
                  <Index.Box className="form-group search-box-bg">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <img
                      src={PageIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                  <Index.Box>
                    <FileExcel apiData={excel} fileName={fileName} />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-bg-main table-main-box">
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box pb-0">
                      <Index.Box className="userlist-table-main page-table-main">
                        <Index.TableContainer component={Index.Paper} className="table-container">
                          <Index.Table aria-label="simple table" className="table">
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell className="table-th">Action</Index.TableCell>
                                <Index.TableCell className="table-th">Active/Inactive</Index.TableCell>
                                <Index.TableCell className="table-th">Name</Index.TableCell>
                                <Index.TableCell className="table-th">Wallet</Index.TableCell>
                                <Index.TableCell className="table-th">Company</Index.TableCell>
                                <Index.TableCell className="table-th">Email</Index.TableCell>
                                <Index.TableCell className="table-th">Pubish URL</Index.TableCell>
                                <Index.TableCell className="table-th">Date</Index.TableCell>
                                <Index.TableCell className="table-th">Status</Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {[...Array(5)].map((_, index) => (
                                <Index.TableRow key={index}>
                                  <Index.TableCell width="10%">
                                    <Skeleton variant="circular" width={40} height={40} />
                                  </Index.TableCell>
                                  <Index.TableCell width="12%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="15%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="15%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="10%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="10%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="10%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="10%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      ) : (
        <Index.Box className="dashboard-content user-list-content">
          <Index.Box className="user-list-flex">
            <Index.Typography
              className="admin-page-title user-list-page-title main-heading"
              component="h2"
              variant="h2"
            >
              Publisher
            </Index.Typography>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box flex-search-details">
                  <Index.Box className="form-group search-box-bg">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <img
                      src={PageIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                  <Index.Box>
                    <FileExcel apiData={excel} fileName={fileName} />
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="userlist-inner-btn-flex">
                {/* <Index.Box className="export-btn-main border-btn-main">
                <Index.Button
                  className="export-btn border-btn"
                  onClick={handleOpenDelete}
                >
                  <img
                    src={PageIndex.Svg.down}
                    className="down-icon"
                    alt="download icon"
                  />
                  Export
                </Index.Button>
              </Index.Box> */}
                {/* <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={handleOpen}
                >
                  <img
                    src={PageIndex.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add User
                </Index.Button>
              </Index.Box> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-bg-main table-main-box">
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12"
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box pb-0">
                      <Index.Box className="advertiserlist-table-main page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Action
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Active/Inactive
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Name
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Wallet
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Company
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Email
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Publish URL
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Date
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Status
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            {pageData && pageData?.length > 0 ? (
                              <Index.TableBody className="table-body">
                                {pageData.length ? (
                                  pageData.map((row, index) => (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0
                                        }
                                      }}
                                    >

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td action-tab"
                                      >
                                        <Index.IconButton
                                          aria-label="more"
                                          id="long-button"
                                          aria-haspopup="true"
                                          onClick={(event) => {
                                            handleActionOpen(event);
                                            setSingleChallangeData(
                                              pageData?.[index]
                                            );
                                          }}
                                          sx={{ filter: "brightness(0%)" }}
                                        >
                                          <Index.MoreVertIcon />
                                        </Index.IconButton>
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        <Index.Box className="userdata-btn-flex">
                                          {/* {row?.isApproved === "Approved" ? (
                                          <Index.Button
                                            disabled={
                                              row?.isApproved === "Approved" ||
                                              row?.isApproved === "Rejected"
                                            }
                                            onClick={() =>
                                              handleApproveRejectPublisher(
                                                row?._id,
                                                "Approved"
                                              )
                                            }
                                          >
                                            <Index.CheckIcon className="table-td-active" />
                                            <Index.CheckCircleIcon className="table-td-active" />
                                          </Index.Button>
                                          { ) : row?.isApproved === "Rejected" ? ( }
                                          <Index.Button
                                            disabled={
                                              row?.isApproved === "Rejected" ||
                                              row?.isApproved === "Approved"
                                            }
                                            onClick={() =>
                                              handleApproveRejectPublisher(
                                                row?._id,
                                                "Rejected"
                                              )
                                            }
                                          >
                                            <Index.CloseIcon className="table-td-Inactive" />
                                            <Index.CancelIcon className="table-td-Inactive" />
                                          </Index.Button> */}

                                          {/* <Index.IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            onClick={() => {
                                              navigate(
                                                "/admin/view-publisher",
                                                {
                                                  state: row
                                                }
                                              );
                                            }}
                                          >
                                            <Index.RemoveRedEyeIcon className="view-ic" />
                                          </Index.IconButton> */}

                                          {/* <Index.IconButton
                                            color="primary view-ic"
                                            aria-label="upload picture"
                                            component="label"
                                            onClick={() => {
                                              navigate("/admin/edit-publisher", {
                                                state: row,
                                              });
                                            }}
                                          >
                                            <Index.EditIcon className="table-td-edit view-ic" />
                                          </Index.IconButton> */}

                                          <Index.Switch
                                            className="cus-switch"
                                            checked={row?.isActive}
                                            onChange={(e) =>
                                              handleActiveDeactivePublisher(
                                                row?._id,
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </Index.Box>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {row.name || "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {console.log(row,"row")}
                                        {row?.walletAmount.toFixed(10) }
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row.company || "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row.email || "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row.publishUrl === null ? (
                                          "-"
                                        ) : (
                                          <LightTooltip title={row.publishUrl}>
                                            {
                                              row.publishUrl
                                                // ?.split(" ")
                                                ?.slice(0, 15) + "..."
                                              // ?.join(" ")
                                            }
                                            {/* {row.committeeRemark
                                     ?.split(" ")
                                     ?.length > 20
                                     ? " ..."
                                     : ""} */}
                                          </LightTooltip>
                                        )}
                                        {/* {row.publisherUrl || "-"} */}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.createdAt
                                          ? moment(row?.createdAt).format(
                                            "DD-MM-YYYY"
                                          )
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td status-col"
                                      >
                                        <div
                                          className={
                                            row?.isApproved === "Approved"
                                              ? "table-td-active"
                                              : row?.isApproved === "Rejected"
                                                ? "table-td-Inactive"
                                                : "table-td-Pending"
                                          }
                                        >
                                          {row?.isApproved === "Approved"
                                            ? "Approved"
                                            : row?.isApproved === "Rejected"
                                              ? "Rejected"
                                              : "Pending"}
                                        </div>
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  ))
                                ) : (
                                  <Index.TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0
                                      }
                                    }}
                                  >
                                    <Index.TableCell align="center" colSpan={5}>
                                      No record available
                                    </Index.TableCell>
                                  </Index.TableRow>
                                )}
                              </Index.TableBody>
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td no-record-text"
                                  align="center"
                                  colSpan={8}
                                >
                                  <h3> No records available </h3>
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.Table>
                        </Index.TableContainer>

                        {/* Action Menu */}
                        <Index.Box className="admin-menu-box">
                          <Index.Menu
                            className="admin-menu-main"
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl}
                            open={open1}
                            onClose={handleActionClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "auto",
                                boxShadow: "none",
                                border: "1px solid rgba(224, 224, 224, 1)",
                                marginTop: "6px",
                              },
                            }}
                          >
                            <Index.MenuItem
                              key={`view_`}
                              onClick={handleActionClose}
                              className="menu-option-li"
                            >
                              <Index.Button
                                disabled={
                                  singleChallangeData?.isApproved === "Approved" ||
                                  singleChallangeData?.isApproved === "Rejected"
                                }
                                onClick={() =>
                                  handleApproveRejectPublisher(
                                    singleChallangeData?._id,
                                    "Approved"
                                  )
                                }
                              >
                                <Index.CheckIcon className="table-td-active" />
                                {/* <Index.CheckCircleIcon className="table-td-active" /> */}
                              </Index.Button>
                            </Index.MenuItem>

                            <Index.MenuItem
                              key={`delete_`}
                              onClick={handleActionClose}
                              className="menu-option-li"
                            >
                              <Index.Button
                                disabled={
                                  singleChallangeData?.isApproved === "Rejected" ||
                                  singleChallangeData?.isApproved === "Approved"
                                }
                                onClick={() =>
                                  handleApproveRejectPublisher(
                                    singleChallangeData?._id,
                                    "Rejected"
                                  )
                                }
                              >
                                <Index.CloseIcon className="table-td-Inactive" />
                                {/* <Index.CancelIcon className="table-td-Inactive" /> */}
                              </Index.Button>
                            </Index.MenuItem>

                            <Index.MenuItem
                              key={`delete_`}
                              onClick={handleActionClose}
                              className="menu-option-li"
                            >
                              <Index.IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                onClick={() => {
                                  navigate(
                                    "/admin/view-publisher",
                                    {
                                      state: singleChallangeData
                                    }
                                  );
                                }}
                              >
                                <Index.RemoveRedEyeIcon className="view-ic" />
                              </Index.IconButton>
                            </Index.MenuItem>

                            <Index.MenuItem
                              key={`delete_`}
                              onClick={handleActionClose}
                              className="menu-option-li"
                            >
                              <Index.IconButton
                                color="primary view-ic"
                                aria-label="upload picture"
                                component="label"
                                onClick={() => {
                                  navigate("/admin/edit-publisher", {
                                    state: singleChallangeData,
                                  });
                                }}
                              >
                                <Index.EditIcon className="table-td-edit view-ic" />
                              </Index.IconButton>
                            </Index.MenuItem>

                          </Index.Menu>
                        </Index.Box>
                        {/* End Action Menu */}

                      </Index.Box>
                      <Index.Box className="pagination-main">
                        <Index.Box className="pagination-main">
                          <PageIndex.Pagination
                            fetchData={filteredData}
                            setPageData={setPageData}
                            pageData={pageData}
                            search={search}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
}
