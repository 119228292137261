const ADMIN = "admin";
const ADVERTISER = "advertiser";
const PUBLISHER = "publisher";
const COMMON = "common";
const ROLE = "role";

const Api = {
  // Common routes
  FORGOT_PASSWORD: `${COMMON}/forgot-password`,
  VERIFY_OTP: `${COMMON}/verify-otp`,
  RESET_PASSWORD: `${COMMON}/reset-password`,

  // Admin routes
  ADMIN_LOGIN: `${ADMIN}/admin-login`,
  CHANGE_ADMIN_PASSWORD: `${ADMIN}/admin-change-password`,
  UPDATE_ADMIN_DATA: `${ADMIN}/update-admin-profile`,
  ALL_DASHBOARD_DATA: `${ADMIN}/dashboard`,
  ALL_USER_DATA: `${ADMIN}/getallprofile`,
  UPDATE_PUBLIHSER_AMOUNT:`${ADMIN}/publisher-add-amount`,

 


  ADVERTISER_LIST: `${ADMIN}/get-all-advertiser`,
  UPDATE_ADVERTISER_AMOUNT:`${ADMIN}/advertiser-addWalletAmount`,

  ACTIVE_DEACTIVE_ADVERTISER: `${ADMIN}/active-deactive-advertiser`,
  APPROVE_REJECT_ADVERTISER: `${ADMIN}/approve-reject-advertiser`,
  APPROVE_REJECT_ADVERTISEMENT: `${ADMIN}/approve-reject-advertisement`,
  ACTIVE_DEACTIVE_ADMIN_ADVERTISEMENT: `${ADMIN}/active-deactive-advertisement`,

  CREATE_CATEGORY_DATA: `${ADMIN}/category/add-edit`,
  ALL_CATEGORY_LIST: `${ADMIN}/category/get-all-categories`,
  DELETE_CATEGORY_DATA: `${ADMIN}/category/delete-categorie`,

  GET_ALL_ADMIN_PUBLISHER_LIST: `${ADMIN}/get-all-publisher`,
  APPROVE_REJECT_PUBLISHER: `${ADMIN}/approve-reject-publisher`,
  ACTIVE_DEACTIVE_PUBLISHER: `${ADMIN}/active-deactive-publisher`,

  // presale routes
  CREATE_PRESALE_DATA: `${ADMIN}/private-sales-addedit`,
  ALL_PRESALE_LIST: `${ADMIN}/private-sales`,

  // Advertiser route
  CREATE_ADVERTISEMENT: `${ADVERTISER}/create-update-Advertisement`,
  ALL_ADVERTISEMENT_LIST: `${ADVERTISER}/getall-advertisement`,
  PAUSE_RESTART_ADVERTISEMENT: `${ADVERTISER}/advertisement-pause-restart`,
  DELETE_ADVERTISEMENT: `${ADVERTISER}/delete-advertisements`,
  CHANGE_ADVERTISER_PASSWORD: `${ADVERTISER}/advertiser-change-password`,
  ADVERTISER_DASHBOARD: `${ADVERTISER}/deshboard-advertisements`,


  // Publisher route
  CHANGE_PUBLISHER_PASSWORD: `${PUBLISHER}/publisher-change-password`,
  ALL_TRANSATION_HISTORY: `${PUBLISHER}/transaction-history`,
  ADVERTISER_PUBLISHER_DATA: `${PUBLISHER}/getadrecordbypublisher`,
  PUBLISHER_DASHBOARD: `${PUBLISHER}/publisher-deshoard`,



  // Role routes
  CREATE_ADVERTISER_PUBLISHER: `${ROLE}/create-update-role`,
  ADVERTISER_PUBLISHER_LOGIN: `${ROLE}/role-login`,
  CATEGORY_LIST: `${ROLE}/get-all-categories`,
  
};
export default Api;
