import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = () => {
  const dates = [
    { x: '2024-02-06', y: 1500000 },
    { x: '2024-03-12', y: 2000000 },
    { x: '2024-04-08', y: 1000000 },
    { x: '2024-05-02', y: 3000000 },
    { x: '2024-08-16', y: 2500000 },
    // Add more data points as needed
  ];
  const [series, setSeries] = useState([{
    name: 'XYZ MOTORS',
    data: dates // Make sure `dates` is defined or passed as a prop
  }]);

  const [options, setOptions] = useState({
    chart: {
      type: 'area',
      stacked: false,
      height: 350,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom'
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
    },
    title: {
      text: 'Stock Price Movement',
      align: 'left'
    },
    stroke: {
      colors: ['#3C6721']
    },
    fill: {
      colors: ['#96D172', '#A6D886', '#B6E09C'],
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100]
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return (val / 1000000).toFixed(0);
        },
      },
      title: {
        text: 'Price'
      },
    },
    xaxis: {
      type: 'datetime',
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return (val / 1000000).toFixed(0);
        }
      }
    }
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="area" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default ApexChart;
