import logo from "./logo.svg";
import "./App.css";
import Routes from "./routes/Routes";
import "./components/admin/defaultLayout/defaultlayout.css";
import "./components/admin/defaultLayout/defaultlayout.responsive.css";
import "./assets/style/global.css";
import "./container/admin/auth/auth.css";
import "./container/admin/auth/auth.responsive.css";
import "./container/admin/pages/layout/layout.css";
import "./container/admin/pages/layout/layout.responsive.css";
import "./container/admin/pages/home/home.css";
import "./container/admin/pages/home/home.responsive.css";
import "./container/admin/pages/userManagementList/usermanagement.css";
import "./container/admin/pages/userManagementList/usermanagement.responsive.css";
import "./container/admin/pages/advertiser/advertiser.css";
import "./container/admin/pages/advertiser/advertiser.responsive.css";
import "./container/admin/pages/publisher/publiser.css";
import "./container/admin/pages/publisher/publiser.responsive.css";
import "./container/admin/pages/editprofile/editprofile.css";
import "./container/admin/pages/editprofile/editprofile.responsive.css";
import './container/user/pages/userLayout/userLayout.css'
import './container/user/pages/userLayout/userLayout.responsive.css'
import './container/user/pages/userDashbord/userDashboard.css'
import './container/user/pages/userDashbord/userDashbord.responsive.css'
import './components/user/defaultLayout/defaultLayout.css'
import './components/user/defaultLayout/defaultLayout.responsive.css'
import "./assets/style/global.responsive.css";
import "./assets/style/userglobal.css";


function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
