import React, { useState } from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import { ResetPasswordSchema } from "../../../../validations/Validation";
import { resetRolePassword } from "../../../../redux/slices/AdminService";
import ButtonMvpLoader from "../../../../components/common/buttonMvpLoader/ButtonMvpLoader";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
export default function ResetPassword({ handleLogin, verifyEmail }) {
  // for password eye hide and show

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = PageIndex?.useDispatch();
  

  const handleShowPassword = () => setShowPassword((show) => !show);
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const HandleResetPaaswordSubmit = (values) => {
    setLoading(true);
    const resetPasswordData = {
      password: values?.password,
      confirmPassword: values?.confirmPassword,
      email: verifyEmail,
    };
    dispatch(resetRolePassword(resetPasswordData)).then((res) => {
      setTimeout(() => {
        setLoading(false);
      }, 6000);
      if (
        (res?.payload !== undefined && res?.payload?.status == 200) ||
        res?.payload?.status == 201
      ) {
        setLoading(false);
        handleLogin();
      }
    });
  };

  return (
    <div>
      <Index.Box className="">
        <Index.Formik
          validationSchema={ResetPasswordSchema}
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          onSubmit={HandleResetPaaswordSubmit}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleChange,
            handleSubmit,
            handleBlur,
            isValid,
            touched,
            dirty,
          }) => (
            <Index.Form>
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Reset Password!
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                  {/* Please enter your credentials to Forgot Password ! */}
                </Index.Typography>
                <Index.Box className="input-box">
                  <Index.FormHelperText className="form-lable">
                    New Password<span>*</span>
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.OutlinedInput
                      className="form-control-eye from-control-password "
                      autocomplete="off"
                      placeholder="Please enter new password"
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={(e) => {
                        const inputValue = e.target.value.trim(); // Trim whitespace
                        if (inputValue.length >= 0) { // Check if not empty after trimming
                          setFieldValue("password", inputValue.slice(0, 25));
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.password}
                      onKeyDown={ e => e.keyCode === 32 && e.preventDefault() }
                      endAdornment={
                        <Index.InputAdornment position="end">
                          <Index.IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            onMouseDown={(event) => {
                              event.preventDefault();
                            }}
                            onMouseUp={(event) => {
                              event.preventDefault();
                            }}
                            edge="end"
                          >
                            {!showPassword ? (
                             <Index.VisibilityOff />
                          
                            ) : (
                              <Index.Visibility />
                           
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
               
                    />
                    {errors?.password && touched?.password ? (
                      <Index.Typography
                        className="error-msg"
                        // style={{ marginTop: "-21px" }}
                      >
                        {errors?.password}
                      </Index.Typography>
                    ) : null}
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box">
                  <Index.FormHelperText className="form-lable">
                    Confirm Password<span>*</span>
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.OutlinedInput
                      className="form-control-eye from-control-password "
                      autocomplete="off"
                      placeholder="Please enter confirm password"
                      id="outlined-adornment-password"
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      onChange={(e) => {
                        const inputValue = e.target.value.trim(); // Trim whitespace
                        if (inputValue.length >= 0) { // Check if not empty after trimming
                          setFieldValue("confirmPassword", inputValue.slice(0, 8));
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                      endAdornment={
                        <Index.InputAdornment position="end">
                          <Index.IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowConfirmPassword}
                            onMouseDown={(event) => {
                              event.preventDefault();
                            }}
                            onMouseUp={(event) => {
                              event.preventDefault();
                            }}
                            edge="end"
                          >
                            {!showConfirmPassword ? (
                                <Index.VisibilityOff />
                            ) : (
                              <Index.Visibility />
     
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
                      onKeyDown={ e => e.keyCode === 32 && e.preventDefault() }
                    />
                    {errors?.confirmPassword && touched?.confirmPassword ? (
                      <Index.Typography
                        className="error-msg"
                        // style={{ marginTop: "-21px" }}
                      >
                        {errors?.confirmPassword}
                      </Index.Typography>
                    ) : null}
                  </Index.Box>
                </Index.Box>

                <Index.Box className="btn-main-primary login-btn-main">
                  <Index.Button
                    className="btn-primary login-btn"
                    type="submit"
                    disabled={loading}
                    // startIcon={
                    //   loading ? (
                    //     <ButtonMvpLoader color="white" loading={loading} />
                    //   ) : null
                    // }
                  >
                    {loading ? (
                      <ButtonMvpLoader color="white" loading={loading} />
                    ) : (
                      "Submit"
                    )}
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Form>
          )}
        </Index.Formik>
      </Index.Box>
    </div>
  );
}
