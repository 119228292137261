import React, { useEffect, useState } from "react";

import ForgotPassword from "../../../container/admin/auth/forgotpassword/ForgotPassword";
import Otp from "../../../container/admin/auth/otp/Otp";
import Index from "../../../container/Index";
import Login from "../../../container/admin/auth/login/Login";
import PageIndex from "../../PageIndex";
import ResetPassword from "../../../container/admin/auth/resetpassword/ResetPassword";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 2,
  borderRadius: 1,
};
export const CommonModal = () => {
  const [verifyId, setVerifyId] = useState(null);
  const [verifyEmail, setVerifyEmail] = useState("");
  const [userToken, setUserToken] = useState(null);
  const [open, setOpen] = useState(false);
  const [authStep, setAuthStep] = useState("Login");
  const [id, setId] = useState();

  //Open
  const handleOpen = () => {
    setOpen(true);
  };

  //Close
  const handleClose = () => {
    setOpen(false);
  };

  const handleAddUser = () => {
    setAuthStep("Add_User");
  };

  const handleLogin = () => {
    setAuthStep("Login");
  };

  const handleForgot = () => {
    setAuthStep("forgot-password");
  };

  const handleOtpSend = () => {
    setAuthStep("Otp_Send");
  };

  const handleResetPassword = () => {
    setAuthStep("Reset_Password");
  };
  const handleVerifyOtp = (res) => {
    setVerifyId(res);
    setAuthStep("Otp_Verify");
  };
  const handdleCategorySelect = (res) => {
    setAuthStep("Catergory_Select");
    setUserToken(res);
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <>
      <Index.Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-auth modal-login"
      >
        <Index.Box sx={style} className="delete-modal-inner-main">
          <Index.Box>
            <Index.Box className="modal-logo-sec cus-center">
              <img
                src={PageIndex.Png.flexoLogo}
                className="login-modal-logo"
                alt="logo"
              />
            </Index.Box>
            {authStep === "Login" && (
              <Login
                handleClose={handleClose}
                handleAddUser={handleAddUser}
                onClose={handleClose}
                handleForgot={handleForgot}
                setAuthStep={setAuthStep}
              />
            )}

            {authStep === "forgot-password" && (
              <ForgotPassword
                handleClose={handleClose}
                handleAddUser={handleAddUser}
                onClose={handleClose}
                handleOtpSend={handleOtpSend}
                setAuthStep={setAuthStep}
                setVerifyEmail={setVerifyEmail}
                handleLogin={handleLogin}
              />
            )}

            {authStep === "Otp_Send" && (
              <Otp
                handleVerifyOtp={handleVerifyOtp}
                handleClose={handleClose}
                handleResetPassword={handleResetPassword}
                verifyEmail={verifyEmail}
              />
            )}
            {authStep === "Reset_Password" && (
              <ResetPassword
                handleCaterSelect={handdleCategorySelect}
                handleVerifyOtp={handleVerifyOtp}
                handleClose={handleClose}
                verifyId={verifyId}
                handleLogin={handleLogin}
                verifyEmail={verifyEmail}
              />
            )}

            {/* {authStep === "Catergory_Select" && (
                        <Catergoryselect
                        handleClose={handleClose}
                            
                            handleOtpSend={handleOtpSend}
                            userToken={userToken}
                      
                        />
                    )}  */}
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};
