import { SwipeableDrawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import MvpLoader from "../../../../components/common/mvpLoader/MvpLoader";
import { Skeleton } from "@mui/material";
import {
  adminTransactionHistory,
  listOfAdvertiserUser,
  transationHistory
} from "../../../../redux/slices/AdminService";
import "./transaction.css";
import moment from "moment";
import FileExcel from "../../../../components/common/fileExcel/FileExcel";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24
};

// for table data

function createData(name, address, city, number, stutus, action) {
  return { name, address, city, number, stutus, action };
}

const rows = [
  createData(
    <Index.Box className="userlist-data">
      <img src={PageIndex.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS"
  ),

  createData(
    <Index.Box className="userlist-data">
      <img src={PageIndex.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS"
  ),

  createData(
    <Index.Box className="userlist-data">
      <img src={PageIndex.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS"
  ),

  createData(
    <Index.Box className="userlist-data">
      <img src={PageIndex.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS"
  )
];

function Transaction() {
  const [age, setAge] = useState("");
  const [loading, setLoading] = useState(true);
  const [transationHistoryData, setTrnsationHistoryData] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [excel, setExcel] = useState([]);
  const fileName = "myfile";
  const [pageData, setPageData] = useState([]);

  const dispatch = PageIndex?.useDispatch();
  useEffect(() => {
    dispatch(transationHistory()).then((res) => {
      setTrnsationHistoryData(res?.payload?.data);

      setLoading(false);
    });
  }, []);
  useEffect(() => {
    const customHeadings = transationHistoryData?.map((item) => ({
      "Created Date": moment(item.createdAt).format("DD/MM/YYYY"),
      From: item?.from,
      to: item?.to,
      "Hash	": item?.transactionHash,
      Amount: item?.value,
      Url: item?.url,
      Gasless: item?.gasless
    }));

    setExcel(customHeadings);
  }, [transationHistoryData]);

  useEffect(() => {
    const filtered =
      transationHistoryData &&
      transationHistoryData?.filter((item) => {
        return !search
          ? item
          : item?.from?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.to?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.transactionHash
            ?.toLowerCase()
            .includes(search?.toLowerCase()) ||
          item?.value
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase()) ||
          item?.url
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase()) ||
          item?.gasless
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase());
      });

    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [transationHistoryData, search]);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Index.Box
      className="filter-main"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    ></Index.Box>
  );

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  return (
    <>
      {loading ? (
        <Index.Box className="dashboard-content user-list-content">
          <Index.Box className="user-list-flex">
            <Index.Typography
              className="admin-page-title user-list-page-title main-heading"
              component="h2"
              variant="h2"
            >
              Transaction
            </Index.Typography>

            <Index.Box className="userlist-btn-flex res-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box flex-search-details">
                  <Index.Box className="form-group search-box-bg">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <img
                      src={PageIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                  <Index.Box>
                    <FileExcel apiData={excel} fileName={fileName} />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-bg-main table-main-box">
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box pb-0">
                      <Index.Box className="userlist-table-main page-table-main">
                        <Index.TableContainer component={Index.Paper} className="table-container">
                          <Index.Table aria-label="simple table" className="table">
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell className="table-th">From</Index.TableCell>
                                <Index.TableCell className="table-th">To</Index.TableCell>
                                <Index.TableCell className="table-th">Hash</Index.TableCell>
                                <Index.TableCell className="table-th">Gasless</Index.TableCell>
                                <Index.TableCell className="table-th">Amount($)</Index.TableCell>
                                <Index.TableCell className="table-th">Url</Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {[...Array(5)].map((_, index) => (
                                <Index.TableRow key={index}>
                                  <Index.TableCell width="10%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="12%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="15%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="15%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="10%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="10%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      ) : (
        <Index.Box className="dashboard-content user-list-content">
          <Index.Box className="user-list-flex">
            <Index.Typography
              className="admin-page-title user-list-page-title main-heading"
              component="h2"
              variant="h2"
            >
              Transaction
            </Index.Typography>

            <Index.Box className="userlist-btn-flex res-flex">
              <Index.Box className="header-date-picker add-user-input">

              </Index.Box>
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box flex-search-details">
                  <Index.Box className="form-group search-box-bg">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <img
                      src={PageIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                  <Index.Box>
                    <FileExcel apiData={excel} fileName={fileName} />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

          </Index.Box>
          <Index.Box className="table-bg-main table-main-box">
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12"
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box pb-0">
                      <Index.Box className="advertiserlist-table-main transaction-table-main page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  From
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  To
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Hash
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Gasless
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Amount($)
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Url
                                </Index.TableCell>

                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {pageData && pageData?.length > 0 ? (
                                pageData?.map((row) => (
                                  <Index.TableRow
                                    // key={row.name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0
                                      }
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row.from}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.to}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.transactionHash}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.gasless == true ? "true" : "false"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.value}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.url}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <Index.TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0
                                    }
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td no-record-text"
                                    align="center"
                                    colSpan={8}
                                  >
                                    <h3> No records available </h3>
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                      <Index.Box className="pagination-main">
                        <PageIndex.Pagination
                          fetchData={filteredData}
                          setPageData={setPageData}
                          pageData={pageData}
                          search={search}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
}

export default Transaction;
