import { createSlice } from "@reduxjs/toolkit";

import {
  loginAdmin,
  loginAdvertiser,
  loginPublisher,
  roleLogin,
  updateAdminData,
  updateAdvertiserData,
  updatePublisherData
} from "./AdminService";

export const AdminSlice = createSlice({
  name: "admin",
  initialState: {
    loading: false,
    isAuthenticated: false,
    adminData: {},
    token: "",
    advertiserData: {},
    advertisertoken: "",
    publisherData: {},
    publishertoken: ""
  },
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.adminData = {};
      state.token = "";
    }
  },

  extraReducers: (builder) => {
    builder.addCase(loginAdmin.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.adminData = action?.payload?.data;
      state.token = action?.payload?.data?.token;
    });

    builder.addCase(roleLogin?.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      action?.payload?.data?.role === "advertiser"
        ? (state.advertiserData = action?.payload?.data)
        : (state.publisherData = action?.payload?.data);

      action.payload?.data?.role === "advertiser"
        ? (state.advertisertoken = action?.payload?.data.token)
        : (state.publishertoken = action?.payload?.data.token);
    });

    builder.addCase(updateAdvertiserData.fulfilled, (state, action) => {
      if (action?.payload?.data != undefined) {
        state.advertiserData = action?.payload?.data;
   
      }
    });

    builder.addCase(updateAdminData.fulfilled, (state, action) => {
      state.adminData = action?.payload?.data;
    });
    builder.addCase(updatePublisherData.fulfilled, (state, action) => {
 
      if (action?.payload?.data != undefined) {
        state.publisherData = action?.payload?.data;
     
      }
    });
  }
});
export const { logout } = AdminSlice.actions;
export default AdminSlice.reducer;
