import React, { useState, useEffect, useRef } from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import { registerSchema } from "../../../../validations/Validation";
import {
  listAllCategory,
  registerPublisherUser
} from "../../../../redux/slices/AdminService";
import ButtonMvpLoader from "../../../../components/common/buttonMvpLoader/ButtonMvpLoader";
import { Autocomplete, TextField } from "@mui/material";

function SignUp() {
  const [allCategoryList, setAllCategoryList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = PageIndex?.useDispatch();
  const navigate = PageIndex?.useNavigate();

  const style = {
    bgcolor: "background.paper",
    boxShadow: 24
  };

  const handleClickShowPassword = (field) => {
    switch (field) {
      case "password":
        setShowPassword((show) => !show);
        break;
      case "confirmpassword":
        setShowConfirmPassword((show) => !show);
        break;
      default:
        break;
    }
  };

  let initialValues;

  initialValues = {
    role: "",
    name: "",
    email: "",
    company: "",
    password: "",
    confirmpassword: "",
    publishUrl: "",
    category: []
  };

  const handleSignupSubmit = (values, errors) => {
    setLoading(true);
    let addAdvertiserData = {
      role: values.role,
      name: values?.name,
      email: values?.email,
      company: values?.company,
      password: values?.password,
      confirmpassword: values?.confirmpassword,
      categoryId: values?.category,
      image: PageIndex.Png.flexoLogo
    };
    if (values.role === "publisher") {
      addAdvertiserData.publishUrl = values?.publishUrl;
    }
    handleOpen();
    dispatch(registerPublisherUser(addAdvertiserData)).then((res) => {
      setTimeout(() => {
        setLoading(false);
      }, 6000);

      if (res?.payload?.status == 201 || res?.payload?.status == 200) {
        setLoading(false);
        handleClose();

        navigate("/");
      } else {
        handleClose();
      }
    });
  };

  const myElementRef = useRef(null);

  const scrollToElementTop = (e) => {
e.preventDefault()
    if (myElementRef.current) {
      myElementRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    dispatch(listAllCategory()).then((res) => {
      setAllCategoryList(res?.payload?.data);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    });
  }, []);
  

  return (
    <>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          {/* <pageIndex.AuthBackground/> */}
          <Index.Box className="login-left-bg">
            <img
              src={PageIndex.Png.flexoLogo}
              alt="logo"
              className="signup-logo"
            />
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main" >
              <Index.Formik
                validationSchema={registerSchema}
                onSubmit={handleSignupSubmit}
                initialValues={initialValues}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  touched,
                  setFieldValue
                }) => (
                  <form
                    component="form"
                    spacing={2}
                    noValidate
                    autoComplete="off"
                    onSubmit={(e)=>{handleSubmit(e);
                      scrollToElementTop(e)
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit();
                        scrollToElementTop(e)
                      }
                    }}
                  >
                    <Index.Box className="admin-login-inner">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        Register
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para common-para"
                      >
                        {/* Please enter your credentials to sign in! */}
                      </Index.Typography>
                      <Index.Box
                 ref={myElementRef}
                        className="scroll-content-auth public-form-wraper"
                        id="signup-form"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Role<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="dropdown-box">
                            <Index.Box className="form-control drop-form-control">
                              <PageIndex.Field
                                name="role"
                                as={Index.Select}
                                variant="outlined"
                                fullWidth
                                displayEmpty
                                value={values?.role}
                                id="fullWidth"
                                className="dropdown-select drop-select dropdown-property"
                                onChange={(e) => {
                                  setFieldValue("role", e.target.value);
                                }}
                                onBlur={handleBlur}
                              >
                                <Index.MenuItem
                                  value=""
                                  disabled
                                  className="drop-menuitem"
                                >
                                  Select Role
                                </Index.MenuItem>
                                <Index.MenuItem value="advertiser">
                                  Advertiser
                                </Index.MenuItem>
                                <Index.MenuItem value="publisher">
                                  Publisher
                                </Index.MenuItem>
                              </PageIndex.Field>
                              {errors?.role && touched?.role ? (
                                <Index.Typography className="error-msg">
                                  {errors?.role}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-box">
                          <Index.FormHelperText className="form-lable">
                            Name<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Please enter name"
                              autocomplete="off"
                              name="name"
                              type="text"
                              onBlur={handleBlur}
                              value={values.name}
                              // onChange={(e) => {
                              //   const value = e.target.value;
                              //   const regex = /^[A-Za-z][A-Za-z\s]*$/;

                              //   if (regex.test(value) || value === "") {
                              //     setFieldValue("name", value.slice(0, 20));
                              //   }
                              // }}
                              onChange={(e) => {
                                let value = e.target.value;

                                // Remove any leading spaces
                                value = value.trimStart();

                                // Replace multiple spaces with a single space
                                value = value.replace(/\s\s+/g, " ");

                                const regex = /^[A-Za-z][A-Za-z\s]*$/;

                                if (regex.test(value) || value === "") {
                                  // Limit the length of the name to 25 characters
                                  setFieldValue("name", value.slice(0, 25));
                                }
                              }}
                            />
                            {errors?.name && touched?.name ? (
                              <Index.Typography className="error-msg">
                                {errors?.name}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="input-box">
                          <Index.FormHelperText className="form-lable">
                            Email<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Please enter email"
                              autocomplete="off"
                              name="email"
                              onBlur={handleBlur}
                              value={values?.email}
                              onChange={(e) => {
                                const inputValue = e.target.value.trim();
                                // Trim whitespace
                                if (inputValue.length <= 50) {
                                  // Check if not empty after trimming
                                  setFieldValue(
                                    "email",
                                    inputValue.slice(0, 50)
                                  );
                                }
                              }}
                              onKeyDown={(e) =>
                                e.keyCode === 32 && e.preventDefault()
                              }
                              // onChange={handleChange}
                            />
                            {errors?.email && touched?.email ? (
                              <Index.Typography className="error-msg">
                                {errors?.email}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="input-box">
                          <Index.FormHelperText className="form-lable">
                            Company<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Please enter company name"
                              autocomplete="off"
                              name="company"
                              type="text"
                              onBlur={handleBlur}
                              value={values.company}
                              // onChange={(e) => {
                              //   const value = e.target.value;
                              //   const regex =
                              //     /^(?!\s)[a-zA-Z0-9.,;:|\\\/~!@#$%^&*_-{}\[\]()`"'<>?\s]+$/;

                              //   if (regex.test(value) || value === "") {
                              //     setFieldValue("company", value.slice(0, 30));
                              //   }
                              // }}
                              onChange={(e) => {
                                let value = e.target.value;

                                // Remove any leading spaces
                                value = value.trimStart();

                                // Replace multiple spaces with a single space
                                value = value.replace(/\s\s+/g, " ");

                                const regex =
                                  /^(?!\s)[a-zA-Z0-9.,;:|\\\/~!@#$%^&*_-{}\[\]()`"'<>?\s]+$/;

                                if (regex.test(value) || value === "") {
                                  // Limit the length of the name to 20 characters
                                  setFieldValue("company", value.slice(0, 30));
                                }
                              }}
                            />
                            {errors?.company && touched?.company ? (
                              <Index.Typography className="error-msg">
                                {errors?.company}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>

                        {values?.role === "publisher" && (
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-lable">
                              Publisher Url<span>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Please enter url"
                                autocomplete="off"
                                name="publishUrl"
                                type="text"
                                onBlur={handleBlur}
                                value={values?.publishUrl}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /^\S*$/; // Regex pattern to disallow whitespace

                                  if (regex.test(value) || value === "") {
                                    handleChange(e); // Update state if value is valid or empty
                                  }
                                }}
                              />
                              {errors?.publishUrl && touched?.publishUrl ? (
                                <Index.Typography className="error-msg">
                                  {errors?.publishUrl}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        )}

                        {values?.role === "publisher" && (
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-lable">
                              Category<span>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Autocomplete
                                multiple
                                id="tags-outlined"
                                name="category"
                                options={allCategoryList}
                                onChange={(event, newValue) =>
                                  setFieldValue(
                                    "category",
                                    newValue.map((item) => item?._id)
                                  )
                                }
                                getOptionLabel={(option) =>
                                  option?.Category_Name
                                }
                                filterSelectedOptions
                                onBlur={handleBlur}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={handleChange}
                                    placeholder={
                                      values?.category.length > 0
                                        ? ""
                                        : "Category"
                                    }
                                    value={values?.category}
                                  />
                                )}
                              />
                              {errors?.category && touched?.category ? (
                                <Index.Typography className="error-msg">
                                  {errors?.category}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        )}

                        <Index.Box className="input-box">
                          <Index.FormHelperText className="form-lable">
                            Password<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.OutlinedInput
                              className="form-control-eye from-control-password"
                              autocomplete="off"
                              name="password"
                              placeholder="Please enter password"
                              id="outlined-adornment-password"
                              onBlur={handleBlur}
                              value={values.password}
                              onChange={(e) => {
                                const inputValue = e.target.value.trim(); // Trim whitespace
                                if (inputValue.length >= 0) {
                                  // Check if not empty after trimming
                                  setFieldValue(
                                    "password",
                                    inputValue.slice(0, 25)
                                  );
                                }
                              }}
                              onKeyDown={(e) =>
                                e.keyCode === 32 && e.preventDefault()
                              }
                              helperText={touched.password && errors.password}
                              type={showPassword ? "text" : "password"}
                              endAdornment={
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword("password")
                                    }
                                    onMouseDown={(event) => {
                                      event.preventDefault();
                                    }}
                                    onMouseUp={(event) => {
                                      event.preventDefault();
                                    }}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Index.Visibility />
                                    ) : (
                                      <Index.VisibilityOff />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              }
                            />

                            {errors?.password && touched?.password ? (
                              <Index.Typography className="error-msg">
                                {errors?.password}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="input-box">
                          <Index.FormHelperText className="form-lable">
                            Confirm Password<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.OutlinedInput
                              className="form-control-eye from-control-password"
                              autoComplete="off"
                              name="confirmpassword"
                              placeholder="Please enter confirm password"
                              id="outlined-adornment-password"
                              onBlur={handleBlur}
                              value={values.confirmpassword}
                              onChange={(e) => {
                                const inputValue = e.target.value.trim(); // Trim whitespace
                                if (inputValue.length >= 0) {
                                  // Check if not empty after trimming
                                  setFieldValue(
                                    "confirmpassword",
                                    inputValue.slice(0, 25)
                                  );
                                }
                              }}
                              onKeyDown={(e) =>
                                e.keyCode === 32 && e.preventDefault()
                              }
                              helperText={
                                touched.confirmpassword &&
                                errors.confirmpassword
                              }
                              // error={Boolean(errors.confirmpassword)}
                              type={showConfirmPassword ? "text" : "password"}
                              endAdornment={
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword("confirmpassword")
                                    }
                                    onMouseDown={(event) => {
                                      event.preventDefault();
                                    }}
                                    onMouseUp={(event) => {
                                      event.preventDefault();
                                    }}
                                    edge="end"
                                  >
                                    {showConfirmPassword ? (
                                      <Index.Visibility />
                                    ) : (
                                      <Index.VisibilityOff />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              }
                            />

                            {errors?.confirmpassword &&
                            touched?.confirmpassword ? (
                              <Index.Typography className="error-msg">
                                {errors?.confirmpassword}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          className="btn-primary login-btn"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <ButtonMvpLoader color="white" loading={loading} />
                          ) : (
                            "Submit"
                          )}
                        </Index.Button>
                        <Index.Box className="admin-signup">
                          <Index.Typography
                          // className="text-decoration-none admin-forgot-para common-para"
                          >
                            Already have an account ?{" "}
                            <Index.Link
                              // className="text-decoration-none admin-forgot-para common-para"
                              to={"/"}
                            >
                              Login
                            </Index.Link>
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </Index.Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete"
      >
        <Index.Box sx={style} className="delete-modal-inner-main">
          <Index.Box>
            <Index.Box>
              <h3 className="deleteModel-heading">Please wait ...</h3>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

export default SignUp;
