import React, { useEffect, useState } from "react";
import MvpLoader from "../../../../components/common/mvpLoader/MvpLoader";
import { Skeleton } from "@mui/material";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import moment from "moment/moment";
import "../../../advertiser/pages/advertisement/advertisementlist.css";
import FileExcel from "../../../../components/common/fileExcel/FileExcel";
import { listAllPublisherAdvertisment } from "../../../../redux/slices/AdminService";
// for modal design

export default function PublisherAdvertiserList() {
  const [loading, setLoading] = useState(true);
  const [advertisementList, setAdvertisementList] = useState([]);
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const dispatch = PageIndex?.useDispatch();
  const navigate = PageIndex?.useNavigate();

  useEffect(() => {
    dispatch(listAllPublisherAdvertisment()).then((res) => {

      if (res?.payload?.status === 200) {
        setAdvertisementList(res?.payload?.data?.adRecords);
        setLoading(false);
      }
    });
  }, []);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [excel, setExcel] = useState([]);
  const fileName = "myfile";

  useEffect(() => {
    const customHeadings = advertisementList?.map((item) => ({
      "Advertisement Name": item?.advertismentId?.advertiserId.name,
      "Advertiser Name": item?.advertismentId?.title,
      "Duration": item?.advertismentId?.duration,
      "Date and Time": moment(item?.advertismentId?.createdAt).format(
        "DD/MM/YYYY HH:mm:ss"
      )
    }));

    setExcel(customHeadings);
  }, [advertisementList]);

  useEffect(() => {

    if (!search) {
      setFilteredData(advertisementList);
    } else {


      const filtered =
        advertisementList &&
        advertisementList?.filter((item) => {

          let advertismentName = item?.advertismentId?.advertiserId?.name
            ?.toLowerCase()
            ?.includes(search?.toLowerCase());

          let advertismentTitle = item?.advertismentId?.title
            ?.toLowerCase()
            ?.includes(search?.toLowerCase());

          let advertismentDuatation = item?.advertismentId?.duration?.toString()
            ?.toLowerCase()
            .includes(search?.toLowerCase())

          let date =
            moment(item?.advertismentId?.createdAt)
              ?.format("DD/MM/YYYY HH:mm:ss")
              ?.toString()
              .includes(search)



          return advertismentName || advertismentTitle || advertismentDuatation || date


        });

      if (filtered) {
        setFilteredData(filtered);
      } else {
        setFilteredData([]);
      }

    }

  }, [advertisementList, search]);


  return (
    <>
      {loading ? (
        <Index.Box className="dashboard-content user-list-content">
          <Index.Box className="user-list-flex">
            <Index.Typography
              className="admin-page-title user-list-page-title main-heading"
              component="h2"
              variant="h2"
            >
              Advertisement
            </Index.Typography>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box flex-search-details">
                  <Index.Box className="form-group search-box-bg">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <img
                      src={PageIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                  <Index.Box>
                    <FileExcel apiData={excel} fileName={fileName} />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-bg-main table-main-box">
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box pb-0">
                      <Index.Box className="userlist-table-main page-table-main">
                        <Index.TableContainer component={Index.Paper} className="table-container">
                          <Index.Table aria-label="simple table" className="table">
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell className="table-th">Advertisement Name (Title)</Index.TableCell>
                                <Index.TableCell className="table-th">Advertiser Name</Index.TableCell>
                                <Index.TableCell className="table-th">Duration</Index.TableCell>
                                <Index.TableCell className="table-th">Date And Time</Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {[...Array(5)].map((_, index) => (
                                <Index.TableRow key={index}>
                                  <Index.TableCell width="10%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="9%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="8%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="15%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      ) : (
        <Index.Box className="dashboard-content user-list-content">
          <Index.Box className="user-list-flex">
            <Index.Typography
              className="admin-page-title user-list-page-title main-heading"
              component="h2"
              variant="h2"
            >
              Advertisement
            </Index.Typography>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box flex-search-details">
                  <Index.Box className="form-group search-box-bg">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <img
                      src={PageIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                  <Index.Box>
                    <FileExcel apiData={excel} fileName={fileName} />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-bg-main table-main-box">
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12"
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box pb-0">
                      <Index.Box className="advertiserlist-table-main advertiser-advertiserlist-table page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Advertisement Name (Title)
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Advertiser Name
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Duration
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Date and Time
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>

                            <Index.TableBody className="table-body">
                              {pageData && pageData?.length > 0 ? (
                                pageData?.map((row) => (
                                  <Index.TableRow
                                    // key={row.name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0
                                      }
                                    }}
                                  >

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.advertismentId?.title}
                                      {/* {row.advertismentId?.advertiserId.name} */}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.advertismentId?.advertiserId.name}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.advertismentId?.duration}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {moment(
                                        row?.advertismentId?.createdAt
                                      ).format("DD/MM/YYYY HH:mm:ss")}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <Index.TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0
                                    }
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td no-record-text"
                                    align="center"
                                    colSpan={8}
                                  >
                                    <h3> No records available </h3>
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                      <Index.Box className="pagination-main">
                        <PageIndex.Pagination
                          fetchData={filteredData}
                          setPageData={setPageData}
                          pageData={pageData}
                          search={search}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
}
