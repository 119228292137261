import React, { useEffect, useState, useRef } from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import "./advertisementlist.css";
import {
  createAdvertisementData,
  listOfAllCategory
} from "../../../../redux/slices/AdminService";
import { imageUrl } from "../../../../config/DataService";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { advertisementSchema } from "../../../../validations/Validation";
import ButtonMvpLoader from "../../../../components/common/buttonMvpLoader/ButtonMvpLoader";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TextareaAutosize } from "@mui/material";

export default function ViewAdvertisement() {
  // for open handleChangedropdown
  const [videoData, setVideoData] = useState(null);
  const [videoDataNew, setVideoDataNew] = useState(null);
  const [allCategoryList, setAllCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const params = PageIndex.useLocation();
  const[videoError,setVideoError]=useState("")
  const advertisementSingleData = params?.state;
  const formRef = useRef();

  const categoryListData = () => {
    dispatch(listOfAllCategory()).then((res) => {
      setAllCategoryList(res?.payload?.data);
    });
  };
  useEffect(() => {
    categoryListData();
  }, []);

  
  const initialValues = {

    title: advertisementSingleData?._id ? advertisementSingleData?.title : "",
    // video: "",
    category: advertisementSingleData?._id
      ? advertisementSingleData?.category?._id
      : "",
    duration: advertisementSingleData?._id
      ? advertisementSingleData?.duration
      : "",
    maxSpendLimitPerTransaction: advertisementSingleData?._id
      ? advertisementSingleData?.maxSpendLimitPerTransaction
      : "",
    // maxSpendLimit: advertisementSingleData?._id
    //   ? advertisementSingleData?.maxSpendLimit
    //   : "",
    startDate: advertisementSingleData?._id
      ? advertisementSingleData?.startDate
      : "",
    endDate: advertisementSingleData?._id
      ? advertisementSingleData?.endDate
      : "",
    // maxLimit: advertisementSingleData?._id
    //   ? advertisementSingleData?.maxLimit
    //   : "",
    // maxGasFeesLimit: advertisementSingleData?._id
    //   ? advertisementSingleData?.maxGasFeesLimit
    //   : "",
    video: advertisementSingleData?._id ? advertisementSingleData?.video : "",

    advertisement_type: advertisementSingleData?._id
      ? advertisementSingleData?.addType
      : "video",
    description: advertisementSingleData?._id
      ? advertisementSingleData?.description
      : "",
    hyperLink: advertisementSingleData?._id
      ? advertisementSingleData?.hyperLink
      : "",
    textdetails: advertisementSingleData?._id
      ? advertisementSingleData?.textdetails
      : ""
  };

 

  const videoHandle = (e, advertisementType, setFieldValue, setFieldError) => {
    if (advertisementType == "video") {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      const allowedTypes = ["video/mp4"];
      if (allowedTypes.includes(file.type)) {
        let imageOrVideo;
        imageOrVideo = {
          type: file.type.split("/")[1],
          pre: URL.createObjectURL(file)
        };
        setVideoData(imageOrVideo);
        setVideoDataNew(file);
        setFieldValue("video", file);
      } else {
        setFieldError("video", "Please upload a valid  video (MP4) file.");
      }
    } else if (advertisementType == "image") {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

      if (allowedTypes.includes(file.type)) {
        let imageOrVideo;
        imageOrVideo = {
          type: file.type.split("/")[1],
          pre: URL.createObjectURL(file)
        };
        setVideoData(imageOrVideo);
        setVideoDataNew(file);
        setFieldValue("video", file);
      } else {
        setFieldError(
          "video",
          "Please upload a valid image (JPEG, JPG)  file."
        );
      }
    }
  };

  useEffect(() => {
    if (advertisementSingleData) {
      const newVideoData = {
        type: advertisementSingleData.video.split(".")[1],
        pre: `${imageUrl}/${advertisementSingleData.video}`,
        backend: true
      };
      setVideoData(newVideoData);
      setVideoDataNew(`${advertisementSingleData.video}`);
    }
  }, [advertisementSingleData]);

  return (
    <Index.Box className="dashboard-content add-user-containt">
      <Index.Typography
        className="admin-page-title main-heading"
        component="h2"
        variant="h2"
      >
        View Advertisement
      </Index.Typography>
      <Index.Box className="table-bg-main">
        <PageIndex.Formik
          initialValues={initialValues}
          validationSchema={advertisementSchema}
          // innerRef={(f) => (formRef.current = f)}
          enableReinitialize
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldError,
            setFieldTouched
          
          }) => (
            <PageIndex.Form onSubmit={handleSubmit}>
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Title<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <PageIndex.Field
                            name="title"
                            disabled
                            
                            value={values?.title}
                            as={Index.TextField}
                            placeholder="Please enter title"
                            // label="Carpet Area"
                            variant="outlined"
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              if (inputValue.length <= 25) {
                                // Only update the value if it's not more than 30 characters
                                handleChange(event);
                              }
                            }}
                            onBlur={handleBlur}
                            error={Boolean(errors.title)}
                          />
                          {errors?.title && touched?.title ? (
                            <Index.Typography className="error-msg">
                              {errors?.title}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Category<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="dropdown-box">
                          <Index.Box className="form-control drop-form-control">
                            <PageIndex.Field
                              name="category"
                              as={Index.Select}
                              variant="outlined"
                              fullWidth
                              disabled
                              displayEmpty
                              value={values?.category}
                              id="fullWidth"
                              className="dropdown-select drop-select dropdown-property"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(errors.title)}
                            >
                              <Index.MenuItem
                                value={values?.category}
                                disabled
                                className="drop-menuitem"
                              >
                                Select Category
                              </Index.MenuItem>
                              {allCategoryList?.map((category) => (
                                <Index.MenuItem
                                  value={category._id} // Replace 'category.value' with the actual value for each item
                                  key={category._id} // Add a unique key to each menu item
                                >
                                  {category.Category_Name}{" "}
                                  {/* Replace 'category.label' with the actual label for each item */}
                                </Index.MenuItem>
                              ))}
                            </PageIndex.Field>
                            {errors?.category && touched?.category ? (
                              <Index.Typography className="error-msg">
                                {errors?.category}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Max Spend Limit Per Transaction<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <PageIndex.Field
                            type="text"
                            name="maxSpendLimitPerTransaction"
                            value={values?.maxSpendLimitPerTransaction}
                            as={Index.TextField}
                            placeholder="Please enter max spend limit per transaction"
                            // label="Carpet Area"
                            variant="outlined"
                            fullWidth
                            disabled
                            id="fullWidth"
                            className="form-control"
                            onChange={(e) => {
                              const inputValue = e.target.value.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              setFieldValue(
                                "maxSpendLimitPerTransaction",
                                inputValue.slice(0, 10)
                              );
                            }}
                            onBlur={handleBlur}
                            error={Boolean(errors.maxSpendLimitPerTransaction)}
                          />
                          {errors?.maxSpendLimitPerTransaction &&
                          touched?.maxSpendLimitPerTransaction ? (
                            <Index.Typography className="error-msg">
                              {errors?.maxSpendLimitPerTransaction}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    {/* <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Max Spend Limit<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <PageIndex.Field
                            type="text"
                            name="maxSpendLimit"
                            value={values?.maxSpendLimit}
                            placeholder="Please enter max spend limit"
                            as={Index.TextField}
                            // label="Carpet Area"
                            variant="outlined"
                            fullWidth
                            disabled
                            id="fullWidth"
                            className="form-control"
                            onChange={(e) => {
                              const inputValue = e.target.value.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              setFieldValue(
                                "maxSpendLimit",
                                inputValue.slice(0, 10)
                              );
                            }}
                            onBlur={handleBlur}
                            // error={Boolean(errors.maxSpendLimit)}
                          />
                          {errors?.maxSpendLimit && touched?.maxSpendLimit ? (
                            <Index.Typography className="error-msg">
                              {errors?.maxSpendLimit}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box> */}
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Start Date<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group custom-datepicker">
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            className="date-adapter-main"
                          >
                            <DemoContainer
                              components={["DatePicker", "DatePicker"]}
                              className="date-adapter-container"
                            >
                              <MobileDatePicker
                                className="date-adapter-picker"
                                name="startDate"
                                disabled
                                value={dayjs(values?.startDate)}
                                onChange={(e) => {
                                  setFieldValue("startDate", e);
                                  // setFieldValue("minDate", e);
                                }}
                                format="DD/MM/YYYY"
                                disablePast
                              />
                            </DemoContainer>
                          </LocalizationProvider>

                          {errors?.startDate && touched?.startDate ? (
                            <Index.Typography className="error-msg">
                              {errors?.startDate}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          End Date<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group custom-datepicker">
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            className="date-adapter-main"
                          >
                            <DemoContainer
                              components={["DatePicker", "DatePicker"]}
                              className="date-adapter-container"
                            >
                              <MobileDatePicker
                                className="date-adapter-picker"
                                name="endDate"
                                disabled
                                value={dayjs(values?.endDate)}
                                onChange={(e) => {
                                  setFieldValue("endDate", e);
                                }}
                                format="DD/MM/YYYY"
                                // Set the minDate based on the selected start date
                                minDate={dayjs(values?.startDate) || undefined}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          {errors?.endDate && touched?.endDate ? (
                            <Index.Typography className="error-msg">
                              {errors?.endDate}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    {/* <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Max Limit<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <PageIndex.Field
                            type="text"
                            name="maxLimit"
                            value={values?.maxLimit}
                            placeholder="Please enter max limit"
                            as={Index.TextField}
                            // label="Carpet Area"
                            variant="outlined"
                            fullWidth
                            disabled
                            id="fullWidth"
                            className="form-control"
                            onChange={(e) => {
                              const inputValue = e.target.value.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              setFieldValue(
                                "maxLimit",
                                inputValue.slice(0, 10)
                              );
                            }}
                            onBlur={handleBlur}
                            // error={Boolean(errors.maxLimit)}
                          />
                          {errors?.maxLimit && touched?.maxLimit ? (
                            <Index.Typography className="error-msg">
                              {errors?.maxLimit}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box> */}
                    {/* <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Max Gas Fees Limit<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <PageIndex.Field
                            type="text"
                            name="maxGasFeesLimit"
                            value={values?.maxGasFeesLimit}
                            placeholder="Please enter max gas fees limit"
                            as={Index.TextField}
                            // label="Carpet Area"
                            variant="outlined"
                            fullWidth
                            disabled
                            id="fullWidth"
                            className="form-control"
                            onChange={(e) => {
                              const inputValue = e.target.value.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              setFieldValue(
                                "maxGasFeesLimit",
                                inputValue.slice(0, 10)
                              );
                            }}
                            onBlur={handleBlur}
                            // error={Boolean(errors.maxGasFeesLimit)}
                          />
                          {errors?.maxGasFeesLimit &&
                          touched?.maxGasFeesLimit ? (
                            <Index.Typography className="error-msg">
                              {errors?.maxGasFeesLimit}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box> */}
                    
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Select Advertisement Type<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="dropdown-box">
                          <Index.Box className="form-control drop-form-control">
                            <PageIndex.Field
                              name="advertisement_type"
                              value={values?.advertisement_type}
                              as={Index.Select}
                              variant="outlined"
                              fullWidth
                              displayEmpty
                              disabled
                              id="fullWidth"
                              className="dropdown-select drop-select dropdown-property"
                              onChange={(e) => {
                                setFieldValue("advertisement_type" , e.target.value);
                                setVideoData(null);
                                setFieldValue("video", null);
                                // setFieldError("video",null);
                                setFieldTouched("video",false);
                                // if(e.target.value == "image"){

                                //   setVideoError("please select image")
                                // }else if(e.target.value == "video"){
                                //   setVideoError("please select video")

                                // }
                              
                              }}
                              onBlur={handleBlur}
                              error={Boolean(errors.title)}
                            >
                              <Index.MenuItem
                                value=""
                                disabled
                                className="drop-menuitem"
                              >
                                Select Advertisement Type
                              </Index.MenuItem>
                              <Index.MenuItem
                                value="text"
                                className="drop-menuitem"
                              >
                                Text
                              </Index.MenuItem>
                              <Index.MenuItem
                                value="video"
                                className="drop-menuitem"
                              >
                                Video
                              </Index.MenuItem>
                              <Index.MenuItem
                                value="image"
                                className="drop-menuitem"
                              >
                                Image
                              </Index.MenuItem>
                            </PageIndex.Field>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    {(values.advertisement_type == "image" ||
                      values.advertisement_type == "text") && (
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6"
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Duration<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              type="text"
                              name="duration"
                              value={values?.duration}
                              as={Index.TextField}
                              disabled
                              placeholder="Please enter duration"
                              // label="Carpet Area"
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              onChange={(e) => {
                                const inputValue = e.target.value.replace(
                                  /[^0-9.]/g,
                                  ""
                                );
                                setFieldValue(
                                  "duration",
                                  inputValue.slice(0, 2)
                                );
                              }}
                              onBlur={handleBlur}
                              error={Boolean(errors.duration)}
                            />
                            {errors?.duration && touched?.duration ? (
                              <Index.Typography className="error-msg">
                                {errors?.duration}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    )}

                    {/* {values.advertisement_type == "image" &&
                      <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                        Image  <span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="edit-profile-flex">
                        <Index.Box className="file-upload-btn-main">
                          <img
                            src={
                              values.file
                                ? URL.createObjectURL(values.file)
                                // : adminData?.image === "false"
                                // ? 
                               
                               : Index.Png.usericon
                                // : `${imageUrl}/${Index.Png.usericon}`
                            }
                            onChange={(e) => e.target.files[0]}
                            className="upload-profile-img"
                            alt="upload img"
                          ></img>
                          <Index.Button
                            variant="contained"
                            component="label"
                            className="file-upload-btn"
                          >
                            <img
                              // src={Index.Svg.edit}
                              className="upload-icon-img"
                              alt="upload img"
                            ></img>
                            <input
                              hidden
                              accept="image/*"
                              name="image"
                              type="file"
                              onChange={(e) => {
                                try {
                                 setFieldValue(
                                    "file",
                                    e.target.files[0]
                                  );
                                } catch (error) {
                                  console.error(error);
                                  e.target.value = null;
                                }
                              }}
                            />
                          </Index.Button>
                        </Index.Box>
                        {errors?.file && touched?.file ? (
                              <Index.Typography className="error-msg">
                                {errors?.file}
                              </Index.Typography>
                            ) : null}
                      </Index.Box>
                      </Index.Box>
                    </Index.Box>} */}

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          HyperLink <span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <PageIndex.Field
                            name="hyperLink"
                            value={values?.hyperLink}
                            as={Index.TextField}
                            placeholder="Please enter hyperLink"
                            // label="Carpet Area"
                            variant="outlined"
                            fullWidth
                            disabled
                            id="fullWidth"
                            className="form-control"
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              if (inputValue.length <= 120) {
                                // Only update the value if it's not more than 30 characters
                                handleChange(event);
                              }
                            }}
                            onBlur={handleBlur}
                            error={Boolean(errors.title)}
                          />
                          {errors?.hyperLink && touched?.hyperLink ? (
                            <Index.Typography className="error-msg">
                              {errors?.hyperLink}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    {(values.advertisement_type == "video" ||
                      values.advertisement_type == "image") && (
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12"
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Description <span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              name="description"
                              value={values?.description}
                              as={TextareaAutosize}
                              placeholder="Please enter description"
                              // label="Carpet Area"
                              variant="outlined"
                              disabled
                              fullWidth
                              id="fullWidth"
                              className="form-control textarea-filed"
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 120) {
                                  // Only update the value if it's not more than 30 characters
                                  handleChange(event);
                                }
                              }}
                              onBlur={handleBlur}
                            />
                            {errors?.description && touched?.description ? (
                              <Index.Typography className="error-msg">
                                {errors?.description}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    )}

                    {values.advertisement_type === "text" && (
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12"
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Text Details <span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              name="textdetails"
                              value={values?.textdetails}
                              as={TextareaAutosize}
                              placeholder="Please enter textdetails"
                              // label="Carpet Area"
                              variant="outlined"
                              disabled
                              fullWidth
                              id="fullWidth"
                              className="form-control textarea-filed"
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 500) {
                                  // Only update the value if it's not more than 30 characters
                                  handleChange(event);
                                }
                              }}
                              onBlur={handleBlur}
                            />
                            {errors?.textdetails && touched?.textdetails ? (
                              <Index.Typography className="error-msg">
                                {errors?.textdetails}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    )}

                    {(values.advertisement_type === "video" ||
                      values.advertisement_type === "image") && (
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 6",
                          lg: "span 6"
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input add-game-row">
                          <Index.FormHelperText className="form-lable add-game-lables">
                            Advertisement{" "}
                            {values.advertisement_type === "video"
                              ? "Video"
                              : "Image"}
                            <span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="grid-row">
                            <Index.Box className="grid-main">
                              <Index.Box className="display-row-add-user position-relative">
                              
                                {videoData ? (
                                  <Index.Box>
                               
                                    <Index.Box className="document-close-set"></Index.Box>
                                    <Index.Box
                                      gridColumn={{
                                        xs: "span 12",
                                        sm: "span 3",
                                        md: "span 3",
                                        lg: "span 3"
                                      }}
                                    >
                                      <Index.Box>
                                        {videoData.type === "mp4" ? (
                                          <video
                                            className="upload-img-slider"
                                            controls
                                          >
                                            <source
                                              src={videoData?.pre}
                                              // src={`${imageUrl}/${values.video}`}
                                              type="video/mp4"
                                            />
                                          </video>
                                        ) : (
                                          <img
                                            src={videoData?.pre}
                                            className="upload-img-slider"
                                          />
                                        )}
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                ) : (
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 3",
                                      md: "span 3",
                                      lg: "span 6"
                                    }}
                                  >
                                    <Index.Box className="images-upload-btn">
                                      <Index.Button
                                        variant="contained"
                                        component="label"
                                        className="save-user-btn border-btn image-upload-btn"
                                      >
                                        <img
                                          alt="img"
                                          src={Index.Svg.plus}
                                          className="upload-img"
                                        />

                                        <input
                                          hidden
                                          accept="video/mp4"
                                          name="video"
                                          disabled
                                          key={values.advertisement_type}
                                          type="file"
                                          // onClick={(e) => {
                                          //   e.target.value = null;
                                          // }}
                                          // onBlur={handleBlur}
                                          onChange={(event) => {
                                            const file = event.target.files[0];
                                            const video =
                                              document.createElement("video");
                                            const videoURL =
                                              URL.createObjectURL(file);

                                            video.preload = "metadata";
                                            video.src = videoURL;

                                            video.onloadedmetadata = () => {
                                              if (
                                                video.duration < 15 ||
                                                video.duration > 20
                                              ) {
                                                // formRef.current?.setErrors({
                                                //   ...errors,
                                                //   video: "Video duration should be between 15 and 20 seconds."
                                                // });
                                                if (
                                                  values?.advertisement_type ==
                                                  "video"
                                                ) {
                                                  setFieldError(
                                                    "video",
                                                    "Video duration should be between 15 or 20 seconds."
                                                  );
                                                } else {
                                                  setFieldError(
                                                    "video",
                                                    "Please upload a valid image (JPEG, JPG)  file."
                                                  );
                                                }

                                                // setFieldTouched("video")
                                              } else {
                                                videoHandle(
                                                  event,
                                                  values.advertisement_type,
                                                  setFieldValue,
                                                  setFieldError
                                                );

                                                // Here you can proceed with uploading the video or any other action
                                              }
                                            };
                                            // setTouched({...touched,[video]: true })
                                            video.onerror = () => {
                                              videoHandle(
                                                event,
                                                values.advertisement_type,
                                                setFieldValue,
                                                setFieldError
                                              );
                                            };
                                          }}

                                        />
                                      </Index.Button>
                                    </Index.Box>
                                  </Index.Box>
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          {errors?.video && touched?.video ? (
                            <Index.Typography className="error-msg">
                              {errors?.video}
                            </Index.Typography>):null}

                          {/* {errors?.video && touched?.video ? (
                            <Index.Typography className="error-msg">
                              {errors?.video}
                            </Index.Typography>
                          ) : errors?.video == "Please select video" ||
                            (errors?.video == "Please select image" &&
                              touched?.video === undefined) ? (
                            ""
                          ) : (
                            <Index.Typography className="error-msg">
                              {errors?.video}
                            </Index.Typography>
                          )} */}
                        </Index.Box>
                      </Index.Box>
                    )}

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="user-btn-flex">
                        <Index.Box className="discard-btn-main border-btn-main">
                          <Index.Button
                            className="discard-user-btn border-btn"
                            onClick={() =>
                              navigate("/advertiser/advertisment-list")
                            }
                          >
                            Back
                          </Index.Button>
                        </Index.Box>
                     
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </PageIndex.Form>
          )}
        </PageIndex.Formik>
      </Index.Box>
    </Index.Box>
  );
}
