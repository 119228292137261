import React from 'react'
import Index from '../../Index'

export default function UserSidebar() {
    return (
        <>
            <Index.Box className="user-sidebar-inner">
                <Index.List className="user-sidebar-list">
                    <Index.ListItem className="user-sidebar-listitem">
                        <Index.Link className="user-sidebar-link" to="/">Vesting dashboard</Index.Link>
                    </Index.ListItem>
                    {/* <Index.ListItem className="user-sidebar-listitem active">
                        <Index.Link className="user-sidebar-link" to="/">Dashboard</Index.Link>
                    </Index.ListItem>
                    <Index.ListItem className="user-sidebar-listitem">
                        <Index.Link className="user-sidebar-link" to="/">Private Sale</Index.Link>
                    </Index.ListItem>
                    <Index.ListItem className="user-sidebar-listitem">
                        <Index.Link className="user-sidebar-link" to="/">Purchase History</Index.Link>
                    </Index.ListItem>
                    <Index.ListItem className="user-sidebar-listitem">
                        <Index.Link className="user-sidebar-link" to="/">Referrals</Index.Link>
                    </Index.ListItem>
                    <Index.ListItem className="user-sidebar-listitem">
                        <Index.Link className="user-sidebar-link" to="/">Account</Index.Link>
                    </Index.ListItem>
                    <Index.ListItem className="user-sidebar-listitem">
                        <Index.Link className="user-sidebar-link" to="/">Contact Support</Index.Link>
                    </Index.ListItem>
                    <Index.ListItem className="user-sidebar-listitem">
                        <Index.Link className="user-sidebar-link" to="/">User Agreement</Index.Link>
                    </Index.ListItem>
                    <Index.ListItem className="user-sidebar-listitem">
                        <Index.Link className="user-sidebar-link" to="/">AML & KYC Policy</Index.Link>
                    </Index.ListItem>
                    <Index.ListItem className="user-sidebar-listitem">
                        <Index.Link className="user-sidebar-link" to="/">Privacy Policy</Index.Link>
                    </Index.ListItem>
                    <Index.ListItem className="user-sidebar-listitem">
                        <Index.Link className="user-sidebar-link" to="/">API Documentation</Index.Link>
                    </Index.ListItem>
                    <Index.ListItem className="user-sidebar-listitem">
                        <Index.Link className="user-sidebar-link" to="/">Privacy Policy</Index.Link>
                    </Index.ListItem>
                    <Index.ListItem className="user-sidebar-listitem">
                        <Index.Link className="user-sidebar-link" to="/">Cookie Policy</Index.Link>
                    </Index.ListItem> */}
                </Index.List>
            </Index.Box>
        </>
    )
}
