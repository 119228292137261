import React from "react";
import Index from "../../../container/Index";
const   RejectModal = ({
  deleteOpen,
  handleDeleteClose,
  reason,
  setReason,
  handleReject,
  row
}) => {
  return (
    <Index.Modal open={deleteOpen} onClose={handleDeleteClose}>
      <Index.Box
        sx={{
          position: "absolute",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Index.TextField
          label="Enter Reject reason"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={reason}
          onChange={(e) => setReason(e.target.value.slice(0, 200))}
        />
        <Index.Button
          onClick={() => handleReject(row?._id, "Rejected")}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Reject
        </Index.Button>
        <Index.Button
          onClick={handleDeleteClose}
          variant="contained"
          color="primary"
          sx={{ mt: 2, ml: 2 }}
        >
          Close
        </Index.Button>
      </Index.Box>
    </Index.Modal>
  );
};

export default RejectModal;
