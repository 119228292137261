import React from "react";
// import HashLoader from "react-spinners/HashLoader";
import PulseLoader from "react-spinners/PulseLoader";
const ButtonMvpLoader = ({ color, loading, ariaLabel, dataTestId }) => {
  return (
    <>
      <PulseLoader
        color={color}
        loading={loading}
        size={12}
        aria-label={ariaLabel}
        data-testid={dataTestId}
        // style={{ transform: "none", margin: "auto" }}
      />
    </>
  );
};

export default ButtonMvpLoader;
