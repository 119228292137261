import usericon from "./images/png/user.png";
import logo from "./images/png/logo.png";
import edit from "./images/png/edit.png";
import userlist1 from "./images/png/userlist1.png";
import bergurmenu from "./images/png/bergur-menu.png";
import close from "./images/png/close.png";
import user1 from "./images/png/user-1.png";
import userimg from "./images/png/user-dashboard.png";
import flexoLogo from "./images/png/flexo_logo.png";
import favIconLogo from "./images/png/image_logo.png";
import metaLogo from "./images/png/metaLogo.png";
import flexo from "./images/png/flexo.png";
import user2 from "./images/png/user2.png";
import dashboardBackground from "./images/png/Dashboardbackground.png"
import back from "./images/png/back.png"
import userbg from "./images/png/userbg.png"


const Png = {
  dashboardBackground,
  favIconLogo,
  back,
  userimg,
  usericon,
  logo,
  edit,
  userlist1,
  bergurmenu,
  close,
  user1,
  flexoLogo,
  metaLogo,
  flexo,
  user2,
  userbg,
};

export default Png;
