import { styled, alpha } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import Index from "../../Index";
import PageIndex from "../../PageIndex";
import { imageUrl } from "../../../config/DataService";
// import { Box, Tab, Tabs, Typography } from '@mui/material'
import PropTypes from "prop-types";
import { logout } from "../../../redux/slices/AdminSlice";

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Index.Box sx={{ p: 3 }}>
//           <Index.Typography>{children}</Index.Typography>
//         </Index.Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
};
// const qrstyle = {
//   position: 'absolute',
//   top: '50%',
//   right: '50%',
//   bgcolor: 'background.paper',
//   boxShadow: 24,
// };

export default function Header(props) {
  // for open in header profile menu
  const [isMenuOpen, setIsMenuOpen] = useState(props.open);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = PageIndex?.useNavigate();
  const dispatch = PageIndex?.useDispatch()
  const open = Boolean(anchorEl);
  const { adminData } = PageIndex.useSelector((state) => state.admin);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutAdmin = () => {
    dispatch(logout())
    handleClose();
    navigate("/admin/login");
  };
  const handleOpenAccountSetting = () => {
    handleClose();
    navigate("/admin/account-setting");
  };
  const handleButtonClick = () => {
    setIsMenuOpen(!isMenuOpen);
    props.setOpen(!props.open);
    document.body.classList[props.open ? "add" : "remove"]("body-overflow");
  };
  const StyledMenu = styled((props) => (
    <Index.Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 3,
      marginTop: theme.spacing(1),
      minWidth: 258,
      paddingTop: 30,
      paddingBottom: 0,
      paddingLeft: 23,
      marginTop: 38,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow: "0px 3px 6px #00000029",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  // metaMask modal
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  // qr modal
  // const [openQr, setOpenQr] = React.useState(false);
  // const handleOpenQr = () => setOpenQr(true);
  // const handleCloseQr = () => setOpenQr(false);

  // const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const opendots = Boolean(anchorEl);
  const handleClickdots = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosedots = () => {
    setAnchorEl(null);
  };

  const [age, setAge] = React.useState("");

  const handleChangedropdown = (event) => {
    setAge(event.target.value);
  };

  // for custom switch design

  // const IOSSwitch = styled((props) => (
  //   <Index.Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  // ))(({ theme }) => ({
  //   width: 34,
  //   height: 20,
  //   padding: 0,
  //   '& .MuiSwitch-switchBase': {
  //     padding: 0,
  //     margin: 3,
  //     transitionDuration: '300ms',
  //     '&.Mui-checked': {
  //       transform: 'translateX(14px)',
  //       color: '#112A35',
  //       '& + .MuiSwitch-track': {
  //         backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#00000000',
  //         opacity: 1,
  //         border: '1px solid #112A35',
  //       },
  //       '&.Mui-disabled + .MuiSwitch-track': {
  //         opacity: 0.5,
  //       },
  //     },
  //     '&.Mui-focusVisible .MuiSwitch-thumb': {
  //       color: '#33cf4d',
  //       border: '6px solid #112A35',
  //     },
  //     '&.Mui-disabled .MuiSwitch-thumb': {
  //       color:
  //         theme.palette.mode === 'light'
  //           ? theme.palette.grey[100]
  //           : theme.palette.grey[600],
  //     },
  //     '&.Mui-disabled + .MuiSwitch-track': {
  //       opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
  //     },
  //   },
  //   '& .MuiSwitch-thumb': {
  //     boxSizing: 'border-box',
  //     width: 14,
  //     height: 14,
  //     boxShadow: "none",
  //   },
  //   '& .MuiSwitch-track': {
  //     borderRadius: 26 / 2,
  //     backgroundColor: theme.palette.mode === 'light' ? '#A6A8AA' : '#39393D',
  //     opacity: 1,
  //     transition: theme.transitions.create(['background-color'], {
  //       duration: 500,
  //     }),
  //   },
  // }));
  // for custom switch design End

  return (
    <>
      <Index.Box
        className={`admin-header-main ${props.open ? "active" : "admin-header-deactive"
          }`}
      >
        <Index.Box className="admin-header-left">
          <Index.Box className="admin-header-logo-main">
            <img
              src={PageIndex.Png.flexoLogo}
              className="admin-sidebar-logo"
              alt="logo"
            />
            <Index.Button className="admin-bergur-button" onClick={handleButtonClick}>
              <img
                src={isMenuOpen ? PageIndex.Svg.bergurmenu : PageIndex.Png.back} // Adjust the icon based on the state
                className="bergurmenu-icon"
                alt="dashboard logo"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-header-right">
            {/* <Index.Box className="meta-mask-btn-sec">
              <Index.Button
                className="meta-mask-btn"
                onClick={handleOpenDelete}
              >
                Connect
              </Index.Button>
            </Index.Box> */}
            {/* <Index.Button className="admin-header-icon-box">
              <img
                src={PageIndex.Svg.notification}
                className="admin-header-icon"
                alt="dashboard logo"
              ></img>
            </Index.Button>
            <Index.Button className="admin-header-icon-box">
              <img
                src={PageIndex.Svg.settings}
                className="admin-header-icon"
                alt="dashboard bell icon"
              ></img>
            </Index.Button> */}
            <Index.Box className="admin-header-drop-main">
              <Index.Button
                className="drop-header-btn"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Index.Box className="flex-drop-main">
                  <img
                    src={
                      adminData?.image === "false"
                        ? Index.Png.usericon
                        : `${imageUrl}/${adminData?.image}`
                    }
                    className="admin-header-profile-icon"
                    alt="dashboard bell icon"
                  ></img>
                  <Index.Box className="title-admin-drop">
                    <Index.Typography
                      variant="h5"
                      component="h5"
                      className="admin-header-drop"
                    >
                      {adminData?.name}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Button>
            </Index.Box>
            <Index.Menu
              className="drop-header-menu"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {/* <Index.MenuItem
                onClick={handleOpenProfile}
                className="drop-header-menuitem"
              >
                {" "}
                <img
                  src={PageIndex.Svg.profilegrey}
                  className="drop-header"
                />{" "}
                Profile
              </Index.MenuItem> */}
              <Index.MenuItem
                onClick={handleOpenAccountSetting}
                className="drop-header-menuitem"
              >
                {" "}
                <img
                  src={PageIndex.Svg.settings}
                  className="drop-header"
                />{" "}
                Account Settings
              </Index.MenuItem>
              <Index.MenuItem
                onClick={handleLogoutAdmin}
                // onClick={() => {
                //   localStorage.clear()
                //   window.location.reload();
                // }}
                className="drop-header-menuitem"
              >
                {" "}
                <img src={PageIndex.Svg.edit1} className="drop-header" /> Sign
                Out
              </Index.MenuItem>
            </Index.Menu>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="wallet-modal-details">
        <Index.Modal
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="meta-mask-main modal"
        >
          <Index.Box className="right-main-position-modal">
            <Index.Box
              sx={style}
              className="metamask-modal-inner-main modal-inner"
            >
              <Index.WalletHeader />
              <Index.Box className="metamask-modal-body">
                <Index.WalletTokens />
                <Index.WalletSwap />
                <Index.WalletSetting />
                <Index.WalletNetwork />
                <Index.WalletContact />
                <Index.WalletNewContact />
                <Index.WalletAlerts />
                <Index.WalletNewTransaction />
                <Index.WalletTransactionInfo />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Modal>
      </Index.Box>

      {/* <Index.Modal
        open={openQr}
        onClose={handleCloseQr}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='meta-mask-main modal'
      >
        <Index.Box sx={qrstyle} className="metamask-modal-inner-main modal-inner">
          dsgpioojg
        
        </Index.Box>
      </Index.Modal> */}
    </>
  );
}
