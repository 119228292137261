import React, { useState, useEffect } from "react";
import { Skeleton } from "@mui/material";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import MvpLoader from "../../../../components/common/mvpLoader/MvpLoader";
import { listOfAllUser } from "../../../../redux/slices/AdminService";
import FileExcel from "../../../../components/common/fileExcel/FileExcel";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24
};

function createData(name, address, city, number, stutus, action) {
  return { name, address, city, number, stutus, action };
}

export default function UserManagementList() {
  const [age, setAge] = useState("");
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allUserList, setAllUserList] = useState();
  const [excel, setExcel] = useState([]);
  const fileName = "myfile";

  const handleChangedropdown = (event) => {
    setAge(event.target.value);
  };

  // add user modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // delete modal
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  // filter
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Index.Box
      className="filter-main"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    ></Index.Box>
  );
  const dispatch = PageIndex?.useDispatch();

  useEffect(() => {
    dispatch(listOfAllUser()).then((res) => {
      setAllUserList(res?.payload?.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const customHeadings = allUserList?.map((item) => ({
      "Name": item?.name,
      "Email": item?.email,
      "Wallet Address": item?.publickey
    }));

    setExcel(customHeadings);
  }, [allUserList]);

  useEffect(() => {
    const filtered = allUserList?.filter((item) => {
      return !search
        ? item
        : item?.name?.toLowerCase().includes(search?.toLowerCase()) ||
        item?.email?.toLowerCase().includes(search?.toLowerCase()) ||
        item?.publickey?.toLowerCase().includes(search?.toLowerCase());
    });

    setFilteredData(filtered || []);
  }, [allUserList, search]);

  return (
    <>
      {loading ? (
        <Index.Box className="dashboard-content user-list-content">
          <Index.Box className="user-list-flex">
            <Index.Typography className="admin-page-title user-list-page-title main-heading" component="h2" variant="h2">
              User Management
            </Index.Typography>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box flex-search-details">
                  <Index.Box className="form-group search-box-bg">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search user"
                      onChange={(e) => setSearch(e.target.value)}
                      autoComplete="off"
                    />
                    <img
                      src={PageIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                  <Index.Box>
                    <FileExcel apiData={excel} fileName={fileName} />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-bg-main table-main-box">
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box pb-0">
                      <Index.Box className="userlist-table-main page-table-main">
                        <Index.TableContainer component={Index.Paper} className="table-container">
                          <Index.Table aria-label="simple table" className="table">
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell className="table-th">Profile</Index.TableCell>
                                <Index.TableCell className="table-th">Name</Index.TableCell>
                                <Index.TableCell className="table-th">Wallet Address</Index.TableCell>
                                <Index.TableCell className="table-th">Email</Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {[...Array(5)].map((_, index) => (
                                <Index.TableRow key={index}>
                                  <Index.TableCell>
                                    <Skeleton variant="circular" width={40} height={40} />
                                  </Index.TableCell>
                                  <Index.TableCell>
                                    <Skeleton variant="text" width="80%" />
                                  </Index.TableCell>
                                  <Index.TableCell>
                                    <Skeleton variant="text" width="60%" />
                                  </Index.TableCell>
                                  <Index.TableCell>
                                    <Skeleton variant="text" width="80%" />
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      ) : (
        <Index.Box className="dashboard-content user-list-content">
          <Index.Box className="user-list-flex">
            <Index.Typography className="admin-page-title user-list-page-title main-heading" component="h2" variant="h2">
              User Management
            </Index.Typography>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box flex-search-details">
                  <Index.Box className="form-group search-box-bg">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search user"
                      onChange={(e) => setSearch(e.target.value)}
                      autoComplete="off"
                    />
                    <img
                      src={PageIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                  <Index.Box>
                    <FileExcel apiData={excel} fileName={fileName} />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-bg-main table-main-box">
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box pb-0">
                      <Index.Box className="userlist-table-main page-table-main">
                        <Index.TableContainer component={Index.Paper} className="table-container">
                          <Index.Table aria-label="simple table" className="table">
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell className="table-th">Profile</Index.TableCell>
                                <Index.TableCell className="table-th">Name</Index.TableCell>
                                <Index.TableCell className="table-th">Wallet Address</Index.TableCell>
                                <Index.TableCell className="table-th">Email</Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {pageData && pageData.length > 0 ? (
                                pageData.map((row) => (
                                  <Index.TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <Index.TableCell className="table-td">
                                      <img className="category-images" src={`${row?.image? row?.image:PageIndex.Png.userbg}`} alt="user" />
                                    </Index.TableCell>
                                    <Index.TableCell className="table-td">{row.name}</Index.TableCell>
                                    <Index.TableCell className="table-td">{row.publickey}</Index.TableCell>
                                    <Index.TableCell className="table-td">{row.email}</Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <Index.TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                  <Index.TableCell className="table-td no-record-text" align="center" colSpan={8}>
                                    <h3>No records available</h3>
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                      <Index.Box className="pagination-main">
                        <PageIndex.Pagination
                          fetchData={filteredData}
                          setPageData={setPageData}
                          pageData={pageData}
                          search={search}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
}
