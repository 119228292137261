import React from 'react'
import Index from '../Index'
import PageIndex from '../PageIndex'
import PropTypes from 'prop-types';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Index.Box sx={{ p: 3 }}>
            <Index.Typography>{children}</Index.Typography>
          </Index.Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

function WalletTokens() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

  return (
    <>
      <Index.Box className='copy-text-sec-main'>
                  <Index.Box className='copy-text-sec'>
                    <Index.Typography className='meta-drop-title' component="p" variant='p'>0x38D...85B3</Index.Typography>
                    <img src={PageIndex.Svg.copy} alt="copy" className="copy-img" />
                  </Index.Box>
                  <Index.Box className='eth-text-sec'>
                    <Index.Typography className='eth-text' component="p" variant='p'>100 ETH</Index.Typography>
                    <Index.Typography className='usd-text' component="p" variant='p'>$0.00 USD</Index.Typography>
                  </Index.Box>
                  <Index.Box className='buy-swap-sec'>
                    <Index.Box className='buy-swap-btn'>
                      <Index.Button className="buy-btn">
                        <img src={PageIndex.Svg.add} alt="add" className="three-dot-img" />
                      </Index.Button>
                      <Index.Typography component="p" variant="p" className="buy-text">Buy</Index.Typography>
                    </Index.Box>
                    <Index.Box className='buy-swap-btn swap-btn-sec'>
                      <Index.Button className="buy-btn">
                        <img src={PageIndex.Svg.swap} alt="swap" className="three-dot-img" />

                      </Index.Button>
                      <Index.Typography component="p" variant="p" className="buy-text">Swap</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className='token-tab-sec'>
                  <Index.Box className="tabs-main-box">
                    <Index.Box sx={{ width: '100%' }}>
                      <Index.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Index.Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='admin-tabs-main'>
                          <Index.Tab label="Tokens" {...a11yProps(0)} className="admin-tab" />
                          <Index.Tab label="Activity" {...a11yProps(1)} className="admin-tab" />
                        </Index.Tabs>
                      </Index.Box>
                      <TabPanel value={value} index={0} className="admin-tabpanel">
                        <Index.Box className="tabpanel-main">
                          <Index.Box className='flexo-eth-usd'>
                            <Index.Box className='flexo-eth-main'>
                              <Index.Box className='flexo-img-sec'>
                                <img
                                  src={PageIndex.Png.flexo}
                                  className="flexo-ic"
                                  alt="logo"
                                />
                              </Index.Box>
                              <Index.Box className='flexo-content-sec'>
                                <Index.Typography component="p" variant="p" className="flexo-text">Flexo</Index.Typography>
                                <Index.Typography component="p" variant="p" className="flexo-text eth-text">0 ETH</Index.Typography>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className='flexo-usd-main'>
                              <Index.Typography component="p" variant="p" className="flexo-text">$0.00 USD</Index.Typography>

                            </Index.Box>
                          </Index.Box>
                          <Index.Box className='flexo-eth-usd'>
                            <Index.Box className='flexo-eth-main'>
                              <Index.Box className='flexo-img-sec'>
                                <img
                                  src={PageIndex.Png.flexo}
                                  className="flexo-ic"
                                  alt="logo"
                                />
                              </Index.Box>
                              <Index.Box className='flexo-content-sec'>
                                <Index.Typography component="p" variant="p" className="flexo-text">Flexo</Index.Typography>
                                <Index.Typography component="p" variant="p" className="flexo-text eth-text">0 ETH</Index.Typography>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className='flexo-usd-main'>
                              <Index.Typography component="p" variant="p" className="flexo-text">$0.00 USD</Index.Typography>

                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </TabPanel>
                      <TabPanel value={value} index={1} className="admin-tabpanel">
                        <Index.Box className="tabpanel-main">
                          <Index.Box className="activity-tab-main">
                            <Index.Typography component="p" variant="p" className="no-trans-text">You have no transactions</Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </TabPanel>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
    </>
  )
}

export default WalletTokens
