import {
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Menu,
  Collapse,
  Stack,
  Pagination,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { toast } from "react-toastify";
import SettingsIcon from "@mui/icons-material/Settings";
import PrimaryButton from "./common/button/PrimaryButton";
import WalletHeader from "./walletcomponent/WalletHeader";
import WalletFooter from "./walletcomponent/WalletFooter";
import WalletTokens from "./walletcomponent/WalletTokens";
import WalletSwap from "./walletcomponent/WalletSwap";
import WalletSetting from "./walletcomponent/WalletSetting";
import WalletNetwork from "./walletcomponent/WalletNetwork";
import WalletContact from "./walletcomponent/WalletContact";
import WalletNewContact from "./walletcomponent/WalletNewContact";
import WalletAlerts from "./walletcomponent/WalletAlerts";
import WalletNewTransaction from "./walletcomponent/WalletNewTransaction";
import WalletTransactionInfo from "./walletcomponent/WalletTransactionInfo";
import { Form, Formik } from "formik";

const Index = {
  Form,
  NotificationsIcon,
  SettingsIcon,
  Box,
  FormHelperText,
  Link,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  VisibilityOff,
  Visibility,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Menu,
  ExpandLess,
  ExpandMore,
  Collapse,
  Stack,
  Pagination,
  toast,
  PrimaryButton,
  WalletHeader,
  WalletFooter,
  WalletTokens,
  WalletSwap,
  WalletSetting,
  WalletNetwork,
  WalletContact,
  WalletNewContact,
  WalletAlerts,
  WalletNewTransaction,
  WalletTransactionInfo,
  Formik,
};
export default Index;
