import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Routess,
} from "react-router-dom";

//#region user
import Login from "../container/admin/auth/login/Login";
import Otp from "../container/admin/auth/otp/Otp";
import LayOut from "../container/admin/pages/layout/LayOut";
import ResetPassword from "../container/admin/auth/resetpassword/ResetPassword";
import ForgotPassword from "../container/admin/auth/forgotpassword/ForgotPassword";
import UserManagementList from "../container/admin/pages/userManagementList/UserManagementList";
import Home from "../container/admin/pages/home/Home";
import PubliserList from "../container/admin/pages/publisher/PubliserList";
import AdvertiserList from "../container/admin/pages/advertiser/AdvertiserList";
import EditProfile from "../container/admin/pages/editprofile/EditProfile";
import SignUp from "../container/admin/auth/signup/SignUp";
import AdvertiserLayOut from "../container/advertiser/pages/advertiserLayout/AdvertiserLayout";
import AdvertiserHome from "../container/advertiser/pages/advertiserHome/AdvertiserHome";
import PublisherLayOut from "../container/publisher/pages/publisherLayout/PublisherLayout";
import PublisherHome from "../container/publisher/pages/publisherHome/PublisherHome";
import AdvertisementList from "../container/advertiser/pages/advertisement/AdvertisementList";
import AddUpdateAdvertisement from "../container/advertiser/pages/advertisement/AddUpdateAdvertisement";
import AdminPrivateRoutes from "./AdminPrivateRoutes";
import ViewAdvertisement from "../container/advertiser/pages/advertisement/ViewAdvertisement";
import CopyAdvertisementData from "../container/advertiser/pages/advertisement/CopyAdvertisementData";
import AdvertiserPrivateRoutes from "./AdvertiserPrivateRoutes";
import Transaction from "../container/admin/pages/transaction/Transaction";
import CategoryList from "../container/admin/pages/category/CategoryList";
import AddUpdateCategory from "../container/admin/pages/category/AddUpdateCategory";
import ViewCategory from "../container/admin/pages/category/ViewCategory";
import AdvertiserEditProfile from "../container/advertiser/pages/advertisereditprofile/AdvertiserEditProfile";
import AdminAdvertisementList from "../container/admin/pages/advertisement/AdminAdvertisementList";
import PublisherPrivateRoutes from "./PublisherPrivateRoutes";
import ViewAdminAdvertisement from "../container/admin/pages/advertisement/ViewAdvertisement";
import ViewAdminAdvertiser from "../container/admin/pages/advertiser/ViewAdvertiser";
import ViewAdminPublisher from "../container/admin/pages/publisher/ViewPublisher";
import VideoPlayer from "../container/admin/pages/videoplayer/VideoPlayer";
import AdminAdvertisementListNew from "../container/admin/pages/advertisement/AdminAdvertisementListNew";
import { CommonModal } from "../components/common/commonModel/CommonModel";
import PublisherEditProfile from "../container/publisher/pages/publishereditprofile/PublisherEditProfile";
import PublisherAdvertiserList from "../container/publisher/pages/publisherAdvertiserList/PublisherAdvertiserList";
import PublisherUpdate from "../container/admin/pages/publisher/PublisherUpdate";
import PrivateSale from "../container/admin/pages/presale/PrivateSale";
import PrivateSaleList from "../container/admin/pages/presale/PrivateSaleList";
import UpdateAdvertiser from "../container/admin/pages/advertiser/UpdateAdvertiser";

//#endregion

//#region Admin

//#endregion

export default function Routes() {
  // const login = useSelector((state) => {
  //   return state.AdminReducer.isAdminLogin;
  // });
  // const login = true;

  return (
    <BrowserRouter>
      <Routess>
        {/* <Route path={"/"}></Route> */}
        <Route path="/admin/login" element={<CommonModal />} />
        {/* <Route path="admin/forgot-password" element={<ForgotPassword />} />
        <Route path="admin/reset-password" element={<ResetPassword />} /> */}
        {/* <Route path="signup" element={<SignUp />} /> */}
        {/* <Route path="admin/otp-verify" element={<Otp />} /> */}
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/admin/" element={<LayOut />}>
            <Route path="dashboard" element={<Home />} />
            <Route
              path="user-management-list"
              element={<UserManagementList />}
            />
            <Route path="publisher-list" element={<PubliserList />} />
            <Route path="view-publisher" element={<ViewAdminPublisher />} />
            <Route path="edit-publisher" element={<PublisherUpdate />} />
            <Route path="edit-advertiser" element={<UpdateAdvertiser />} />

            <Route path="advertiser-list" element={<AdvertiserList />} />
            <Route path="view-advertiser" element={<ViewAdminAdvertiser />} />
            <Route path="account-setting" element={<EditProfile />} />
            <Route path="transaction" element={<Transaction />} />
            <Route path="category-list" element={<CategoryList />} />
            <Route path="add-category" element={<AddUpdateCategory />} />
            <Route path="view-category" element={<ViewCategory />} />
            {/* <Route path="add-presale" element={<PrivateSale />}/>
            <Route path="presale-list" element={<PrivateSaleList />}/> */}
            <Route
              path="view-admin-advertisement"
              element={<ViewAdminAdvertisement />}
            />
            <Route
              path="advertisement-list"
              element={<AdminAdvertisementListNew />}
            />
            {/* <Route
              path="advertisement-list"
              element={<AdminAdvertisementList />}
            /> */}
          </Route>
          <Route path="video-player" element={<VideoPlayer />} />
        </Route>

        {/* This Routes is for Advertiser */}

        <Route path="/" element={<CommonModal />} />
        <Route path="signup" element={<SignUp />} />
        {/* <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="otp-verify" element={<Otp />} /> */}
        {/* <Route
          path="advertiser/publisher-signup"
          element={<PublisherSignUp />}
        /> */}
        <Route path="advertiser/otp-verify" element={<Otp />} />
        <Route element={<AdvertiserPrivateRoutes />}>
          <Route path="/advertiser" element={<AdvertiserLayOut />}>
            <Route path="dashboard" element={<AdvertiserHome />} />
            <Route path="advertisment-list" element={<AdvertisementList />} />
            <Route path="view-advertisment" element={<ViewAdvertisement />} />
            <Route path="account-setting" element={<AdvertiserEditProfile />} />
            <Route
              path="add-update-advertisment"
              element={<AddUpdateAdvertisement />}
            />
            <Route
              path="copy-advertisement"
              element={<CopyAdvertisementData />}
            />
          </Route>
        </Route>
        {/* This Routes is for publisher */}

        {/* <Route path="/publisher/login" element={<Login />} />
        <Route path="publisher/forgot-password" element={<ForgotPassword />} />
        <Route path="publisher/reset-password" element={<ResetPassword />} />
        <Route path="advertiser-publisher/signup" element={<SignUp />} />
        <Route path="publisher/otp-verify" element={<Otp />} /> */}
        <Route element={<PublisherPrivateRoutes />}>
          <Route path="/publisher" element={<PublisherLayOut />}>
            <Route path="dashboard" element={<PublisherHome />} />
            <Route path="account-setting" element={<PublisherEditProfile />} />
            <Route path="advertiser-list" element={<PublisherAdvertiserList />} />
            <Route path="transaction" element={<Transaction />} />
          </Route>
        </Route>
      </Routess>
    </BrowserRouter>
  );
}