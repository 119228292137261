import React from 'react'
import Index from '../Index'
import PageIndex from '../PageIndex'

function WalletSetting() {
  return (
    <>
      <Index.Box className="swap-sec setting-wrapper">
                  <Index.Box className="swap-header-sec cus-between">
                    <Index.Box className="left-errow-sec">
                    </Index.Box>
                    <Index.Box className="swap-header-title">
                      <Index.Typography component="p" variant="p" className="swap-title">Setting</Index.Typography>
                    </Index.Box>
                    <Index.Box className="swap-header-icon">
                      <img src={PageIndex.Svg.closeSetting} alt="close" className="colse-setting-img" />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="search-main">
                    <Index.Box className="input-wrapper search-wrapper">
                      <Index.Box className="input-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="cus-input"
                          placeholder="Search..."
                        />
                      </Index.Box>
                      <img src={PageIndex.Svg.search2} alt="search" className="inp-search-ic" />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="setting-menu">
                    <Index.Box className="setting-item cus-between">
                      <Index.Box className="setting-item-text d-flex-y">
                        <img
                          src={PageIndex.Svg.settingSwap}
                          className="setting-ic"
                          alt="setting"
                        />
                        <Index.Typography component="p" variant="p" className="setting-item-title">General</Index.Typography>
                      </Index.Box>
                      <Index.Box className="setting-item-ic">
                        <img
                          src={PageIndex.Svg.rightArrow}
                          className="right-arrow-ic"
                          alt="rightArrow"
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="setting-item cus-between">
                      <Index.Box className="setting-item-text d-flex-y">
                        <img
                          src={PageIndex.Svg.contact}
                          className="setting-ic"
                          alt="setting"
                        />
                        <Index.Typography component="p" variant="p" className="setting-item-title">Contacts</Index.Typography>
                      </Index.Box>
                      <Index.Box className="setting-item-ic">
                        <img
                          src={PageIndex.Svg.rightArrow}
                          className="right-arrow-ic"
                          alt="rightArrow"
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="setting-item cus-between">
                      <Index.Box className="setting-item-text d-flex-y">
                        <img
                          src={PageIndex.Svg.alert}
                          className="setting-ic"
                          alt="setting"
                        />
                        <Index.Typography component="p" variant="p" className="setting-item-title">Alerts</Index.Typography>
                      </Index.Box>
                      <Index.Box className="setting-item-ic">
                        <img
                          src={PageIndex.Svg.rightArrow}
                          className="right-arrow-ic"
                          alt="rightArrow"
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="setting-item cus-between">
                      <Index.Box className="setting-item-text d-flex-y">
                        <img
                          src={PageIndex.Svg.network}
                          className="setting-ic"
                          alt="setting"
                        />
                        <Index.Typography component="p" variant="p" className="setting-item-title">Networks</Index.Typography>
                      </Index.Box>
                      <Index.Box className="setting-item-ic">
                        <img
                          src={PageIndex.Svg.rightArrow}
                          className="right-arrow-ic"
                          alt="rightArrow"
                        />
                      </Index.Box>
                    </Index.Box>

                  </Index.Box>
                </Index.Box>
    </>
  )
}

export default WalletSetting
