import React from 'react'
import PageIndex from '../PageIndex'
import Index from '../Index'

const qrstyle = {
    position: 'absolute',
    top: '50%',
    right: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
  };
  
function WalletHeader() {
      // qr modal
  const [openQr, setOpenQr] = React.useState(false);
  const handleOpenQr = () => setOpenQr(true);
  const handleCloseQr = () => setOpenQr(false);
  return (
    <>
      <Index.Box className='metamask-header-main'>
                <img src={PageIndex.Png.flexo} className="metamask-head-logo" />
                <Index.Box className='user-detail-drop'>
                  <Index.Button className='drop-user-img-sec' disableRipple onClick={handleOpenQr}>
                    <img src={PageIndex.Png.user2} className="user-img" />
                    <Index.Typography className='meta-drop-title' component="p" variant='p'>User Name...</Index.Typography>
                    <img src={PageIndex.Svg.downArrow} className="downarrow-img" />
                  </Index.Button>
                  <Index.Box className="cus-user-drop drop-user-img-sec user-d-none">
                    <Index.List className="main-list">
                      <Index.ListItem className="ul-listitem">
                        <img src={PageIndex.Png.user2} className="user-img" />
                        <Index.Typography className='meta-drop-title' component="p" variant='p'>User Name...</Index.Typography>
                      </Index.ListItem>
                      <Index.ListItem className="ul-listitem">
                        <img src={PageIndex.Png.user2} className="user-img" />
                        <Index.Typography className='meta-drop-title' component="p" variant='p'>User Name...</Index.Typography>
                      </Index.ListItem>
                      <Index.ListItem className="ul-listitem">
                        <img src={PageIndex.Png.user2} className="user-img" />
                        <Index.Typography className='meta-drop-title' component="p" variant='p'>User Name...</Index.Typography>
                      </Index.ListItem>
                    </Index.List>
                  </Index.Box>
                </Index.Box>
                <Index.Box className='three-dot-sec'>
                  <img src={PageIndex.Svg.web} alt="user-ic" className="web-img" />
                  <Index.Box className="cus-dot-drop">
                    <img src={PageIndex.Svg.dots} alt="dots" className="three-dot-img" />
                    <Index.Box className="cus-user-drop setting-d-non drop-user-img-sec">
                      <Index.List className="main-list">
                        <Index.ListItem className="ul-listitem">
                          <img src={PageIndex.Svg.profile2} alt="dots" className="drop-inner-img" />
                          <Index.Typography className='meta-drop-title' component="p" variant='p'>Account details</Index.Typography>
                        </Index.ListItem>
                        <Index.ListItem className="ul-listitem active">
                          <img src={PageIndex.Svg.bell} alt="dots" className="drop-inner-img" />
                          <Index.Typography className='meta-drop-title' component="p" variant='p'>Notifications</Index.Typography>
                        </Index.ListItem>
                        <Index.ListItem className="ul-listitem">
                          <img src={PageIndex.Svg.settingmodal} alt="dots" className="drop-inner-img" />
                          <Index.Typography className='meta-drop-title' component="p" variant='p'>Settings</Index.Typography>
                        </Index.ListItem>
                      </Index.List>
                    </Index.Box>
                  </Index.Box>

                </Index.Box>
              </Index.Box>
              <Index.Modal
          open={openQr}
          onClose={handleCloseQr}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className='common-modal modal'
        >
          <Index.Box className="qr-inner-modal-wallet">
            <Index.Box sx={qrstyle} className="common-modal-inner user-qr-modal-inner">
              <img src={PageIndex.Svg.closeQr} className='modal-close-icon' onClick={handleCloseQr} />
              <Index.Box className="user-qr-modal-content">
                <Index.Box className="user-qr-img-box">
                  <img src={PageIndex.Svg.userimg} className='user-img' alt='' />
                </Index.Box>
                <Index.Typography className='user-qr-name'>User Name...<span><img src={PageIndex.Svg.edittext} className='user-edit-icon' alt='' /></span></Index.Typography>
                <Index.Box className="qr-code-img-main">
                  <img src={PageIndex.Svg.qrcodeimg} className='qr-code-img' alt='' />
                </Index.Box>
                <Index.Box className="inner-modal-copy copy-text-sec-main">
                  <Index.Box className='copy-text-sec'>
                    <Index.Typography className='meta-drop-title' component="p" variant='p'>0x38DeeC1515707f5e64B412B713481162873
                      B85B3</Index.Typography>
                    <img src={PageIndex.Svg.copy} alt="copy" className="copy-img" />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Modal>
    </>
  )
}

export default WalletHeader
