import Svg from "../assets/Svg";
import Png from "../assets/Png";
import Video from "../assets/Video";
import Sidebar from "../components/admin/defaultLayout/Sidebar";
import Header from "../components/admin/defaultLayout/Header";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../components/common/pagination/PaginationMvp";
import MvpLoader from "../components/common/mvpLoader/MvpLoader";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import Button from "../components/common/button/Button"
import UserHeader from "../components/user/defaultLayout/UserHeader";
import UserFooter from "../components/user/defaultLayout/UserFooter";
import UserSidebar from "../components/user/defaultLayout/UserSidebar";


export default {
  useSelector,
  Field,
  Form,
  Formik,
  useDispatch,
  MvpLoader,
  useLocation,
  Pagination,
  useNavigate,
  Svg,
  Png,
  Sidebar,
  Header,
  toast,
  //   PaidLable,
  //   FailedLable,
  //   PendingLable,
  //   PrimaryButton,
  //   AuthFooter,
  //   AuthBackground,
  Button,
  Video,
  UserHeader,
  UserFooter,
  UserSidebar,
};
