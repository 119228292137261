import React from 'react'
import Index from '../Index';
import PageIndex from '../PageIndex';

function WalletSwap() {
    const [age, setAge] = React.useState('');

    const handleChangedropdown = (event) => {
      setAge(event.target.value);
    };
  return (
    <>
      <Index.Box className="swap-sec">
                  <Index.Box className="swap-header-sec cus-between">
                    <Index.Box className="left-errow-sec">
                      <img src={PageIndex.Svg.leftArrow} alt="leftArrow" className="leftarrow-img" />
                    </Index.Box>
                    <Index.Box className="swap-header-title">
                      <Index.Typography component="p" variant="p" className="swap-title">Swap</Index.Typography>
                    </Index.Box>
                    <Index.Box className="swap-header-icon">
                      <img src={PageIndex.Svg.settingSwap} alt="setting" className="swap-header-img" />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="swap-value-wrapper">
                    <Index.Box className="cus-line"></Index.Box>
                    <Index.Box className="cus-down-round">
                      <img src={PageIndex.Svg.swapDown} alt="swapDown" className="swap-down-img" />
                    </Index.Box>
                    <Index.Box className="swap-value-top">
                      <Index.Box>
                        <Index.Box className="input-box drop-eth add-user-input">
                          <Index.Box className='dropdown-box'>
                            <Index.FormControl className='form-control drop-form-control'>
                              <Index.Select className='dropdown-select drop-select'
                                value={age}
                                onChange={handleChangedropdown}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                              >
                                <Index.MenuItem value="" className='drop-menuitem'>
                                  <Index.Box className="flexo-img-sec"><img
                                    src={PageIndex.Png.flexo}
                                    className="flexo-ic"
                                    alt="logo"
                                  /></Index.Box> ETH
                                </Index.MenuItem>
                                <Index.MenuItem value={10} className='drop-menuitem'><Index.Box className="flexo-img-sec"><img
                                  src={PageIndex.Png.flexo}
                                  className="flexo-ic"
                                  alt="logo"
                                /></Index.Box> ETH 2</Index.MenuItem>
                                <Index.MenuItem value={20} className='drop-menuitem'><Index.Box className="flexo-img-sec"><img
                                  src={PageIndex.Png.flexo}
                                  className="flexo-ic"
                                  alt="logo"
                                /></Index.Box> ETH 3</Index.MenuItem>
                                <Index.MenuItem value={30} className='drop-menuitem'><Index.Box className="flexo-img-sec"><img
                                  src={PageIndex.Png.flexo}
                                  className="flexo-ic"
                                  alt="logo"
                                /></Index.Box> ETH 4</Index.MenuItem>
                              </Index.Select>
                            </Index.FormControl>
                          </Index.Box>
                        </Index.Box>
                        <Index.Typography component="p" variant="p" className="swap-balance-text">Balance : <span>0</span></Index.Typography>

                      </Index.Box>
                      <Index.Typography component="p" variant="p" className="swap-value-right">100</Index.Typography>
                    </Index.Box>
                    <Index.Box className="swap-value-bottom">
                      <Index.Box className="input-box drop-eth add-user-input">
                        <Index.Box className='dropdown-box'>
                          <Index.FormControl className='form-control drop-form-control'>
                            <Index.Select className='dropdown-select drop-select'
                              value={age}
                              onChange={handleChangedropdown}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                            >
                              <Index.MenuItem value="" className='drop-menuitem'>
                                Select Token
                              </Index.MenuItem>
                              <Index.MenuItem value={10} className='drop-menuitem'> Token 1</Index.MenuItem>
                              <Index.MenuItem value={20} className='drop-menuitem'> Token 2</Index.MenuItem>
                              <Index.MenuItem value={30} className='drop-menuitem'> Token 3</Index.MenuItem>
                            </Index.Select>
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                  </Index.Box>
                  <Index.PrimaryButton btnLabel="Select Token" className="primary-btn" />
                  <Index.Box className="terms-link-sec">
                    <Index.Link to={'/'} className="terms-link">Terms of service</Index.Link>
                  </Index.Box>
                </Index.Box>
    </>
  )
}

export default WalletSwap
