import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../../../components/publisher/publisherdefaultLayout/Sidebar";
import Header from "../../../../components/publisher/publisherdefaultLayout/Header";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import { useNavigate } from "react-router-dom";

export default function PublisherLayOut() {
  const [open, setOpen] = useState(true);
  const [screen, setScreen] = useState("");
  const location = PageIndex.useLocation();
  const handleClickOutside = () => {
    if (screen == "Mobile") {
      setOpen(!open);
      document.body.classList[open ? "add" : "remove"](
        "body-overflow"
      );
    }
  };
  const handleClickAccount = () => {
    setOpen(!open);
  };
  const navigate = useNavigate()
  useEffect(() => {
    if (window.screen.width < 991) {
      setScreen("Mobile");
    } else {
      setScreen("Desktop");
    }
  }, [window.screen.availHeight]);

  return (
    <div>
      <Index.Box
        className={`dashboard-main ${open ? "active" : "dashboard-main-overlay"
          }`}
      >
        <Index.Box
          className={`overlay-section ${(screen == "Mobile" ? !open : open) ? "active" : ""
            }`}
          onClick={handleClickOutside}
        ></Index.Box>

        {/* <Index.Box
          className={`dashboard-left-main ${open ? "active" : "admin-sidebar-deactive"
            }`}
        >
          <Sidebar open={open} setOpen={setOpen} />
        </Index.Box> */}

        <Index.Box
          className={`dashboard-left-main mobile-menu ${open ? "active" : "admin-sidebar-deactive"
            }`}
        >
          <Sidebar open={open} setOpen={setOpen} />
        </Index.Box>

        <Index.Box
          className={`dashboard-top-header mobile-logo ${open ? "active" : "admin-sidebar-deactive"
            }`}
        >
          <PageIndex.Header open={open} setOpen={setOpen} />
        </Index.Box>

        <Index.Box className={`dashboard-right-main ${open ? "active" : "admin-sidebar-left"}`}>
          {/* <Header setOpen={setOpen} open={open} /> */}
          <Index.Box className="dashboard-containt-main">
            <Outlet />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
