import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import MvpLoader from "../../../../components/common/mvpLoader/MvpLoader";
import { Skeleton } from "@mui/material";
import moment from "moment/moment";
import {
  activeDeactiveAdvertisement,
  adminAdvertisementListData,
  approveRejectAdvertisement
} from "../../../../redux/slices/AdminService";
import "./adminadvertisementlist.css";
import RejectModal from "../../../../components/common/rejectModal/RejectModal";
import FileExcel from "../../../../components/common/fileExcel/FileExcel";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#eaac35",
    color: "#ffffff",
    fontSize: "13px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px",
  },
}));



export default function AdminAdvertisementListNew() {
  const [loading, setLoading] = useState(true);
  const [adminAdvertisementList, setAdminAdvertisementList] = useState([]);
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [advertisementRow, setAdvertisementRow] = useState();
  const [reason, setReason] = useState();
  const [excel, setExcel] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const ITEM_HEIGHT = 48;
  const open1 = Boolean(anchorEl);
  const [singleChallangeData, setSingleChallangeData] = useState({})
  const fileName = "myfile";

  const dispatch = PageIndex?.useDispatch();
  const navigate = PageIndex?.useNavigate();

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setReason("");
  };

  // Get all advertisement list
  // const getAllAdvertisementList = async () => {
  //   dispatch(adminAdvertisementListData()).then((res) => {
  //     setAdminAdvertisementList(res?.payload?.data);
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 2000);
  //   });
  // };

  useEffect(() => {
    dispatch(adminAdvertisementListData()).then((res) => {
      setAdminAdvertisementList(res?.payload?.data);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    });
  }, []);

  const handleApproveRejectAdvertisement = async (id, status) => {
    const data = {
      id,
      status
    };

    if (status === "Rejected") {
      data.rejectReason = reason;
    }
    dispatch(approveRejectAdvertisement(data)).then((res) => {
      if (res?.payload?.status === 200) {
        dispatch(adminAdvertisementListData()).then((res) => {
          setAdminAdvertisementList(res?.payload?.data);
          setLoading(false);
          setDeleteOpen(false);
        });
      }
    });
  };

  const handleActiveDeactiveAdvertisement = async (id, status) => {
    const data = {
      id,
      status: status.toString()
    };
    dispatch(activeDeactiveAdvertisement(data)).then((res) => {
      if (res?.payload?.status === 200) {
        dispatch(adminAdvertisementListData()).then((res) => {
          setAdminAdvertisementList(res?.payload?.data);
          setLoading(false);
        });
      }
    });
  };

  useEffect(() => {
    const customHeadings = adminAdvertisementList?.map((item) => ({
      Title: item?.title,
      MaxSpendLimitPerTransaction: item?.maxSpendLimitPerTransaction,
      IsApproved: item?.isApproved,
      Category_Name: item?.category?.Category_Name,
      // "Duration": item?.duration,
      // MaxGasFeesLimit: item?.maxGasFeesLimit,
      StartDate: moment(item.startDate).format("DD/MM/YYYY"),
      EndDate: moment(item.endDate).format("DD/MM/YYYY")
    }));

    setExcel(customHeadings);
  }, [adminAdvertisementList]);

  useEffect(() => {
    const filtered =
      adminAdvertisementList &&
      adminAdvertisementList?.filter((item) => {
        return !search
          ? item
          : item?.title?.toLowerCase().includes(search?.toLowerCase()) ||
              item?.isApproved?.toLowerCase().includes(search?.toLowerCase()) ||
              item?.category?.Category_Name?.toLowerCase().includes(
                search?.toLowerCase()
              ) ||
              item?.maxSpendLimitPerTransaction
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase()) ||
              // item?.duration
              //   .toString()
              //   .toLowerCase()
              //   .includes(search.toString().toLowerCase()) ||
              // item?.maxGasFeesLimit
              //   .toString()
              //   .toLowerCase()
              //   .includes(search.toString().toLowerCase()) ||
              moment(item?.startDate)
                .format("DD-MM-YYYY")
                ?.toString()
                .includes(search?.toLowerCase()) ||
              moment(item?.endDate)
                .format("DD-MM-YYYY")
                ?.toString()
                .includes(search?.toLowerCase());
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [adminAdvertisementList, search]);

  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const handleActionOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      {loading ? (
        <Index.Box className="dashboard-content user-list-content">
          <Index.Box className="user-list-flex">
            <Index.Typography
              className="admin-page-title user-list-page-title main-heading"
              component="h2"
              variant="h2"
            >
              Advertisement
            </Index.Typography>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box flex-search-details">
                  <Index.Box className="form-group search-box-bg">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <img
                      src={PageIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                  <Index.Box>
                    <FileExcel apiData={excel} fileName={fileName} />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-bg-main table-main-box">
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box pb-0">
                      <Index.Box className="userlist-table-main page-table-main">
                        <Index.TableContainer component={Index.Paper} className="table-container">
                          <Index.Table aria-label="simple table" className="table">
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell className="table-th">Action</Index.TableCell>
                                <Index.TableCell className="table-th">Active/Inactive</Index.TableCell>
                                <Index.TableCell className="table-th">Title</Index.TableCell>
                                <Index.TableCell className="table-th">Max Spend LimitPer Transaction</Index.TableCell>
                                <Index.TableCell className="table-th">Max Gas Fees Limit</Index.TableCell>
                                <Index.TableCell className="table-th">Start Date</Index.TableCell>
                                <Index.TableCell className="table-th">End Date</Index.TableCell>
                                <Index.TableCell className="table-th">Reject Reason</Index.TableCell>
                                <Index.TableCell className="table-th">Status</Index.TableCell>
                                <Index.TableCell className="table-th">Advertisement Status</Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {[...Array(5)].map((_, index) => (
                                <Index.TableRow key={index}>
                                  <Index.TableCell width="5%">
                                    <Skeleton variant="circular" width={40} height={40} />
                                  </Index.TableCell>
                                  <Index.TableCell width="7%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="10%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="14%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="10%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="10%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="10%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="10%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="10%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                  <Index.TableCell width="10%">
                                    <Skeleton variant="text" />
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      ) : (
        <Index.Box className="dashboard-content user-list-content advertisement-wrapper">
          <Index.Box className="user-list-flex">
            <Index.Typography
              className="admin-page-title user-list-page-title main-heading"
              component="h2"
              variant="h2"
            >
              Advertisement
            </Index.Typography>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box flex-search-details">
                  <Index.Box className="form-group search-box-bg">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <img
                      src={PageIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                  <Index.Box>
                    <FileExcel apiData={excel} fileName={fileName} />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12"
                  }}
                  className="grid-column"
                >
                  <Index.Box className="table-bg-main table-main-box">
                    <Index.Box className="admin-dash-box pb-0">
                      <Index.Box className="advertiserlist-table-main page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Action
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Active/Inactive
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Title
                                </Index.TableCell>

                                {/* <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Duration
                                </Index.TableCell> */}
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Max Spend LimitPer Transaction
                                </Index.TableCell>
                                {/* <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Max Gas Fees Limit
                                </Index.TableCell> */}
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Start Date
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  End Date
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Reject Reason
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Status
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Advertisement Status
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            {pageData && pageData?.length > 0 ? (
                              <Index.TableBody className="table-body">
                                {pageData &&
                                  pageData.map((row, index) => (
                                    <Index.TableRow
                                      key={row?._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0
                                        }
                                      }}
                                    >

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td action-tab"
                                      >
                                        <Index.IconButton
                                          aria-label="more"
                                          id="long-button"
                                          aria-haspopup="true"
                                          onClick={(event) => {
                                            handleActionOpen(event);
                                            setSingleChallangeData(
                                              pageData?.[index]
                                            );
                                          }}
                                        // sx={{ filter: "invert(1)" }}
                                        >
                                          <Index.MoreVertIcon />
                                        </Index.IconButton>
                                      </Index.TableCell>


                                      {/* <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        <Index.Box className="userdata-btn-flex">
                                          <Index.IconButton
                                            disabled={
                                              row?.isApproved === "Approved" ||
                                              row?.isApproved === "Rejected"
                                            }
                                            onClick={() =>
                                              handleApproveRejectAdvertisement(
                                                row?._id,
                                                "Approved"
                                              )
                                            }
                                          >
                                            <Index.CheckIcon className="table-td-active" />
                                          </Index.IconButton>

                                          <Index.Button
                                            disabled={
                                              row?.isApproved === "Rejected" ||
                                              row?.isApproved === "Approved"
                                            }
                                            onClick={() => {                                              
                                              setAdvertisementRow(row);
                                              handleDeleteOpen();
                                            }}
                                          >
                                            <Index.CloseIcon className="table-td-Inactive" />
                                          </Index.Button>
                                          <Index.IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            onClick={() => {
                                              navigate(
                                                "/admin/view-admin-advertisement",
                                                {
                                                  state: row
                                                }
                                              );
                                            }}
                                          >
                                            <Index.RemoveRedEyeIcon className="view-ic" />
                                          </Index.IconButton>
                                        </Index.Box>
                                      </Index.TableCell> */}

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        <Index.Box className="userdata-btn-flex">
                                          <Index.Switch
                                            className="cus-switch"
                                            checked={row?.isActive}
                                            onChange={(e) =>
                                              handleActiveDeactiveAdvertisement(
                                                row?._id,
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </Index.Box>
                                      </Index.TableCell>


                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {row.title}
                                      </Index.TableCell>

                                      {/* <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.duration}
                                    </Index.TableCell> */}
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row.maxSpendLimitPerTransaction}
                                      </Index.TableCell>
                                      {/* <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row.maxGasFeesLimit}
                                      </Index.TableCell> */}
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.startDate
                                          ? moment(row?.startDate).format(
                                            "DD-MM-YYYY"
                                          )
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.endDate
                                          ? moment(row?.endDate).format(
                                            "DD-MM-YYYY"
                                          )
                                          : "-"}
                                      </Index.TableCell>

                                      {row?.isApproved === "Approved"
                                        || row?.isApproved === "Pending"
                                        ? (
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                          >
                                            -
                                          </Index.TableCell>
                                        ) : (
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"

                                          // onClick={() => {
                                          //   setAdvertisementRow(row);
                                          //   handleDeleteOpen();
                                          // }}
                                          >
                                            <LightTooltip
                                              title={row?.rejectReason}>
                                              <Index.Typography
                                                className="meta-drop-title"
                                                component="p"
                                                variant="p"
                                              >
                                                {`${row?.rejectReason.slice(0, 6)}....`}
                                              </Index.Typography>
                                            </LightTooltip>
                                            {/* {`${row?.rejectReason.slice(0, 6)}....`} */}
                                            {/* <Index.CloseIcon className="table-td-Inactive" /> */}
                                          </Index.TableCell>
                                        )}

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td status-col"
                                      >
                                        <div
                                          className={
                                            row?.isApproved === "Approved"
                                              ? "table-td-active"
                                              : row?.isApproved === "Rejected"
                                                ? "table-td-Inactive"
                                                : "table-td-Pending"
                                          }
                                        >
                                          {row?.isApproved === "Approved"
                                            ? "Approved"
                                            : row?.isApproved === "Rejected"
                                              ? "Rejected"
                                              : "Pending"}
                                        </div>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td status-col"
                                      >
                                        <div
                                          className={
                                            row?.isRunning === true
                                              ? "table-td-active"
                                              : "table-td-Inactive"
                                          }
                                        >
                                          {row?.isRunning === true
                                            ? "Running"
                                            : "Pause"}
                                        </div>
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  ))}
                              </Index.TableBody>
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td no-record-text"
                                  align="center"
                                  colSpan={8}
                                >
                                  <h3> No records available </h3>
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.Table>
                        </Index.TableContainer>

                        {/* Action Menu */}
                        <Index.Box className="admin-menu-box">
                          <Index.Menu
                            className="admin-menu-main"
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl}
                            open={open1}
                            onClose={handleActionClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "auto",
                                boxShadow: "none",
                                border: "1px solid rgba(224, 224, 224, 1)",
                                marginTop: "6px",
                              },
                            }}
                          >
                            <Index.MenuItem
                              key={`check_`}
                              onClick={handleActionClose}
                              className="menu-option-li"
                            >
                              <Index.IconButton
                                disabled={
                                  singleChallangeData?.isApproved === "Approved" ||
                                  singleChallangeData?.isApproved === "Rejected"
                                }
                                onClick={() =>
                                  handleApproveRejectAdvertisement(
                                    singleChallangeData?._id,
                                    "Approved"
                                  )
                                }
                              >
                                <Index.CheckIcon className="table-td-active" />
                              </Index.IconButton>
                            </Index.MenuItem>

                            <Index.MenuItem
                              key={`close_`}
                              onClick={handleActionClose}
                              className="menu-option-li"
                            >
                              <Index.Button
                                disabled={
                                  singleChallangeData?.isApproved === "Rejected" ||
                                  singleChallangeData?.isApproved === "Approved"
                                }
                                onClick={() => {
                                  setAdvertisementRow(singleChallangeData);
                                  handleDeleteOpen();
                                }}
                              >
                                <Index.CloseIcon className="table-td-Inactive" />
                              </Index.Button>
                            </Index.MenuItem>

                            <Index.MenuItem
                              key={`view_`}
                              onClick={handleActionClose}
                              className="menu-option-li"
                            >
                              <Index.IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                onClick={() => {
                                  navigate(
                                    "/admin/view-admin-advertisement",
                                    {
                                      state: singleChallangeData
                                    }
                                  );
                                }}
                              >
                                <Index.RemoveRedEyeIcon className="view-ic" />
                              </Index.IconButton>
                            </Index.MenuItem>



                          </Index.Menu>
                        </Index.Box>
                        {/* End Action Menu */}


                      </Index.Box>
                      <Index.Box className="pagination-main">
                        <PageIndex.Pagination
                          fetchData={filteredData}
                          setPageData={setPageData}
                          pageData={pageData}
                          search={search}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}

      <RejectModal
        deleteOpen={deleteOpen}
        handleDeleteClose={handleDeleteClose}
        reason={reason}
        setReason={setReason}
        handleReject={handleApproveRejectAdvertisement}
        row={advertisementRow}
      />
    </>
  );
}
