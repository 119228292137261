import React from 'react'
import Index from '../../Index'
import PageIndex from '../../PageIndex'

export default function UserHeader() {
    const [age, setAge] = React.useState('');

    const handleChangedropdown = (event) => {
        setAge(event.target.value);
    };
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
    return (
        <>
            <Index.Box className="user-header">
                <Index.Box className="user-header-logo-sec">
                    {/* <Index.Button className='bar-btn'
                        onClick={() => setMobileMenuOpen((prev) => !prev)}>
                        <img
                            src={PageIndex.Svg.bergurmenu}
                            className="bergurmenu-icon"
                            alt="bergurmenu-icon"
                        ></img>
                    </Index.Button> */}
                    <Index.Box className="user-header-logo">
                        <img
                            src={PageIndex.Png.flexoLogo}
                            className="user-header-logo res-user-sidebar-logo"
                            alt="logo"
                        />
                    </Index.Box>
                    {/* <img
                        src={PageIndex.Svg.frame}
                        className="frame-img"
                        alt="fram"
                    /> */}
                </Index.Box>
                <Index.List className={`user-header-list ${mobileMenuOpen ? "active" : ""}`}>
                    <Index.ListItem className='user-header-listitem'>
                        <Index.Link
                            to="/user/user-dashboard"
                            className="user-header-link"
                        >
                            Vesting dashboard
                        </Index.Link>
                    </Index.ListItem>
                    
                </Index.List>
                {/* <Index.List className={`user-header-list ${mobileMenuOpen ? "active" : ""}`}>
                    <Index.ListItem className='user-header-listitem'>
                        <Index.Link
                            to="/"
                            className="user-header-link"
                        >
                            Buy Crypto
                        </Index.Link>
                    </Index.ListItem>
                    <Index.ListItem className='user-header-listitem'> <Index.Link to="/" className="user-header-link">Spot Trading</Index.Link></Index.ListItem>
                    <Index.ListItem className='user-header-listitem'><Index.Link to="/" className="user-header-link">Derivatives</Index.Link></Index.ListItem>
                    <Index.ListItem className='user-header-listitem'> <Index.Link to="/" className="user-header-link">Markets</Index.Link></Index.ListItem>
                    <Index.ListItem className='user-header-listitem'><Index.Link to="/" className="user-header-link">DEX</Index.Link></Index.ListItem>
                    <Index.ListItem className='user-header-listitem'><Index.Link to="/" className="user-header-link">Mvp Card</Index.Link></Index.ListItem>
                    <Index.ListItem className='user-header-listitem'><Index.Link to="/" className="user-header-link">DeFi Wallet</Index.Link></Index.ListItem>
                    <Index.ListItem className='user-header-listitem'><Index.Link to="/" className="user-header-link">Mvp Token</Index.Link></Index.ListItem>
                    <Index.ListItem className='user-header-listitem'><Index.Link to="/" className="user-header-link">Rewards</Index.Link></Index.ListItem>
                    <Index.PrimaryButton className="user-border-btn user-sign-btn res-user-sign-btn" btnLabel="Sign Out" />
                </Index.List>
                <Index.Box className="user-header-button">
                    <Index.PrimaryButton className="user-btn-primary switch-btn" btnLabel="Switch" />
                    <Index.PrimaryButton className="user-border-btn user-sign-btn" btnLabel="Sign Out" />
                    <Index.Box className="play-btn-sec">
                        <img
                            src={PageIndex.Svg.appleIcon}
                            className="appleIcon-img"
                            alt="appleIcon"
                        />
                        <img
                            src={PageIndex.Svg.playStore}
                            className="playstore-img"
                            alt="playStore"
                        />
                    </Index.Box>
                    <Index.Box className='dropdown-box user-header-dropdown language-drop'>
                        <Index.FormControl className='form-control'>
                            <Index.Select className='dropdown-select '
                                value={age}
                                onChange={handleChangedropdown}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <Index.MenuItem value="" className='menuitem'>English</Index.MenuItem>
                                <Index.MenuItem value={10} className='menuitem'>Hindi</Index.MenuItem>
                                <Index.MenuItem value={20} className='menuitem'>English</Index.MenuItem>
                                <Index.MenuItem value={30} className='menuitem'>Hindi</Index.MenuItem>
                            </Index.Select>
                        </Index.FormControl>
                    </Index.Box>
                    <Index.Box className='dropdown-box user-header-dropdown res-menu-list-drop'>
                        <Index.FormControl className='form-control'>
                            <Index.Select className='dropdown-select '
                                value={age}
                                onChange={handleChangedropdown}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <Index.MenuItem value="" className='menuitem'>Dashboard</Index.MenuItem>
                                <Index.MenuItem value={10} className='menuitem'>Private Sale</Index.MenuItem>
                                <Index.MenuItem value={20} className='menuitem'>Wallet</Index.MenuItem>
                                <Index.MenuItem value={30} className='menuitem'>Purchase History</Index.MenuItem>
                                <Index.MenuItem value={30} className='menuitem'>Referrals</Index.MenuItem>
                                <Index.MenuItem value={30} className='menuitem'>Account</Index.MenuItem>
                                <Index.MenuItem value={30} className='menuitem'>Sign Out</Index.MenuItem>
                            </Index.Select>
                        </Index.FormControl>
                    </Index.Box>
                </Index.Box> */}

            </Index.Box >
        </>
    )
}
