import axios from "axios";
import PageIndex from "../container/PageIndex";

const DataService = axios.create({
  baseURL: "https://ad-mvp-wallet-backend.appworkdemo.com/api/",
  // baseURL:"http://localhost:3040/api/"
});
// DataService.interceptors.request.use(
//   (config) => {
//     config.headers.auth = localStorage.getItem("token");
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
export default DataService;
// export const imageUrl = "http://localhost:3040/images"
export const imageUrl = "https://ad-mvp-wallet-backend.appworkdemo.com/api/images";
export const doGet = async (url) => {
  try {
    const response = await DataService.get(url);
    return response;
  } catch (error) {
    PageIndex.toast.error(error?.response?.data?.message, {
      toastId:"customId"
    });
  }
};
export const doPost = async (url, data) => {
  try {
    const response = await DataService.post(url, data);
    if (response?.data?.status == 200 || 201) {
      PageIndex.toast.success(response?.data?.message,
        {
        toastId:"customId"
      });
    }
    return response;
  } catch (error) {
    PageIndex.toast.error(error?.response?.data?.message,    {
      toastId:"customId"
    });
  }
};
