import React from 'react'
import Index from '../Index'
import PageIndex from '../PageIndex'

function WalletTransactionInfo() {
    const [age, setAge] = React.useState('');

    const handleChangedropdown = (event) => {
      setAge(event.target.value);
    };
  return (
    <>
     <Index.Box className="swap-sec new-transaction">
                  <Index.Box className="swap-header-sec cus-between">
                    <Index.Box className="left-errow-sec">
                      <img src={PageIndex.Svg.arrowBack} alt="back-arrow" className="backarrow-img" />
                    </Index.Box>
                    <Index.Box className="swap-header-title">
                      <Index.Typography component="p" variant="p" className="swap-title">New transaction</Index.Typography>
                    </Index.Box>
                    <Index.Box className="swap-header-icon">
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="new-trans-input-sec">
                    <Index.Box className="contact-input-sec">
                      <Index.Box className="input-wrapper add-user-input">
                        <Index.FormHelperText className='input-lable'>Enter address or ENS Name</Index.FormHelperText>
                        <Index.Box className="input-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="cus-input"
                            placeholder="0xa221A50805A233ec44403937c31c43fACfeDCD"
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-wrapper ">
                        <Index.FormHelperText className='input-lable'>Enter ABI</Index.FormHelperText>
                        <Index.Box className="input-group">
                          <Index.TextareaAutosize
                            fullWidth
                            id="fullWidth"
                            className="cus-areainput"
                            placeholder="It is a long established fact that a reader will be dis
                                      ed by the readable content of a page when looking 
                                      its layout. The point of using Lorem Ipsum is that it
                                      s a more-or-less normal distribution of letters, as 
                                      osed to using 'Content here, content here', making
                                      look like readable English. Many desktop publishi 
                                      packages and web page editors now use Lorem Ip
                                      m as their default model text, and a search for 'lor
                                      ipsum' will uncover many web sites still in their in
                                      cy. Various versions have evolved over the years, s
                                      etimes by accident, sometimes on purpose (inject
                                      humour and the like)."
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="transaction-info-sec">
                    <Index.Box className="swap-header-title">
                      <Index.Typography component="p" variant="p" className="swap-title">Transaction information</Index.Typography>
                    </Index.Box>
                    <Index.Box className="input-wrapper add-user-input">
                      <Index.FormHelperText className='input-lable'>To address</Index.FormHelperText>
                      <Index.Box className="input-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="cus-input"
                          placeholder="0xa221A50805A233ec44403937c31c43fACfeDCD"
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className='form-lable'>Contract Method Selector</Index.FormHelperText>
                      <Index.Box className='dropdown-box mb-24'>
                        <Index.FormControl className='form-control drop-form-control'>
                          <Index.Select className='dropdown-select drop-select'
                            value={age}
                            onChange={handleChangedropdown}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            <Index.MenuItem value="" className='drop-menuitem'>
                              mintTokens
                            </Index.MenuItem>
                            <Index.MenuItem value={10} className='drop-menuitem'>mintTokens</Index.MenuItem>
                            <Index.MenuItem value={20} className='drop-menuitem'>mintTokens</Index.MenuItem>
                            <Index.MenuItem value={30} className='drop-menuitem'>mintTokens</Index.MenuItem>
                          </Index.Select>
                        </Index.FormControl>
                      </Index.Box>
                      <Index.Box className="input-wrapper add-user-input">
                        <Index.Box className="input-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="cus-input"
                            placeholder="_amount (uint256)*"
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="add-transaction-btn-sec">
                        <Index.PrimaryButton btnLabel="Add transaction" className='primary-btn save-btn' />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box> 
    </>
  )
}

export default WalletTransactionInfo
