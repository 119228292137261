import React, { useEffect, useState } from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import { imageUrl } from "../../../../config/DataService";

export default function ViewCategory() {
  // for open handleChangedropdown
  const [imageData, setImageData] = useState([]);
  const params = PageIndex.useLocation();
  const navigate = PageIndex.useNavigate();

  const singleCategoryData = params?.state;

  const initialValues = {
    Category_Name: singleCategoryData?._id
      ? singleCategoryData?.Category_Name
      : "",
  };

  ////////////// This is for add Update image video ///////////////
  useEffect(() => {
    if (singleCategoryData) {
      const newImageData = {
        type: "image",
        pre: `${imageUrl}/${singleCategoryData.Category_Image}`,
        backend: true,
      };
      setImageData(newImageData);
    }
  }, [singleCategoryData]);

  return (
    <Index.Box className="dashboard-content add-user-containt">
      <Index.Typography
        className="admin-page-title main-heading"
        component="h2"
        variant="h2"
      >
        View Category
      </Index.Typography>
      <Index.Box className="table-bg-main">
        <PageIndex.Formik initialValues={initialValues}>
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <PageIndex.Form>
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Category Name<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <PageIndex.Field
                            disabled
                            name="Category_Name"
                            as={Index.TextField}
                            // label="Carpet Area"
                            variant="outlined"
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            onChange={handleChange}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input add-game-row">
                        <Index.FormHelperText className="form-lable add-game-lables">
                          Category Images/Videos
                        </Index.FormHelperText>

                        <Index.Box className="grid-row">
                          <Index.Box className="grid-main">
                            <Index.Box className="display-row-add-user">
                              {imageData && imageData.type === "image" ? (
                                <Index.Box sx={{ gridColumn: "span 12" }}>
                                  <Index.Box className="document-close-set">
                                    <Index.Button
                                      disabled
                                      className="document-close"
                                      onClick={() => {
                                        // Handle removal of the image
                                        if (imageData.backend) {
                                          // Add code to remove the image from the backend if necessary
                                        }
                                        setImageData(null);
                                      }}
                                    >
                                      <Index.CloseTwoToneIcon />
                                    </Index.Button>
                                  </Index.Box>
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 3",
                                      md: "span 3",
                                      lg: "span 3",
                                    }}
                                  >
                                    <Index.Box>
                                      <img
                                        className="upload-img-slider"
                                        src={imageData.pre}
                                        alt="Advertisement"
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              ) : (
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 3",
                                    md: "span 3",
                                    lg: "span 3",
                                  }}
                                >
                                  <Index.Box className="images-upload-btn"></Index.Box>
                                </Index.Box>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="user-btn-flex">
                        <Index.Box className="discard-btn-main border-btn-main">
                          <Index.Button
                            className="discard-user-btn border-btn"
                            onClick={() => navigate("/admin/category-list")}
                          >
                            Back
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </PageIndex.Form>
          )}
        </PageIndex.Formik>
      </Index.Box>
    </Index.Box>
  );
}
