import React, { useState } from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import { forgotPasswordSchema } from "../../../../validations/Validation";
import { RoleForgotPassword } from "../../../../redux/slices/AdminService";
import ButtonMvpLoader from "../../../../components/common/buttonMvpLoader/ButtonMvpLoader";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
export default function ForgotPassword({
  handleOtpSend,
  setVerifyEmail,
  handleLogin,
}) {
  const dispatch = PageIndex?.useDispatch();
  const [loading, setLoading] = useState(false);

  const location = PageIndex?.useLocation();

  const HandleForgotPasswordSubmit = (values) => {
    setLoading(true);
    const forgotPasswordData = {
      email: values?.email,
      roleType:
        // location.pathname === "/admin/forgot-password" ? "admin" : "user",
        location.pathname === "/admin/login" ? "admin" : "user",
    };
    dispatch(RoleForgotPassword(forgotPasswordData)).then((res) => {
      setTimeout(() => {
        setLoading(false);
      }, 6000);
      if (
        (res?.payload !== undefined && res?.payload?.status == 200) ||
        (res?.payload !== undefined && res?.payload?.status == 201)
      ) {
        setLoading(false);
        localStorage.setItem("otpResendEndTime", new Date());
        setVerifyEmail(values?.email);
        handleOtpSend();
      }
    });
  };

  return (
    <div>
      <Index.Box className="">
        <Index.Formik
          validationSchema={forgotPasswordSchema}
          initialValues={{
            email: "",
          }}
          onSubmit={HandleForgotPasswordSubmit}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            isValid,
            touched,
            dirty,
          }) => (
            <Index.Form onSubmit={handleSubmit}>
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Forgot your password.
                </Index.Typography>
                <Index.Box className="input-box">
                  <Index.FormHelperText className="form-lable">
                    Email<span>*</span>
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Please enter email"
                      autocomplete="off"
                      name="email"
                      type="text"
                      onBlur={handleBlur}
                      value={values.email}
                      onChange={handleChange}
                      error={Boolean(errors.email)}
                      onKeyDown={ e => e.keyCode === 32 && e.preventDefault() }
                     

                    />
                    {errors?.email && touched?.email ? (
                      <Index.Typography className="error-msg">
                        {errors?.email}
                      </Index.Typography>
                    ) : null}
                  </Index.Box>
                </Index.Box>

                <Index.Box className="btn-main-primary login-btn-main">
                  <Index.Button className="btn-primary login-btn" type="submit">
                    {loading ? (
                      <ButtonMvpLoader color="white" loading={loading} />
                    ) : (
                      "Submit"
                    )}
                  </Index.Button>
                </Index.Box>
                <Index.Box className="admin-signup">
                  <Index.Typography>
                    <Index.Link
                      className="text-decoration-none admin-forgot-para common-para"
                      // to={"/"}
                      onClick={() => {
                        handleLogin();
                      }}
                    >
                      Back to Login
                    </Index.Link>{" "}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Form>
          )}
        </Index.Formik>
      </Index.Box>
    </div>
  );
}
