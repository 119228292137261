import React from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import moment from "moment";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function ViewAdminPublisher() {
  const navigate = PageIndex?.useNavigate();
  const params = PageIndex.useLocation();
  const advertiserSingleData = params?.state;

  const initialValues = {
    name: advertiserSingleData?._id ? advertiserSingleData?.name : "",
    email: advertiserSingleData?._id ? advertiserSingleData?.email : "",
    company: advertiserSingleData?._id ? advertiserSingleData?.company : "",
    isApproved: advertiserSingleData?._id
      ? advertiserSingleData?.isApproved
      : "",
    createdAt: advertiserSingleData?._id ? advertiserSingleData?.createdAt : "",
    publishUrl: advertiserSingleData?._id
      ? advertiserSingleData?.publishUrl
      : "",
      amount: advertiserSingleData?.amount ? advertiserSingleData?.amount : 0
  };

  return (
    <>
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Typography
          className="admin-page-title main-heading"
          component="h2"
          variant="h2"
        >
          View Publisher
        </Index.Typography>
        <Index.Box className="table-bg-main">
          <PageIndex.Formik initialValues={initialValues}>
            {({ values, handleChange, setFieldValue }) => (
              <PageIndex.Form>
                <Index.Box className="add-user-data-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Name<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              disabled
                              name="name"
                              as={Index.TextField}
                              // label="Carpet Area"
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 25) {
                                  // Only update the value if it's not more than 30 characters
                                  handleChange(event);
                                }
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Company Name<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              disabled
                              name="company"
                              as={Index.TextField}
                              // label="Carpet Area"
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 10) {
                                  // Only update the value if it's not more than 30 characters
                                  handleChange(event);
                                }
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Email<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              disabled
                              name="email"
                              as={Index.TextField}
                              // label="Carpet Area"
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 10) {
                                  // Only update the value if it's not more than 30 characters
                                  handleChange(event);
                                }
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Created Date
                          </Index.FormHelperText>
                          <Index.Box className="form-group custom-datepicker ">
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              className="date-adapter-main"
                            >
                              <DemoContainer
                                components={["DatePicker", "DatePicker"]}
                                className="date-adapter-container"
                              >
                                <DatePicker
                                  className="date-adapter-picker"
                                  disabled
                                  name="createdAt"
                                  value={dayjs(values.createdAt)}
                                  onChange={(e) => {
                                    const formatedDate = moment(e).format(
                                      "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
                                    );
                                    setFieldValue("createdAt", formatedDate);
                                  }}
                                  format="DD/MM/YYYY"
                                  disablePast
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Status
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              disabled
                              name="isApproved"
                              as={Index.TextField}
                              // label="Carpet Area"
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 10) {
                                  // Only update the value if it's not more than 30 characters
                                  handleChange(event);
                                }
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Publisher Url<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              disabled
                              name="publishUrl"
                              as={Index.TextField}
                              // label="Carpet Area"
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 10) {
                                  // Only update the value if it's not more than 30 characters
                                  handleChange(event);
                                }
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                          Amount<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              disabled
                              name="amount"
                              as={Index.TextField}
                              // label="Carpet Area"
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 10) {
                                  // Only update the value if it's not more than 30 characters
                                  handleChange(event);
                                }
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="user-btn-flex">
                          <Index.Box className="discard-btn-main border-btn-main">
                            <Index.Button
                              className="discard-user-btn border-btn"
                              onClick={() => navigate("/admin/publisher-list")}
                            >
                              Back
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </PageIndex.Form>
            )}
          </PageIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default ViewAdminPublisher;
