import React, { useEffect, useRef, useState } from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import OtpInput from "react-otp-input";
import { verifyOtpSchema } from "../../../../validations/Validation";
import {
  RoleForgotPassword,
  verfiyRoleOtp,
} from "../../../../redux/slices/AdminService";
import ButtonMvpLoader from "../../../../components/common/buttonMvpLoader/ButtonMvpLoader";
import ResetPassword from "../resetpassword/ResetPassword";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
export default function Otp({ handleResetPassword, verifyEmail }) {

  const navigate = PageIndex?.useNavigate();
  const dispatch = PageIndex?.useDispatch();
  const [loading, setLoading] = useState(true);
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const intervalRef = useRef(null);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const location = PageIndex?.useLocation();

  const HandleOtpSubmit = (values, { resetForm }) => {
    setLoading(true);
    const otpData = {
      otp: values?.otp,
      email: verifyEmail,
    };
    dispatch(verfiyRoleOtp(otpData)).then((res) => {
      setTimeout(() => {
        setLoading(false);
      }, 6000);
      if (
        (res?.payload !== undefined && res?.payload?.status == 200) ||
        (res?.payload !== undefined && res?.payload?.status == 201)
      ) {
        setLoading(false);
        handleResetPassword();
      } else {
        resetForm({
          ...values,
          otp: "",
        });
      }
    });
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 6000);
  }, []);

  const resendOtp = async () => {
    setMinutes(2);
    setSeconds(0);
    setDisabled(true);
    const forgotPasswordData = {
  
      email:verifyEmail ,
      roleType: location.pathname === "/admin/login" ? "admin" : "user",
    };
    dispatch(RoleForgotPassword(forgotPasswordData)).then((res) => {
      setTimeout(() => {
        setLoading(false);
      }, 6000);
      if (
        (res?.payload !== undefined && res?.payload?.status == 200) ||
        (res?.payload !== undefined && res?.payload?.status == 201)
      ) {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        if (minutes === 0) {
          clearInterval(intervalRef.current);
          setDisabled(false);
        } else {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [resendOtp]);

  return (
    <div>
      <Index.Box className="">
        <Index.Formik
          validationSchema={verifyOtpSchema}
          initialValues={{
            otp: "",
          }}
          onSubmit={HandleOtpSubmit}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            isValid,
            touched,
            dirty,
          }) => (
            <Index.Form>
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  OTP!
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                  {/* Please enter your credentials to Otp! */}
                </Index.Typography>

                <Index.Box className="otp-flex-main">
                  <Index.Box className="otp">
                    <OtpInput
                      name="otp"
                      inputStyle="inputStyle"
                      numInputs={4}
                      inputType="number"
                      renderSeparator={<span> </span>}
                      value={values.otp}
                      // validateChar="number"
                      onChange={(file) => setFieldValue("otp", file)}
                      error={Boolean(errors.otp)}
                      className="otp-design-div otp-placeholder"
                      renderInput={(props) => <input {...props} />}
                    />
                  </Index.Box>
                </Index.Box>
                {errors?.otp && touched?.otp ? (
                  <Index.Typography className="error-msg">
                    {errors?.otp}
                  </Index.Typography>
                ) : null}
                <Index.Box className="box-login-text forgot verify-box">
                  {minutes > 0 || seconds > 0 ? (
                    <Index.Typography
                      variant="body1"
                      component="p"
                      className="resend-otp-code"
                    >
                    OTP expired in {` 
                            ${minutes < 10 ? `0${minutes}` : minutes}:
                            ${seconds < 10 ? `0${seconds}` : seconds}`}
                    </Index.Typography>
                  ) : (
                    <Index.Button onClick={resendOtp} disabled={disabled}>
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className="resend-otp-p"
                      >
                        Resend OTP
                      </Index.Typography>
                    </Index.Button>
                  )}
                </Index.Box>
                <Index.Box className="btn-main-primary login-btn-main">
                  <Index.Button
                    className="btn-primary login-btn"
                    type="submit"
                    disabled={loading}
                    // startIcon={
                    //   loading ? (
                    //     <ButtonMvpLoader color="white" loading={loading} />
                    //   ) : null
                    // }
                  >
                    {loading ? (
                      <ButtonMvpLoader color="white" loading={loading} />
                    ) : (
                      "Send OTP"
                    )}
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Form>
          )}
        </Index.Formik>
      </Index.Box>
    </div>
  );
}
