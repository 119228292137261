import React from 'react'
import Index from '../Index'
import PageIndex from '../PageIndex'

function WalletNewTransaction() {
    const [age, setAge] = React.useState('');

    const handleChangedropdown = (event) => {
      setAge(event.target.value);
    };
  return (
    <>
     <Index.Box className="swap-sec new-transaction-first">
                  <Index.Box className="swap-header-sec cus-between">
                    <Index.Box className="left-errow-sec">
                      <img src={PageIndex.Svg.arrowBack} alt="back-arrow" className="backarrow-img" />
                    </Index.Box>
                    <Index.Box className="swap-header-title">
                      <Index.Typography component="p" variant="p" className="swap-title">New transaction</Index.Typography>
                    </Index.Box>
                    <Index.Box className="swap-header-icon">
                      <img src={PageIndex.Svg.closeSetting} alt="close" className="colse-setting-img" />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="send-token-sec cus-between">
                    <Index.Typography component="p" variant="p" className="send-token">Send Tokens</Index.Typography>
                    <Index.Box className="nonce-sec">
                      <Index.Typography component="p" variant="p" className="nonce-text">Nonce #</Index.Typography>
                      <Index.Box className="input-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="cus-input"
                          placeholder="3"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box">
                    <Index.FormHelperText className='form-lable'>Recipient address or ENS</Index.FormHelperText>
                    <Index.Box className='dropdown-box scanner-drop mb-24'>
                      <img src={PageIndex.Svg.scanner} alt="scanner-ic" className="scanner-ic" />

                      <Index.FormControl className='form-control drop-form-control'>
                        <Index.Select className='dropdown-select drop-select'
                          value={age}
                          onChange={handleChangedropdown}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <Index.MenuItem value="" className='drop-menuitem'>
                            <img src={PageIndex.Png.user2} className="user-img" /> gor:
                          </Index.MenuItem>
                          <Index.MenuItem value={10} className='drop-menuitem'><img src={PageIndex.Png.user2} className="user-img" /> gor:</Index.MenuItem>
                          <Index.MenuItem value={20} className='drop-menuitem'><img src={PageIndex.Png.user2} className="user-img" /> gor:</Index.MenuItem>
                          <Index.MenuItem value={30} className='drop-menuitem'><img src={PageIndex.Png.user2} className="user-img" /> gor:</Index.MenuItem>

                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box>
                  </Index.Box>
                  <Index.FormHelperText className='form-lable'>Amount</Index.FormHelperText>
                  <Index.Box className="input-box-row">
                    <Index.Box className="input-box">
                      <Index.Box className="input-wrapper button-input">
                        <Index.Box className="input-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="cus-input"
                            placeholder="5000"
                          />
                        </Index.Box>
                        <Index.Button className="max-btn">Max</Index.Button>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box">
                      <Index.Box className='dropdown-box scanner-drop'>

                        <Index.FormControl className='form-control drop-form-control'>
                          <Index.Select className='dropdown-select drop-select'
                            value={age}
                            onChange={handleChangedropdown}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            <Index.MenuItem value="" className='drop-menuitem'>
                              <Index.Box className="flexo-img-sec"><img
                                src={PageIndex.Png.flexo}
                                className="flexo-ic"
                                alt="logo"
                              /></Index.Box>
                              <Index.Box className="drop-in-sec">
                                <Index.Typography component="p" variant="p" className="goerli-ether"> Goerli Ether</Index.Typography>
                                <Index.Typography component="p" variant="p" className="gor-text"> 0.05 GOR</Index.Typography>
                              </Index.Box>
                            </Index.MenuItem>
                            <Index.MenuItem value={10} className='drop-menuitem'>
                              <Index.Box className="flexo-img-sec"><img
                                src={PageIndex.Png.flexo}
                                className="flexo-ic"
                                alt="logo"
                              /></Index.Box>
                              <Index.Box className="drop-in-sec">
                                <Index.Typography component="p" variant="p" className="goerli-ether"> Goerli Ether</Index.Typography>
                                <Index.Typography component="p" variant="p" className="gor-text"> 0.05 GOR</Index.Typography>
                              </Index.Box></Index.MenuItem>
                            <Index.MenuItem value={20} className='drop-menuitem'><Index.Box className="flexo-img-sec"><img
                              src={PageIndex.Png.flexo}
                              className="flexo-ic"
                              alt="logo"
                            /></Index.Box>
                              <Index.Box className="drop-in-sec">
                                <Index.Typography component="p" variant="p" className="goerli-ether"> Goerli Ether</Index.Typography>
                                <Index.Typography component="p" variant="p" className="gor-text"> 0.05 GOR</Index.Typography>
                              </Index.Box></Index.MenuItem>
                            <Index.MenuItem value={30} className='drop-menuitem'><Index.Box className="flexo-img-sec"><img
                              src={PageIndex.Png.flexo}
                              className="flexo-ic"
                              alt="logo"
                            /></Index.Box>
                              <Index.Box className="drop-in-sec">
                                <Index.Typography component="p" variant="p" className="goerli-ether"> Goerli Ether</Index.Typography>
                                <Index.Typography component="p" variant="p" className="gor-text"> 0.05 GOR</Index.Typography>
                              </Index.Box></Index.MenuItem>

                          </Index.Select>
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="add-transaction-btn-sec next-btn-sec">
                    <Index.PrimaryButton btnLabel="Next" className='primary-btn save-btn' />
                  </Index.Box>
                </Index.Box> 
    </>
  )
}

export default WalletNewTransaction
