import React from 'react'
import Index from '../Index'
import PageIndex from '../PageIndex'

function WalletContact() {
  return (
    <>
      <Index.Box className="swap-sec contacts-wrapper">
                  <Index.Box className="swap-header-sec cus-between">
                    <Index.Box className="left-errow-sec">
                      <img src={PageIndex.Svg.arrowBack} alt="back-arrow" className="backarrow-img" />
                    </Index.Box>
                    <Index.Box className="swap-header-title">
                      <Index.Typography component="p" variant="p" className="swap-title">Contacts</Index.Typography>
                    </Index.Box>
                    <Index.Box className="swap-header-icon">
                      <img src={PageIndex.Svg.closeSetting} alt="close" className="colse-setting-img" />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="search-main">
                    <Index.Box className="input-wrapper search-wrapper">
                      <Index.Box className="input-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="cus-input"
                          placeholder="Search..."
                        />
                      </Index.Box>
                      <img src={PageIndex.Svg.search2} alt="search" className="inp-search-ic" />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="contactlist-main">
                    <img src={PageIndex.Svg.contactGray} alt="contact" className="contact-img" />
                    <Index.Typography className='contact-title' component="p" variant='p'>Build your contact list</Index.Typography>
                    <Index.Typography className='contact-sub-title' component="p" variant='p'>Add friends and addresses you trust</Index.Typography>
                    <Index.PrimaryButton btnLabel="+ Add Contact" className='primary-btn add-contact-btn' />
                  </Index.Box>
                </Index.Box> 
    </>
  )
}

export default WalletContact
