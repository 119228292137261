import React from 'react'
import Index from '../Index'
import PageIndex from '../PageIndex'

function WalletNetwork() {
    return (
        <>
            <Index.Box className="swap-sec network-wrapper">
                <Index.Box className="swap-header-sec cus-between">
                    <Index.Box className="left-errow-sec">
                        <img src={PageIndex.Svg.arrowBack} alt="back-arrow" className="backarrow-img" />
                    </Index.Box>
                    <Index.Box className="swap-header-title">
                        <Index.Typography component="p" variant="p" className="swap-title">Networks</Index.Typography>
                    </Index.Box>
                    <Index.Box className="swap-header-icon">
                        <img src={PageIndex.Svg.closeSetting} alt="close" className="colse-setting-img" />
                    </Index.Box>
                </Index.Box>
                <Index.Box className="search-main">
                    <Index.Box className="input-wrapper search-wrapper">
                        <Index.Box className="input-group">
                            <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="cus-input"
                                placeholder="Search..."
                            />
                        </Index.Box>
                        <img src={PageIndex.Svg.search2} alt="search" className="inp-search-ic" />
                    </Index.Box>
                </Index.Box>
                <Index.Box className="cus-scroll">
                    <Index.Box className="mainnet-sec">
                        <Index.Box className="search-main">
                            <Index.Box className="input-wrapper search-wrapper">
                                <Index.Box className="input-group">
                                    <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        className="cus-input"
                                        placeholder="Search for a previously added...."
                                    />
                                </Index.Box>
                                <img src={PageIndex.Svg.search2} alt="search" className="inp-search-ic" />
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className='flexo-eth-main active'>
                            <Index.Box className='flexo-img-sec'>
                                <img
                                    src={PageIndex.Png.flexo}
                                    className="flexo-ic"
                                    alt="logo"
                                />
                            </Index.Box>
                            <Index.Box className='flexo-content-sec'>
                                <Index.Typography component="p" variant="p" className="flexo-text">Ethereum Mainnet</Index.Typography>
                                <img
                                    src={PageIndex.Svg.padlock}
                                    className="lock-ic"
                                    alt="lock"
                                />
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className='flexo-eth-main'>
                            <Index.Box className='flexo-img-sec'>
                                <img
                                    src={PageIndex.Png.flexo}
                                    className="flexo-ic"
                                    alt="logo"
                                />
                            </Index.Box>
                            <Index.Box className='flexo-content-sec'>
                                <Index.Typography component="p" variant="p" className="flexo-text">Linea Mainnet</Index.Typography>
                                <img
                                    src={PageIndex.Svg.padlock}
                                    className="lock-ic"
                                    alt="lock"
                                />
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="wallet-sub-title">
                        <Index.Typography component="p" variant="p" className="wallet-sub-text">Test Network</Index.Typography>
                    </Index.Box>
                    <Index.Box className="mainnet-sec network-sec">
                        <Index.Box className='flexo-eth-main'>
                            <Index.Box className='flexo-img-sec'>
                                <img
                                    src={PageIndex.Png.flexo}
                                    className="flexo-ic"
                                    alt="logo"
                                />
                            </Index.Box>
                            <Index.Box className='flexo-content-sec'>
                                <Index.Typography component="p" variant="p" className="flexo-text">Goerli test network</Index.Typography>
                                <img
                                    src={PageIndex.Svg.padlock}
                                    className="lock-ic"
                                    alt="lock"
                                />
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className='flexo-eth-main'>
                            <Index.Box className='flexo-img-sec'>
                                <img
                                    src={PageIndex.Png.flexo}
                                    className="flexo-ic"
                                    alt="logo"
                                />
                            </Index.Box>
                            <Index.Box className='flexo-content-sec'>
                                <Index.Typography component="p" variant="p" className="flexo-text">Sepolia test network</Index.Typography>
                                <img
                                    src={PageIndex.Svg.padlock}
                                    className="lock-ic"
                                    alt="lock"
                                />
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className='flexo-eth-main'>
                            <Index.Box className='flexo-img-sec'>
                                <img
                                    src={PageIndex.Png.flexo}
                                    className="flexo-ic"
                                    alt="logo"
                                />
                            </Index.Box>
                            <Index.Box className='flexo-content-sec'>
                                <Index.Typography component="p" variant="p" className="flexo-text">Goerli test network</Index.Typography>
                                <img
                                    src={PageIndex.Svg.padlock}
                                    className="lock-ic"
                                    alt="lock"
                                />
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className='flexo-eth-main'>
                            <Index.Box className='flexo-img-sec'>
                                <img
                                    src={PageIndex.Png.flexo}
                                    className="flexo-ic"
                                    alt="logo"
                                />
                            </Index.Box>
                            <Index.Box className='flexo-content-sec'>
                                <Index.Typography component="p" variant="p" className="flexo-text">Sepolia test network</Index.Typography>
                                <img
                                    src={PageIndex.Svg.padlock}
                                    className="lock-ic"
                                    alt="lock"
                                />
                            </Index.Box>
                        </Index.Box>

                    </Index.Box>
                </Index.Box>
            </Index.Box>
            {/* <Index.Box className='metamask-footer metamask-network-footer'>
                <Index.PrimaryButton btnLabel="Add Network" className='primary-btn add-network' />
            </Index.Box> */}
        </>
    )
}

export default WalletNetwork
