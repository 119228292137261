import React from 'react'
import Index from '../Index'
import PageIndex from '../PageIndex'

function WalletNewContact() {
    return (
        <>
            <Index.Box className="swap-sec new-contacts-wrapper">
                <Index.Box className="swap-header-sec cus-between">
                    <Index.Box className="left-errow-sec">
                        <img src={PageIndex.Svg.arrowBack} alt="back-arrow" className="backarrow-img" />
                    </Index.Box>
                    <Index.Box className="swap-header-title">
                        <Index.Typography component="p" variant="p" className="swap-title">New Contact</Index.Typography>
                    </Index.Box>
                    <Index.Box className="swap-header-icon">
                        <img src={PageIndex.Svg.closeSetting} alt="close" className="colse-setting-img" />
                    </Index.Box>
                </Index.Box>
                <Index.Box className="search-main">
                    <Index.Box className="input-wrapper search-wrapper">
                        <Index.Box className="input-group">
                            <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="cus-input"
                                placeholder="Search..."
                            />
                        </Index.Box>
                        <img src={PageIndex.Svg.search2} alt="search" className="inp-search-ic" />
                    </Index.Box>
                </Index.Box>
                <Index.Box className="contact-input-sec">
                    <Index.Box className="input-wrapper add-user-input">
                        <Index.FormHelperText className='input-lable'>Username</Index.FormHelperText>
                        <Index.Box className="input-group">
                            <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="cus-input"
                                placeholder="Enter username"
                            />
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
                <Index.Box className="contact-input-sec">
                    <Index.Box className="input-wrapper add-user-input">
                        <Index.FormHelperText className='input-lable'>Ethereum public address</Index.FormHelperText>
                        <Index.Box className="input-group scan-input">
                            <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="cus-input"
                                placeholder="Enter public address (0x) or ENS name"
                            />
                            <img src={PageIndex.Svg.scanner} alt="scanner-ic" className="scanner-ic" />
                        </Index.Box>
                    </Index.Box>
                </Index.Box>

            </Index.Box>
            
        </>
    )
}

export default WalletNewContact
