import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Index from "../../../Index";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {
  changePublisherPassword,
  updatePublisherData
} from "../../../../redux/slices/AdminService";
import { useNavigate } from "react-router-dom";
// import {
//   ChangePasswordSchemaProfile,
//   EditProfileSchema,
// } from "../../../../validation/Validation";
import { imageUrl } from "../../../../config/DataService";
import MvpLoader from "../../../../components/common/mvpLoader/MvpLoader";
import PageIndex from "../../../PageIndex";
import {
  ChangePublisherpasswordSchema,
  updatePublisherProfileSchema
} from "../../../../validations/Validation";
import ButtonMvpLoader from "../../../../components/common/buttonMvpLoader/ButtonMvpLoader";

// for tabs design

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function AdvertiserEditProfile() {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { publisherData } = useSelector((state) => state.admin);

  setTimeout(() => {
    setLoading(false);
  }, 2000);
  const initialProfileValue = {
    name: publisherData?.name,
    email: publisherData?.email,
    company: publisherData?.company,
    publishUrl: publisherData?.publishUrl,
    walletAddress: publisherData?.wallets[0]?.smartAccountAddress,
    id: publisherData?._id,
    walletAmount:publisherData?.walletAmount
    // file:publisherData?.image,
    // categoryId:[publisherData?.categoryId]
  };
  const handleClickShowPassword = (field) => {
    switch (field) {
      case "oldPassword":
        setShowOldPassword((show) => !show);
        break;
      case "newPassword":
        setShowNewPassword((show) => !show);
        break;
      case "confirmPassword":
        setShowConfirmPassword((show) => !show);
        break;
      default:
        break;
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //edit profile call
  const handleEditProfile = (values) => {
    setLoading(true);
    const formdata = new FormData();
    // formdata.append("id", publisherData._id);
    for (let key in values) {
      formdata.append(key, values[key]);
    }

    dispatch(updatePublisherData(formdata)).then((res) => {
      if (res?.payload?.status === 201) {
        // dispatch()
      }
    });
  };
  const submitForm = (values, { resetForm }) => {
    setIsDisabled(true);
    const passwordData = {
      oldPassword: values?.oldPassword,
      newPassword: values?.newPassword
    };
    dispatch(changePublisherPassword(passwordData)).then((data) => {
      setTimeout(() => {
        setIsDisabled(false);
      }, 6000);
      if (data?.payload?.status === 200 || data?.payload?.status === 201) {
        setIsDisabled(false);
        resetForm({
          ...values,
          oldPassword: "",
          newPassword: "",
          confirmPassword: ""
        });
      }
    });
  };

  return (
    <>
    {loading ? (
      <MvpLoader color="#1B698E" loading={loading} />
    ) : (
    <Index.Box className="dashboard-content edit-profile-containt">
      <Index.Typography
        className="admin-page-title main-heading"
        component="h2"
        variant="h2"
      >
        Account Settings
      </Index.Typography>

      <Index.Box className="tabs-main-box table-bg-main">
        <Index.Box sx={{ width: "100%" }}>
          <Index.Box sx={{ borderBottom: 1, borderColor: "#203a44" }}>
            <Index.Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="admin-tabs-main"
            >
              <Index.Tab
                label="Edit Profile"
                {...a11yProps(0)}
                className="admin-tab"
              />
              <Index.Tab
                label="Change Password"
                {...a11yProps(1)}
                className="admin-tab"
              />
            </Index.Tabs>
          </Index.Box>

      
            <Formik
              initialValues={initialProfileValue}
              onSubmit={handleEditProfile}
              validationSchema={updatePublisherProfileSchema}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <TabPanel value={value} index={0} className="admin-tabpanel">
                    <Index.Box className="tabpanel-main">
                      <Index.Box className="">
                        <Index.Typography
                          className="common-para edit-highlight-text"
                          component="p"
                          variant="p"
                        ></Index.Typography>
                        <Index.Typography
                          className="common-para edit-para-text"
                          component="p"
                          variant="p"
                        ></Index.Typography>
                        <Index.Box className="edit-profile-flex">
                          
                        </Index.Box>
                        <Index.Box className="add-user-data-main">
                          <Index.Box sx={{ width: 1 }} className="grid-main">
                            <Index.Box
                              display="grid"
                              className="display-row-edit-profile"
                              gridTemplateColumns="repeat(12, 1fr)"
                              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                            >
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 6",
                                  lg: "span 6"
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="input-box add-user-input">
                                  <Index.FormHelperText className="form-lable">
                                    Upload Image<span>*</span>
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group">
                                    <Index.Box className="file-upload-btn-main">
                                      <img
                                        src={
                                          props.values.file
                                            ? URL.createObjectURL(
                                                props.values.file
                                              )
                                            : !publisherData?.image
                                            ? Index.Png.usericon
                                            : `${imageUrl}/${publisherData?.image}`
                                        }
                                        onChange={(e) => e.target.files[0]}
                                        className="upload-profile-img"
                                        // alt="upload img"
                                      ></img>
                                      <Index.Button
                                        variant="contained"
                                        component="label"
                                        className="file-upload-btn"
                                      >
                                        <img
                                          src={Index.Svg.edit}
                                          className="upload-img"
                                          alt="upload img"
                                        ></img>
                                        <input
                                          hidden
                                          accept="image/*"
                                          name="image"
                                          type="file"
                                          onChange={(e) => {
                                            try {
                                              props.setFieldValue(
                                                "file",
                                                e.target.files[0]
                                              );
                                            } catch (error) {
                                              console.error(error);
                                              e.target.value = null;
                                            }
                                          }}
                                        />
                                      </Index.Button>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>

                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 6",
                                  lg: "span 6"
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="input-box add-user-input">
                                  <Index.FormHelperText className="form-lable">
                                    Name<span>*</span>
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group">
                                    <Index.TextField
                                      fullWidth
                                      name="name"
                                      placeholder="Please enter name"
                                      value={props.values.name}
                                      // onChange={(event) => {
                                      //   let inputValue = event.target.value.trimStart();
                                      //   // inputValue = inputValue.trimStart();

                                      //   // // Replace multiple spaces with a single space
                                      //   // inputValue = inputValue.replace(/\s\s+/g, ' ');
                                      //   if (inputValue.length <= 30) {
                                      //     // Only update the value if it's not more than 30 characters
                                      //     props.handleChange(event);
                                      //   }
                                      // }}

                                      onChange={(e) => {
                                        let value = e.target.value;

                                        // Remove any leading spaces
                                        value = value.trimStart();

                                        // Replace multiple spaces with a single space
                                        value = value.replace(/\s\s+/g, " ");

                                        const regex = /^[A-Za-z][A-Za-z\s]*$/;

                                        if (regex.test(value) || value === "") {
                                          // Limit the length of the name to 20 characters
                                          props.setFieldValue(
                                            "name",
                                            value.slice(0, 25)
                                          );
                                        }
                                      }}
                                      maxLength={25}
                                      helperText={props.errors.name}
                                      error={Boolean(props.errors.name)}
                                      id="fullWidth"
                                      className="form-control"
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 6",
                                  lg: "span 6"
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="input-box add-user-input">
                                  <Index.FormHelperText className="form-lable">
                                    Email<span>*</span>
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group">
                                    <Index.TextField
                                      name="email"
                                      value={props.values.email}
                                      onChange={props.handleChange}
                                      helperText={props.errors.email}
                                      error={Boolean(props.errors.email)}
                                      fullWidth
                                      id="fullWidth"
                                      className="form-control"
                                      placeholder="Please enter email"
                                      onKeyDown={ e => e.keyCode === 32 && e.preventDefault() }
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 6",
                                  lg: "span 6"
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="input-box add-user-input">
                                  <Index.FormHelperText className="form-lable">
                                    Company<span>*</span>
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group">
                                    <Index.TextField
                                      fullWidth
                                      id="fullWidth"
                                      placeholder="Please enter company name"
                                      className="form-control"
                                      name="company"
                                      value={props.values.company}
                                      onChange={(e) => {
                                        let value = e.target.value;
        
                                        // Remove any leading spaces
                                        value = value.trimStart();
        
                                        // Replace multiple spaces with a single space
                                        value = value.replace(/\s\s+/g, " ");
        
                                        const regex =
                                          /^(?!\s)[a-zA-Z0-9.,;:|\\\/~!@#$%^&*_-{}\[\]()`"'<>?\s]+$/;
        
                                        if (regex.test(value) || value === "") {
                                          // Limit the length of the name to 20 characters
                                          props.setFieldValue("company", value.slice(0, 30));
                                        }
                                      }}
                                      helperText={props.errors.company}
                                      error={Boolean(props.errors.company)}
                                      maxLength={10}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 6",
                                  lg: "span 6"
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="input-box add-user-input">
                                  <Index.FormHelperText className="form-lable">
                                    Publisher Url<span>*</span>
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group">
                                    <Index.TextField
                                      fullWidth
                                      id="fullWidth"
                                      className="form-control"
                                      placeholder="Please enter publisher url"
                                      name="publishUrl"
                                      value={props.values.publishUrl}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        const regex = /^\S*$/; // Regex pattern to disallow whitespace

                                        if (regex.test(value) || value === "") {
                                          props.handleChange(e); // Update state if value is valid or empty
                                        }
                                      }}
                                      helperText={props.errors.publishUrl}
                                      error={Boolean(props.errors.publishUrl)}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 6",
                                  lg: "span 6"
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="input-box add-user-input">
                                  <Index.FormHelperText className="form-lable">
                                    Wallet Address<span>*</span>
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group">
                                    <Index.TextField
                                      fullWidth
                                      id="fullWidth"
                                      disabled
                                      className="form-control"
                                      placeholder="Please enter wallet address"
                                      name="walletAddress"
                                      value={props.values.walletAddress}
                                      // onChange={props.handleChange}
                                      
                                      helperText={props.errors.walletAddress}
                                      error={Boolean(
                                        props.errors.walletAddress
                                      )}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 6",
                                  lg: "span 6"
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="input-box add-user-input">
                                  <Index.FormHelperText className="form-lable">
                                    Wallet<span>*</span>
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group">
                                    <Index.TextField
                                      fullWidth
                                      id="fullWidth"
                                      disabled
                                      className="form-control"
                                      placeholder="Please enter wallet address"
                                      name="walletAmount"
                                      value={props.values.walletAmount}
                                      // onChange={props.handleChange}
                                      
                                      helperText={props.errors.walletAmount}
                                      error={Boolean(
                                        props.errors.walletAmount
                                      )}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12"
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="user-btn-flex">
                                <Index.Box className="discard-btn-main border-btn-main">
                                  <Index.Button
                                    className="discard-user-btn border-btn"
                                    onClick={() =>
                                      navigate("/publisher/dashboard")
                                    }
                                  >
                                    Discard
                                  </Index.Button>
                                </Index.Box>
                                <Index.Box className="save-btn-main border-btn-main">
                                  <Index.Button
                                    className="save-user-btn border-btn"
                                    type="submit"
                                    startIcon={
                                      loading ? (
                                        <MvpLoader
                                          color="#1B698E"
                                          loading={loading}
                                        />
                                      ) : null
                                    }
                                  >
                                    <img
                                      alt="imgcategory"
                                      src={PageIndex.Svg.save}
                                      className="user-save-icon"
                                    ></img>
                                    Update
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </TabPanel>
                </form>
              )}
            </Formik>
     
          <TabPanel value={value} index={1} className="admin-tabpanel">
            <Index.Box className="tabpanel-main">
              <Index.Box className="change-pass-main">
                {/* <Index.Typography
                  className="common-para edit-highlight-text"
                  component="p"
                  variant="p"
                >
                  Password
                </Index.Typography> */}
                {/* <Index.Typography
                  className="common-para edit-para-text"
                  component="p"
                  variant="p"
                >
                  Enter your current & new password to change your password
                </Index.Typography> */}
                <Formik
                  initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: ""
                  }}
                  onSubmit={submitForm}
                  validationSchema={ChangePublisherpasswordSchema}
                >
                  {({
                    touched,
                    handleBlur,
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    isValid,
                    dirty
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Index.Box className="input-box change-pass-input-box">
                        <Index.FormHelperText className="form-lable">
                          Old Password<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            size="small"
                            placeholder="Please enter old password"
                            name="oldPassword"
                            className="form-control input-with-radius password-form-control"
                            type={showOldPassword ? "text" : "password"}
                            // label="Password"
                            variant="outlined"
                            autoComplete="off"
                            inputProps={{
                              className: "input_props"
                            }}
                            InputLabelProps={{ className: "add-formlabel" }}
                            FormHelperTextProps={{
                              className: "input_label_props"
                            }}
                            onBlur={handleBlur}
                            value={values.oldPassword}
                            onChange={handleChange}
                            onKeyDown={ e => e.keyCode === 32 && e.preventDefault() }
                            // helperText={errors.oldPassword}
                            // error={Boolean(errors.oldPassword)}
                            InputProps={{
                              // <-- This is where the toggle button is added.
                              endAdornment: (
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    className="passwrd-eye"
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword("oldPassword")
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showOldPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              )
                            }}
                          />
                          {errors?.oldPassword && touched?.oldPassword ? (
                            <Index.Typography
                              className="error-msg-profile"
                              // style={{ marginTop: "-21px" }}
                            >
                              {errors?.oldPassword}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box change-pass-input-box">
                        <Index.FormHelperText className="form-lable">
                          New Password<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            size="small"
                            name="newPassword"
                            placeholder="Please enter new password"
                            className="form-control input-with-radius password-form-control"
                            type={showNewPassword ? "text" : "password"}
                            onKeyDown={ e => e.keyCode === 32 && e.preventDefault() }
                            // label="Password"
                            variant="outlined"
                            autoComplete="off"
                            inputProps={{
                              className: "input_props"
                            }}
                            InputLabelProps={{ className: "add-formlabel" }}
                            FormHelperTextProps={{
                              className: "input_label_props"
                            }}
                            onBlur={handleBlur}
                            value={values.newPassword}
                            onChange={handleChange}
                            // helperText={errors.newPassword}
                            // error={Boolean(errors.newPassword)}
                            InputProps={{
                              // <-- This is where the toggle button is added.
                              endAdornment: (
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    className="passwrd-eye"
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword("newPassword")
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showNewPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              )
                            }}
                          />
                          {errors?.newPassword && touched?.newPassword ? (
                            <Index.Typography
                              className="error-msg-profile"
                              // style={{ marginTop: "-21px" }}
                            >
                              {errors?.newPassword}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box change-pass-input-box">
                        <Index.FormHelperText className="form-lable">
                          Confirm Password<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            size="small"
                            placeholder="Please enter confirm password"
                            name="confirmPassword"
                            className="form-control input-with-radius password-form-control"
                            type={showConfirmPassword ? "text" : "password"}
                            // label="Password"
                            variant="outlined"
                            autoComplete="off"
                            inputProps={{
                              className: "input_props"
                            }}
                            onKeyDown={ e => e.keyCode === 32 && e.preventDefault() }
                            InputLabelProps={{ className: "add-formlabel" }}
                            FormHelperTextProps={{
                              className: "input_label_props"
                            }}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            // helperText={errors.confirmPassword}
                            // error={Boolean(errors.confirmPassword)}
                            InputProps={{
                              // <-- This is where the toggle button is added.
                              endAdornment: (
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    className="passwrd-eye"
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword("confirmPassword")
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showConfirmPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              )
                            }}
                          />
                          {errors?.confirmPassword &&
                          touched?.confirmPassword ? (
                            <Index.Typography
                              className="error-msg-profile"
                              // style={{ marginTop: "-21px" }}
                            >
                              {errors?.confirmPassword}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12"
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="user-btn-flex">
                          <Index.Box className="discard-btn-main border-btn-main">
                            <Index.Button
                              className="discard-user-btn border-btn"
                              onClick={() => navigate("/publisher/dashboard")}
                            >
                              Discard
                            </Index.Button>
                          </Index.Box>
                          <Index.Box className="save-btn-main border-btn-main">
                            <Index.Button
                              className="save-user-btn border-btn"
                              type="submit"
                              disabled={isDisabled}
                            >
                              {isDisabled ? (
                                <ButtonMvpLoader
                                  color="white"
                                  loading={isDisabled}
                                />
                              ) : (
                                <>
                                  <img
                                    alt="imgcategory"
                                    src={PageIndex.Svg.save}
                                    className="user-save-icon"
                                  ></img>
                                  Update
                                </>
                              )}
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </form>
                  )}
                </Formik>
              </Index.Box>
            </Index.Box>
          </TabPanel>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )}
  </>
  );
}
